
.top_analytics{
    display: flex;
    flex-wrap: wrap;
    box-shadow: 0 6px 12px rgba(0,0,0,0.1);
    padding: 40;
    background-color: #fff;
}

.top_analytics_box{
    display: flex;
    flex-direction: column;
    padding: 20px;
    border: solid 1px #00000019;
    width: 50%;
}

.top_analytics_box.first{
    width: 100%;
}

.top_analytics_first{
    display: flex;
    flex-direction: row; 
    justify-content: space-evenly;
}

.top_analytics_main{
    display: flex;
    flex-direction: row; 
    justify-content: space-evenly;
}

.top_analytics_child{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.top_analytics_child h1{
    margin: 0px;
}

.top_analytics_child p{
    font-size: 14px;
}


.top_box_magic{
    display: flex;
    flex-direction: column;
}

.top_box_magic h1{
    margin: 0px;
    margin-top: 20px;
    font-size: 18px;
}

.top_box_magic p {
    color: #000000;
    white-space: pre-wrap;
    font-weight: 200;
    font-size: 14px;
    max-width: 400px;
}

@media (max-width: 867px) {
    .top_analytics_box{
        width: 100%;
    }
    .top_analytics_first{
        flex-wrap: wrap;
    }
}


.rdw-editor-toolbar{
    position: sticky;
    top: 65px!important;
    left: 0!important;
    width: 100%!important;
    order: 0;
    background-color: rgb(33, 40, 63);
    z-index: 222;
}

.css-ubpweo-MuiPaper-root-MuiPopover-paper{
    padding: 20px;
}

.rbc-event, .rbc-day-slot .rbc-background-event{
    background-color: #26BFBF;
}
