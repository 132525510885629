.loading-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 70vh;
  max-width: 8xl;
  margin: 0 auto;
  padding: 4rem 2rem;
  opacity: 0;
  transform: translateY(-20px);
  animation: fadeInUp 1s forwards;
}

.stroller img {
  animation: bounce 0.8s infinite;
}

.text-container-splash {
  opacity: 0;
  transform: translateY(-20px);
  animation: fadeInUp 0.8s forwards 0.3s;
}

.road-container {
  width: 100%;
  overflow: hidden;
  height: 3px;
  margin-top: 2rem;
}

.road {
  width: 0;
  height: 100%;
  background-color: #E53E3E; /* Adjust color as needed */
  animation: stretchRoad 1.5s forwards 1s;
}

.text-center-splash {
  opacity: 0;
  transform: translateY(-20px);
  animation: fadeInUp 0.8s forwards 1.5s;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40%, 60% {
    transform: translateY(-20px);
  }
}

@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes stretchRoad {
  to {
    width: 100%;
  }
}

.animation-complete {
  opacity: 1;
  transform: translateY(0);
}
