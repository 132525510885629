.card {
  min-width: 290px;
  width: 100%;
  max-width: 100%;

  padding: 0.5rem;
  background: white;
  box-shadow: 2px 2px 40px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  border: 1px solid #f1f0f0;

  display: flex;
  flex-direction: column;
  gap: 10px;
}

.card.grid {
  min-width: 100%;
  width: 45%;
  max-width: 45%;
}

.card-top {
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 10px;
  gap: 10px;
}

.card-banner {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: max-content;

  border-radius: 10px;
  gap: 10px;
}

.banner-green {
  background: #0cbc13;
}

.banner-gray {
  background: #797979;
}

.banner-text {
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 0px 10px;
  height: 24px;

  color: white;
  font-size: 10px;
  font-family: 'Nunito', sans-serif;
  text-transform: uppercase;
  line-height: 15px;

  border-radius: 10px;
}

.card-image img {
  margin: auto;

  width: 152px;
  height: 140px;

  border-radius: 10px;
  cursor: pointer;
}

.card-title {
  margin: 0px !important;

  color: black;
  font-size: 16px;
  font-family: 'Quicksand', sans-serif;
  font-weight: 600;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.rent-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 5px;

  width: 100%;
  background: #fc6170 !important;
  border-radius: 4px;
  gap: 10px;
}

.rent-button:hover {
  opacity: 0.85;
}

.rent-text {
  color: white;
  font-size: 16px;
  font-family: 'Nunito', sans-serif;
}

.rent-icon {
  width: 20px;
  height: 20px;
  border: 1.5px solid white;
  position: relative;
}

.store-info {
  display: flex;
  align-items: center;
  gap: 11px;
}

.store-info img {
  flex-shrink: 0;
  width: 36px;
  height: 36px;
  border-radius: 50%;
}

.store-name {
  font-size: 12px;
  font-family: 'Quicksand', sans-serif;
  font-weight: 400;
}

.review-info {
  padding: 0px;

  width: max-content;
  height: 12px;

  font-size: 12px;
  font-family: 'Quicksand', sans-serif;
  font-weight: 400;
}

.text-warning {
  color: #fc6170 !important;
}

.product-rating > div {
  margin-bottom: 0px !important;
}

.product-rating :nth-child(5) {
  margin-right: 0px !important;
}

.product-rating :last-child {
  display: none;
}

.product-store-name {
  overflow: hidden;
  width: 75%;

  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 1.25rem;
}

.grid .product-store-name {
  width: 7ch;
}

.card-footer {
  padding: 0;

  color: #0b0000 !important;

  background: none;
  border-top: none;
}

@media (min-width: 768px) {
  .card {
    padding: 1rem;
    width: calc(40% - 4rem);
  }
}

@media (min-width: 1024px) {
  .card {
    width: calc(25% - 4rem);
  }
}
