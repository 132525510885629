/* PICKUP LOCATION */
.product-details-tabs {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;

  width: 100%;
  height: 2.5rem;

  border-radius: 10px;
}

.product-details-tabs > button:nth-child(2) {
  border-left: 1px var(--color-mid-gray) solid;
  border-right: 1px var(--color-mid-gray) solid;
}

.product-details-tab {
  flex: 1 1 0;
  min-width: max-content;
  padding: 5px 10px;
  background: var(--background-light-gray) !important;
  justify-content: center;
  align-items: center;
  display: 'flex';

  font-size: 16px;
  font-family: 'Nunito Sans', sans-serif;
}

.active-tab {
  width: 100%;
  font-weight: bold;
  color: var(--white);
  background: var(--color-pr) !important;
}

.main-tab-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  margin: 24px 0px;
  padding-right: 16px;

  border-radius: 40px;
}

.main-tab-header img {
  width: 50px;
  height: 50px;

  background: #fff;
  border-radius: 50%;
}

.product-preview,
.pickup-location-map-wrapper,
.product-store-img {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  gap: 10px;

  height: 320px;

  border-radius: 12px;
  border: 1px solid #d4d4d4;
  background: #fff;
}

.pickup-location-map-wrapper img,
.product-store-img img {
  width: 100%;
  height: 100%;
  border-radius: 12px;
}

.product-preview .selected-img {
  display: flex;

  width: max-content;
  height: 210px;

  border-radius: 12px;
}

.product-img-option {
  display: flex;

  margin: 0px 5px;

  width: 56px;
  height: 56px;

  border-radius: 12px;
}

.product-img-option:hover {
  transform: scale(1.01);
}
.product-img-option.active {
  outline: 2px #fc6170 solid !important;
}

.product-img-option img {
  width: 100%;
  height: 100%;

  border-radius: 12px;
}

.product-info-long,
.store-info-long {
  display: flex;
  flex-direction: column;
  gap: 12px;

  margin: 24px 0px;
}

.product-info-long h3,
.store-info-long h3 {
  font-family: 'Quicksand', sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.store-info-long h3 {
  text-align: center;
}

.product-info-long p,
.store-info-long p {
  font-family: 'Quicksand', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.features-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  gap: 1rem;

  margin-bottom: 2rem;
}

.feature-box {
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;

  border-radius: 8px;
  border: 1px solid #d4d4d4;
}

.feature-box h4 {
  text-align: center;

  font-family: 'Quicksand', sans-serif;
  font-size: 14px;
  font-weight: 600;

  min-width: 120px;
}

.feature-box img {
  width: 100px;
  height: 100px;
}

.rent-wrapper {
  margin-top: 24px;
}

.box-stroke {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  padding: 6px;

  width: 100%;

  font-family: 'Quicksand', sans-serif;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  color: #8c8c8c;

  border: 1px solid #8c8c8c;
}

.box-stroke.color-ba {
  color: #fc6170;
  border: 1px solid #fc6170;
}

.buttons-product-store-wrapper {
  display: flex;
  gap: 12px;

  margin-top: 25px;
}

@media (min-width: 1024px) {
  .main-tab-header {
    flex-direction: row;
    background: #eeeeee;
  }
}
