/* LISTING GRID */
/* .main-filters-wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;

  margin-bottom: 20px;
  padding: 10px;
} */

/* .main-filter-box {
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #d8d8d8;
  background: #fff;
  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.09) inset;
} */

/* .main-filter-item {
  flex-grow: 0;
  flex-shrink: 0;
  width: 100%;
  max-width: calc(20% - 36px);
} */

.button-apply-filters {
  height: min-content;

  margin: auto 0px;
  padding: 12px 22px;
  min-width: max-content;

  color: #fff;
  background: rgba(0, 0, 0, 0.87) !important;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}

.button-stroke.color-ba {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;

  padding: 3px 28px;

  font-family: 'Quicksand', sans-serif;
  font-size: 12px;
  font-weight: 600;
  color: rgb(252, 97, 112);

  border: 1px solid rgb(252, 97, 112);
}

.secondary-filters {
  position: relative;
  display: flex;
  justify-content: space-between;
  gap: 8px;

  width: 100%;
  padding: 0.5px 1.5rem;
}

.secondary-filters button {
  font-size: 0px !important;
  padding: 0.25rem 0.75rem !important;

  border: none !important;
}

.secondary-filters button.button-apply-filters {
  font-size: 14px !important;
}

.main-filters-mobile-wrapper {
  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  flex-direction: column;

  width: 100vw;
  height: 100vh;

  z-index: 20;

  animation: slideInFromLeft 0.25s ease-out forwards;
  border-radius: 10px;
  border: 1px solid #d8d8d8;
  background: #fff;
  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.09) inset;
}

.main-filters-mobile {
  display: flex;
  margin: auto;
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.bg-listing-grid {
  background: white;
}

@media (min-width: 768px) {
  .bg-listing-grid {
    background: var(--background-light-gray);
  }
}

@media (min-width: 1024px) {
  /* .main-filters-wrapper {
    flex-direction: row;
    margin-bottom: 50px;
  } */

  .secondary-filters {
    padding: 0px;
  }

  .secondary-filters button {
    position: absolute;
    top: 0px;
    right: 0px;

    width: max-content;

    border-radius: 50px;
  }
}
