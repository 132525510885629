/* NAVBAR */
.nav_auth {
    display: flex;
    flex-direction: row; 
    align-items: center; 
    justify-content: space-between;
    height: 90px;
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
    box-shadow: 0 4px 8px rgb(0 0 0 / 5%);
}

.nav_right {
    display: flex;
    align-items: center;
    justify-content: center;
}

/* SECTIONS */
.section_box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 60px;
}

.section_box.row {
    flex-direction: row;
}

.section_box.reverse {
    flex-direction: row-reverse;
}

.section_left {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.section_right {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.section_intro_img{
    height: 100%;
    width: 100%;
    height: auto;
    border-top-left-radius: 50px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 50px;
    object-fit: cover;
}

.section_intro_img.contain{
    object-fit: contain;
    max-height: 80vh;
}

.section_title {
    margin: 0px;
    font-size: 60px;
    text-align: center;
}
.section_title.left {
    text-align: left;
}

.section_sub {
    margin: 0px;
    font-weight: 300;
    margin: 30px 0px;
    text-align: center;
    white-space: pre-line;
    width: 100%;
}
.section_sub.left {
    text-align: left;
}

.section_button {
    border: none;
    width: 240px;
    padding: 20px;
    font-size: 20px;
    background-color: #fd6270;
    border-radius: 8px;
    color: #ffffff;
    cursor: pointer;
    text-align: center;
    margin-bottom: 70px;
}

.section_button.provider {
    background-color: #26BFBF;
}

.section_list {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    margin-bottom: 100px;
    flex-wrap: wrap;
}

.section_item {
    display: flex; 
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 60px;
}

.section_item_img {
    width: 250px;
    height: auto;
}


.section_item_sub {
    font-weight: 200;
    font-size: 18px;
    margin-top: 15px;
}

.section_city_img{
    width: 250px;
    height: auto;
    border-top-left-radius: 50px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 50px;
    object-fit: cover;
    cursor: pointer;
}

.section_city_name{
    width: 200px;
    font-size: 44px;
    font-weight: 600;
    text-align: left;
    color: #f9efef;
    cursor: pointer;
    margin-top: -70px;
}

.language_dropdown {
    position: absolute;
    background-color: #ffffff;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    top: 70px;
    right: 90px;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 30px;
} 

.language_dropdown div {
    display: flex;
    align-items: center;
    margin: 20px;
    opacity: 1.0;
    cursor: pointer;
    font-size: 16px;
    font-weight: 200;
    color: #000;
}

.language_dropdown a {
    display: flex;
    align-items: center;
    margin: 20px;
    opacity: 1.0;
    cursor: pointer;
    font-size: 16px;
    font-weight: 200;
    color: #000;
}

.language_dropdown img {
	width: 20px;
	height: 20px;
	object-fit: cover;
	border-radius: 50%;
	box-shadow: 0 6px 12px rgba(0,0,0,0.1);
	margin-right: 10px;
	cursor: pointer;
}
.language_dropdown div.active {
	opacity: 0.4;
}

.nav_links{
    font-size: 15px;
    text-transform: none;
    white-space: nowrap;
    background: inherit;
    height: 100%;
    display: flex;
    margin-left: auto;
}

.nav_links a {
    height: 100%;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    margin: 0;
    padding: 0 20px;
    border-right: 1px solid rgba(0,0,0,.05);
}

.nav_links a.active {
    border: solid 5px;
    border-top: none;
    border-left: none;
    border-right: 1px solid rgba(0,0,0,.05);
    border-bottom: 5px solid #fd6270;
}

.nav_links a.provider {
    border: solid 5px;
    border-top: none;
    border-left: none;
    border-right: 1px solid rgba(0,0,0,.05);
    border-bottom: 5px solid #26BFBF;
}

.nav_links a strong {
    font-size: 16px;
    font-weight: 200;
    position: relative;
}

.section_download {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 70px;

}

.section_download a {

}

.section_download a img {
   cursor: pointer; 
   width: 150px;
}

.section_search_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 550px;
}

.section_search_bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-radius: 30px;
    width: 100%;
    height: 70px;
    background-color: #fff;
    padding: 5px;
    box-shadow: 0px 0 5px -2px #888;
}

.section_search_bar input {
    background-color: transparent;
    font-size: 18px;
    border: none;
    margin-left: 20px;
    width: 100%;
}

.section_search_bar div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 100%;
    background: #fd6270;
    border-radius: 50px;
    color: #fff;
    cursor: pointer;
    font-weight: 300;
    font-size: 15px;
}

.section_cta{
    margin-bottom: 70px;
    width: 100%;
}

@media (max-width: 950px) { 
    .nav_links {
        display: none;
    }
    .section_box {
        flex-direction: column;
        padding: 80px;
    }
    
    .section_box.row {
        flex-direction: column;
    }
    
    .section_box.reverse {
        flex-direction: column;
    }

    .section_title {
        font-size: 40px;
    }

    .section_sub {
        font-size: 18px;
    }

    .section_title.left {
        text-align: center;
    }

    .section_sub.left {
        text-align: center;
    }

    .section_search_container{
        width: 100%;
    }

    .section_cta{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
}

@media (max-width: 600px) { 
    .section_box {
        padding: 80px 20px;
    }
    
    .section_search_bar input {
        font-size: 14px;
    }

    .section_search_bar div:nth-child(1) {
        font-size: 13px;
        font-weight: 600;
        padding-left: 10px;
        padding-right: 10px;
        text-align: center;
    }

    /* .section_list{
        overflow: auto;
        white-space: nowrap;
        justify-content: flex-start;
        flex-wrap: nowrap;
    } */
}


.city_icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    vertical-align: middle;
    border-radius: 50px;
    border: 1px solid rgba(0, 0, 0, 0.098);
    margin-left: 5px;
    object-fit: cover;
}


.cai-container{
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-user-select: all;  /* Chrome 49+ */
    -moz-user-select: all;     /* Firefox 43+ */
    -ms-user-select: all;      /* No support yet */
    user-select: all;          /* Likely future */   
}

.cai-container img{
    object-fit: contain!important;
    width: 100%!important;
    height: 366px!important;
}

.cai-container h1{
    margin: 0px;
}

.cai-container h4{
    margin: 0px;
}

.cai-container h5{
    margin: 0px;
}

.cai-container h6{
    margin: 0px;
}

.cai-container p{
    margin: 0px;
    min-height: 20px;
}



.testimonial-box-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width:100%;
}
.testimonial-box{
    width:500px;
    box-shadow: 2px 2px 30px rgba(0,0,0,0.1);
    background-color: #ffffff;
    padding: 20px;
    margin: 15px;
    cursor: pointer;
    border-radius: 10px;
}
.profile-img{
    width:50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 10px;
}
.profile-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
.profile{
    display: flex;
    align-items: center;
}
.name-user{
    display: flex;
    flex-direction: column;
}
.name-user strong{
    color: #3d3d3d;
    font-size: 1.1rem;
    letter-spacing: 0.5px;
}
.name-user span{
    color: #979797;
    font-size: 0.8rem;
}
.reviews{
    color: #f9d71c;
}
.box-top{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}
.client-comment p{
    font-size: 0.9rem;
    color: #4b4b4b;
}
.testimonial-box:hover{
    transform: translateY(-10px);
    transition: all ease 0.3s;
}
 
@media(max-width:1060px){
    .testimonial-box{
        width:45%;
        padding: 10px;
    }
}
@media(max-width:790px){
    .testimonial-box{
        width:100%;
    }
    .testimonial-heading h1{
        font-size: 1.4rem;
    }
}
@media(max-width:340px){
    .box-top{
        flex-wrap: wrap;
        margin-bottom: 10px;
    }
    .reviews{
        margin-top: 10px;
    }
}
::selection{
    color: #ffffff;
    background-color: #252525;
}


.features-block-boxes {
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: flex-start;
}

.features-block-box {
    flex-direction: column;
    width: calc(33.333% - 40px);
    padding: 0;
    display: flex;
    margin: 0 20px 80px;
    position: relative;
    text-align: left;
    cursor: pointer;
}

.features-block-box img{
    width: 100%;
    height: 300px;
    object-fit: cover;
    border-radius: 10px;
}

.features-block-box h4{
    margin-top: 20px;
    font-size: 22px;
}

@media(max-width: 779px) {
    .features-block-boxes {
        flex-direction: column;
    }

    .features-block-box{
        width: 100%;
    }
}


#container-logos {
  height:350px; 
  position:relative; 
  overflow:hidden;
}

.photobanner {
  position:absolute; 
  top:0px; 
  left:0px; 
  overflow:hidden; 
  white-space: nowrap;
  animation: bannermove 20s linear infinite;
}

.photobanner a {    
  margin: 0 0.5em 
}

@keyframes bannermove {
  0% {
      transform: translate(0, 0);
  }
  100% {
      transform: translate(-50%, 0);
  }
}