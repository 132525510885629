.signup-click-logo {
	display: none;
	margin:20px auto;
}

.signup-click-logo img {
	width: 80px;
}
.form-card {
	width: 100%;
	max-width: 400px;
	padding:40px;
	background: #fff;
	border-radius: 6px;
	box-shadow: 13px 13px 50px 0 rgba(0,0,0,0.08); 
	border: solid 1px #f1f1f1;
	margin:0 auto;
}
.form-card .helper {
	font-size: 12px;
	margin-top: -10px; 
	margin-bottom:20px;
	display: block;
	text-align: center;
}
.form-card input {
	border:none;
	border-bottom: solid 1px #f1f1f1;
	font-size: 14px;
	width: 100%;
	padding:22px 0px 22px 30px;
	margin-bottom: 10px;
	outline: none!important;
	transition: all 0.2s ease;
}
.form-card .btn {
	margin-top: 20px;
	margin-bottom: 20px;
}
.form-card input:focus {
	border-bottom-color: #000;
}
.form-terms-links {
	font-size: 12px;
	width: 100%;
	opacity: 0.6;
	line-height: 15px;
	margin-top: 10px;
}

.form-terms-links a {
	text-decoration: underline;
}

.login-form-container {
	width: 100%;
}
.welcome-message {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	align-items: center;
}

.welcome-message.signup-message {
	margin-bottom: 20px;
}

.welcome-message.signup-message h1 {
	margin:50px 0 0 0;
}

.welcome-message.signup-message p {
	margin:0;
	font-size: 16px;
	font-weight: 500;
	opacity: 0.6
}

.welcome-message.signup-message p a {
	font-weight: bold;
}

.welcome-message h1 span {
	font-weight: 300;
}
.welcome-message p {
	font-weight: 100;
}
.welcome-message img {
	border-radius: 50%;
	width: 80px;
	height: 80px;
}

.social-login-btn {
	width: 100%;
	display: flex;
	justify-content: center;
	height: 50px;
	border-radius: 6px;
	align-items: center;
	border:solid 1px #ccc;
	font-size: 16px;
	font-weight: 500;
	margin-bottom: 0px;
	cursor: pointer;
	transition: all 0.2s;
	position: relative;
	overflow: hidden;
	background: #ffffff;
	padding-left: 50px;
}

.social-login-btn .socicon {
	width: 50px;
	height: 50px;
	position: absolute;
	left: 0;
	top: 0;
	background: #fff;
	color: #3b5998;
	display: flex;
	justify-content: center;
	align-items: center;
}

.social-login-btn:focus{
	outline: none;
    background: #ffffff;
}

.social-login-btn:before {
	width: 0;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	background: #fafafa;
	content: " ";
	transition: all 0.3s;
	opacity: 0;
}

.social-login-btn:hover:before {
	width: 100%;
	opacity: 1;
	z-index: 1;
}
.social-login-btn * {
	position: relative;
	z-index: 2;
}

.social-login-btn:hover {
	transform: translateY(-3px);
	border-color: #efefef;
	box-shadow: 0 4px 8px rgba(0,0,0,0.05);
}

.social-login-btn .socicon {
	margin-right: 9px;
	font-size: 18px;
}
.social-login-btn.facebook {
	background: #3b5998;
	color: #fff;
}
.social-login-btn.facebook:hover {
	background:#3b5998;
}
.social-login-btn.facebook:before {
	display: none;
}
.social-login-btn .socicon-facebook {
}
.social-login-btn .socicon-google {
	color: #DB4437;
}
.social-login-btn .socicon-instagram {
	color: #e4405f;
}
.social-login-btn.facebook {
	border-color:#3b5998; 	
}
.social-login-btn.google {
	border-color:#DB4437; 	
} 
.social-login-btn.instagram {
	border-color:#e4405f;
}
.login-graphic .facebook-page-card {
	max-width: 400px;
	overflow: visible;
	background: #fff;
	margin-top: 20px;
}
.login-graphic  .facebook-page-card .card-set.column img {
	border:solid 2px #fff;
}

.login-graphic .facebook-page-card input {
	width: 300px;
	height: 40px;
	outline: none;
	border:solid 1px #efefef;
	border-radius: 6px;
	font-size: 14px;
	margin-top: 20px;
	transition: all 0.2s;
	text-align: center;
}
.login-graphic .facebook-page-card input:focus {
	box-shadow: 0 8px 16px rgba(0,0,0,0.1);
}
.login-graphic .confirm-email-title {
	width: 100%;
	max-width: 400px;
	margin:0 auto 40px auto;
	text-align: center;
}
.login-graphic .confirm-email-title h1 {
	padding:0;
	text-align: center;
}
.login-graphic .facebook-page-card .card-set {
	border:none;
}
.login-graphic .social-accounts-circle {
	margin-bottom: 10px;
	margin-top: -60px;
}
.login-graphic .confirm-email-title p {
	margin:0;
	padding:0;
	text-align: center;
}
.login-graphic .login-logo {
	position: absolute;
	left: 30px;
	top: 30px;
	width: 100px;
}
.login-graphic {
	width: 40%;
	float: left;
	background: #21283F;
	height: 100vh;
	display: flex;
	justify-content: center;
	flex-direction: column;
	position: relative;
	align-items: flex-start;
}
.login-graphic h1 {
	font-weight: 300;
	color: #fff;
	text-align: left;
	margin:0;
	padding:0 0 0 80px;
	font-size: 48px;
	opacity: 0;
	animation: fadeInLeft 1s forwards;
}
.login-graphic h1 span {
	font-weight: bold;
	color: #9EA9C1;
}
.login-graphic .tutorial {
	    max-width: 350px;
	    width: 100%;
    margin-bottom: 30px;
    margin-top: -100px;
}
.login-graphic p {
	color: #fff;
	width: 100%;
	padding:0 80px;
	opacity: 1;
	margin-top: 5px;
	margin-bottom: 0;
	text-align: left;
	font-weight: 400;
	font-size: 16px;
	opacity: 0;
	animation: fadeInLeft 1s forwards;
}
#loginBtn.login-btn {
	position: fixed;
	padding:10px 20px;
	right: 30px;
	top: 30px;
	background: linear-gradient(-45deg, #455173 0%, #21283F 100%);
}
#loginBtn.login-btn:hover {
	transform: none;
}

#loginBtn.login-btn .arrow {
	background: #fff;
}
#loginBtn.login-btn .arrow:after {
	border-color: #fff;
}
.flex-container#login {
	width: 100%;
}
.login-form {
	width: 60%;
    float: right;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
}
.login-form .form-card {
	border:none;
	box-shadow: none;
	padding-top: 0;
}
.login-form .form-card .button-box {
	padding:15px 0 30px 0;
}
.login-form .form-card .form-group img {
	left: 20px;
	opacity: 1;
}


.login-form .form-card .form-group img.show {
	left: auto;
	right: 20px;
	cursor: pointer;
}
.login-form .form-card input {
	background: #F8FAFF;
	border-bottom: none;
	padding:22px 20px 22px 50px;
	border-radius: 6px;
	transition: all 0.3s;
	border:solid 1px #efefef!important;
}
.login-form .form-card input::placeholder {
	text-transform: uppercase;
	font-size: 11px;
	font-weight: bold;
	opacity: 0.6;
}
.login-form .form-card input:focus {
	box-shadow: rgba(0, 123, 255, 0.5) 0px 0px 0px 1px, rgba(0, 123, 255, 0.25) 0px 0px 0px 0.2rem;
	background: #fff;
}
@media (max-width: 779px) {
	.login-graphic {
		width: 100%;
		height: 95px;
		margin-bottom: 20px;
	}
	.login-graphic h1,
	.login-graphic p  {
		display: none;
	}
	.login-form {
		width: 100%;
		justify-content: flex-start;
	}
	.login-form .flex-container {
		padding-top: 0px!important;
	}
	.login-form .welcome-message h1 {
		margin:0px 0 0 0;
	}
	#loginBtn.login-btn {
		border:solid 1px rgba(255,255,255,0.3)!important;
		border:none;	
		position: absolute;
	}
	.login-form .flex-container {
		min-height: initial!important;
		height: auto;
	}
	.flex-container#login {
		overflow: hidden;
	}
	.signup-click-logo {
		display: block!important;
	}
}


/****NEW LOGIN UI****/
.account-create-container {
	padding:30px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
}
.account-create-logo {
	position: absolute;
	left: 30px;
	top: 30px;
	width: 150px;
}
.account-create-logo img {
	width: 100%;
}
.account-create-inner {
	width: 100%;
	max-width: 800px;
	margin:auto;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
}
.account-create-title {
	width: 45%;
	border-right:solid 1px #efefef;
}

.account-create-title a {
	color: #fd6270
}

.account-create-title h1 {
	font-size: 54px;
    margin: 0;
    font-weight: 500;
}
.account-create-title p {
	margin:10px 0 0 0;
}
.account-create-form {
	width: 50%;
	padding-left: 40px;
	/*border-left: solid 1px #333;*/
}
.account-create-form .form-group {
	margin-bottom: 5px;
}

.account-create-form .form-group img {
	width: 18px;
	height: 18px;
	position: absolute;
	object-fit: contain;
	top: calc(50% - 10px);
	right: 20px;
	left: auto;
	cursor: pointer;
}
.account-create-form input {
	width: 100%;
	padding:18px 10px;
	outline: none;
	font-size: 15px;
	border:none;
	border-bottom: solid 1px #ccc;
	/*border-radius: 6px;
	background: #F0F3F7;*/
}

.account-create-form input:focus {
	background: #F0F3F7;
	border-bottom-color:#fff;
	border-radius: 6px;
}


.signup-form-option {
	font-size: 14px;
	width: 100%;
	margin-top: 20px;
	margin-bottom: 20px;
	color: #efefef;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
}
.signup-form-option span {
	flex-shrink: 0;
	padding:0 20px;
	color: #ccc;
}
.signup-form-option:before,
.signup-form-option:after {
	width: 50%;
	height: 1px;
	content: " ";
	background: #efefef;
	display: block;
}

.signup-terms-links {
	width: 40%;
	text-align: left;
	margin-top: 20px;
	font-size: 14px;
}

.signup-terms-links a {
	color: #fd6270
}

.signup-btn {
	background: #fd6270;
	width: 100%;
	font-size: 15px;
	margin-top: 25px;
	text-align: center;
	padding:16px;
	border-radius: 6px;
	color: #fff;
	font-weight: 600;
	cursor: pointer;
}

.signup-btn.not-allowed{
	background: #ccc;
	cursor: not-allowed;
}

.signup-btn:hover {
	background: #f7a8ae;
}

.signup-btn-provider {
	background: #26BFBF;
	width: 100%;
	font-size: 15px;
	margin-top: 25px;
	text-align: center;
	padding:16px;
	border-radius: 6px;
	color: #fff;
	font-weight: 600;
	cursor: pointer;
}

.signup-btn-provider:hover {
	background: rgb(42, 145, 145);
}

@media (max-width: 779px) {
	.account-create-container {
		height: auto;
		padding-top: 110px;
		margin-bottom: 100px;
	}
	.account-create-inner{
		height: 100%;
	}
	.account-create-title,
	.account-create-form {
		width: 100%;
		border:none;
		padding:0;
	}

	.account-create-title h1 {
		font-size: 40px;
	}

	.account-create-form {
		margin-top: 50px;
	}
	.signup-terms-links {
		width:100%;
	}
}









