.close:not(.burger) {
	width: 20px;
	height: 20px;
	position: absolute;
	left: 20px;
	top:20px;
	cursor: pointer;
}
.close:not(.burger):before, .close:not(.burger):after {
	width: 100%;
	height: 1px;
	position: absolute;
	top: 50%;
	left: 0px;
	content: " ";
	background: #000;
	transform: rotate(-45deg);
}
.close:not(.burger):before {
	transform: rotate(45deg);
}
.modal.modal-custom-alert .modal-content {
    width: 275px;
    height: auto;
    border-radius: 10px;
    text-align: center;
    padding:0;
    flex-direction: column;
 }
.dark .modal-content,
.modal.side.dark .modal-content {
	background: #303550;
	color: #fff;
}
.modal.dark .modal-bottom-footer {
	border:none;
	background: #23263e
}
.modal.modal-custom-alert .modal-content {
    padding: 10px;
    background: #25293e;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.modal.modal-custom-alert .modal-content .fa {
	font-size: 40px;
	color: #f8bb86;
    font-weight: 100;
    margin: 35px 0 10px 0;
}

.modal.modal-custom-alert .modal-content {
	margin:0 auto 30px auto;
}

 .modal.modal-custom-alert.active .modal-content {
	animation: swalModalIn .3s forwards;
	background: #3C4367;
 }

@keyframes swalModalIn {
	0% {opacity: 0; transform: scale(0.8);}
	50% {opacity: 1; transform: scale(1.1);}
	100% {opacity: 1; transform: scale(1);}
}

.close-modal-hotspot {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	background: rgba(0,0,0,0.1);
	box-shadow: 0 0 0 500px rgba(0,0,0,0.1);
}
.modal-content .close {
	width: 26px!important;
    height: 26px!important;
    right: 2px!important;
    top: 2px!important;
    left: auto!important;
    background: #333!important;
    border-radius: 6px!important;
    border: solid 5px #333;
}

.modal-content .close:hover {
	background: #000!important;
	border-color:#000!important;
}

.modal-content .close:before,
.modal-content .close:after {
	background: #fff!important;
	height: 2px!important;
	transition: 0.2s;
}

#navBarModal .close-modal-hotspot {
	opacity: 0;
}
.modal#menuLinksModal {
	justify-content: flex-end;
}
.modal#menuLinksModal .modal-content {
	max-width: 550px;
	border-radius: 6px 6px 0 0;
	padding-bottom: 0;
	animation:fadeIn 0.6s forwards;
	transform: none!important;
	height: calc(100vh - 70px);
}

#legalModal .modal-content {
	border-radius: 6px;
	font-size: 18px;
}

#legalModal .modal-content h2 {
	font-size: 24px;
	margin: 0;
}
#legalModal .modal-bottom-footer .btn {
	border-radius: 6px;
}
.legal-modal-placeholder {
	width:100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-size: 16px;
	text-transform: uppercase;
}
.legal-modal-placeholder img {
	max-width: 200px;
}

.modal-steps-title h2 {
	margin: 0;
	font-size: 20px;
    transform: translateY(-8px);
}
.modal#menuLinksModal .new-page-overlay {
	background: rgba(0,0,0,0);
}
.modal#editButtonModal .input-group {
	margin-top: 12px;
}
.modal#editButtonModal .modal-content {
	width: 350px;
}
.button-shape-selector {
	width:100%;
	display: flex;
	justify-content: space-between;
}

.button-shape-selector div {
	width: calc(33.333% - 10px);
	max-width: 150px;
	opacity: 0.3;
	border:solid 2px #000;	
	padding:8px;
	cursor: pointer;
    text-align: center;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 600;
}
.button-shape-selector div.active,
.button-shape-selector div:hover {
	opacity: 1;
}
.button-shape-selector div:first-child {
	border-radius: 20px;
}
.button-shape-selector div:last-child {
	border-radius: 6px;
}



.modal-empty-placeholder {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 20px;
	justify-content: center;
	/*background: #fff;*/
    margin-top: -54px;
    padding-top: 15px;
    position: relative;
}
.modal-empty-placeholder:after {
	width: 100%;
	height: 100px;
	background: inherit;
	position: absolute;
	left: 0;
	bottom: -100px;
	content: " ";
	z-index: 2;
}

.modal-empty-placeholder .modal-empty-placeholder-img {
	width: 200px;
	margin:30px auto;
}
.modal-empty-placeholder h2,
.modal-empty-placeholder p {
	margin:0;
}
.modal {
	width: 100%;
	height: 100%;
	background: rgba(0,0,0,0.4);
	position: fixed;
	left: 0px;
	top:0px;
	z-index: 22222;
	display: flex;
	flex-direction: column;
	justify-content: center;
    align-items: center;
    visibility: hidden;
	opacity: 0;
	transition: all 0.3s ease;
}
.modal#typePageName {
	display: none;
}

.modal.active#typePageName {
	display: flex;
	animation:fadeInBottom 0.5s forwards;
}
.modal .profile-image-edit:hover {
	cursor: pointer;
	opacity: 0.4;
}
.MuiInput-underline:before,
.MuiInput-underline:after {
	display: none!important;
}
.MuiPickersToolbar-toolbar {
	background: linear-gradient(-45deg, #3c4665 0%, #21283F 100%)!important;
}
.MuiPickersDay-daySelected {
	background: #21283F!important;
}
.MuiPickersDay-current {
	color: #9CA9C3!important;
}
.MuiButton-textPrimary {
	color: #21283F!important;
}
.MuiDialog-root {
	z-index: 400000!important;
}
input[type="file"] {
    display: none;
}

.fu_displaynone{
	display: none !important;
}
.custom-file-upload {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    height: 300px;
    background: #f9f9fa;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /*box-shadow: 10px 10px 30px rgba(0,0,0,0.08);*/
    cursor: pointer;
    z-index: 2;
}

#orderConfirm .modal-content {
	max-width: 450px;
	min-height: 450px;
	height: auto;
	font-weight: normal;
	border-radius: 10px;
	font-size: 16px;
	padding:0px;
	overflow: hidden;
	width: calc(100vw - 20px);
}

#typePageName .modal-content {
	max-width: 380px!important;
	max-height: 300px!important;
	height: auto;
	font-weight: normal;
	border-radius: 10px;
	font-size: 16px;
	padding:30px!important;
	overflow: hidden;
	width: calc(100vw - 20px)!important;
	flex-direction: column;
	animation: fadeInBottom 0.4s forwards;
	position: relative;
}
#typePageName .modal-content .modal-title {
	margin-bottom: 0;
}

#typePageName .modal-content h1 {
	font-size: 25px;
	font-weight: bold;
	margin:0;
}

#typePageName .modal-content {
	font-size: 14px;
	margin:0;
}

#typePageName .modal-content .btn {
	margin:0;
	border-radius: 50px;
}

#typePageName .close:not(.burger) {
	left: auto;
	right: 20px;
}

#typePageName .modal-content .input-group {
	margin-top: 10px;
}


.placing-order-flex {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

#orderConfirm .modal-content {
	justify-content: center;
	align-items: flex-start;
}

#orderConfirm .modal-content .order-confirm {
	width: 100%;
	height: auto;
	margin-bottom: 20px;
}

#orderConfirm .modal-content .continue-btn {
	padding:15px 30px;
	border-radius: 10px;
	background: linear-gradient(-135deg, #81FBB8 0%, #28C76F 100%);
	font-size: 14px;
	text-transform: uppercase;
	margin:20px auto;
	color: #fff;
	box-shadow: 0 6px 12px rgba(0,0,0,0.1);
	cursor: pointer;
	transition: all 0.2s;
}

#orderConfirm .modal-content .close:before,
#orderConfirm .modal-content .close:after {
	background: #fff;
}

#orderConfirm .modal-content .continue-btn:hover {
	transform:translateY(-5px);
}

#orderConfirm .modal-content h4,
#orderConfirm .modal-content p {
	margin:0;
	width: 100%;
	text-align: center;
}
#orderConfirm .modal-content p {
	padding:0 80px;
}
#orderConfirm .modal-content h4 {
	margin-bottom: 5px;
	font-size: 23px;
}
.opacityzero {
	opacity:0;
}

.custom-file-upload strong {
	margin-top: 140px;
	font-size: 16px;
	font-weight: normal;
	opacity: 0.4;
}


.custom-file-upload:hover .image-illustration:after {
	right: -10px;
}

.custom-file-upload:hover .image-illustration:before {
	right: 70px;
}

.custom-file-upload:hover .image-illustration span {
	top: 40px;
	left: 45px;
}

#addMemberModal .modal-content {
	max-width: 450px;
	height: auto;
	min-height: 450px;
	border-radius: 10px;
	width: 95%;
}

#addMemberModal .modal-content:before {
	position: absolute;
	left: 0;
	top: 0;
	background: linear-gradient(-45deg, #455173 0%, #21283F 100%);
	height: 167px;
	content: " ";
	width: 100%;
}

#addMemberModal .modal-title,
#addMemberModal .modal-description {
	color: #fff;
	position: relative;
	z-index: 2!important;
}

#addMemberModal .modal-content .close {
	filter: invert(1);
	left: auto;
	right: 20px;
}

.member-modal-form {
	width: 100%;
	position: relative;
	z-index: 2;
}
.member-modal-form .input-group {
	margin-top: 15px;
}
.member-modal-form .input-group input,
.member-modal-form .input-group select {
	box-shadow: 4px 4px 13px rgba(0,0,0,0.08);
	font-size: 16px;
	border:solid 1px rgba(0,0,0,0.1);
	height: 55px;
}

.select-chip {
	width: 100%;
	display: flex;
	position: relative;
	flex-wrap: wrap;
}

.unsplash-images .select-chip div {
	opacity: 0.8;
}

.unsplash-images .select-chip div:hover {
	color: #fff;
	border-color: #fff;
	opacity: 1;
}

.unsplash-images .cat-select {
	margin-top: 0;
}

.select-chip div {
	padding:5px 10px;
	border-radius: 10px;
	border:solid 1px #ccc;
	color: #ccc;
	cursor: pointer;
	margin:0 8px 8px 0;
	overflow: hidden;
	font-size: 14px;
}

.select-chip div:hover {
	border-color: #333;
	color: #333;
}

.select-chip div.active {
	border-color: transparent;
	background: linear-gradient(-45deg, #455173 0%, #21283F 100%);
	color: #fff;
}

.select-chip div span.active {
	color: #fff;
	background: #21283F;
	box-shadow: 0 0 0 15px #21283F;
}


@keyframes mountainIn {
	0% {transform: translateY(50px) rotate(45deg)}
	100% {transform: translateY(0px) rotate(45deg)}
}

@keyframes sunIn {
	0% {transform: translateY(30px) translateX(-60px) ;}
	100% {transform: translateY(0px) translateX(0px)}
}
.modal#contentsModal .modal-content {
	padding-bottom: 0px;
}

.modal-content .switch-table {
	border:solid 1px #efefef;
}
.modal-content .modal-footer {
	width: calc(100% + 70px);
	justify-content: center;
	padding: 15px 0 15px 0;
	position: sticky;
	margin-left: -35px;
	box-shadow: 0 -4px 8px rgba(255,255,255,1);
	bottom: -35px;
	background: #fff;
}
.modal-content .modal-footer a {
	margin-top: 0!important;
}

.modal.portfolio-modal {
	background: transparent;
	justify-content: flex-start;
	display: inline-block;
	overflow:auto;
	z-index: 4;
}
.modal.portfolio-modal .modal-content {
	box-shadow: none;
	min-height: calc(100vh - 80px);
	height: auto;
	width: 80%;
	margin-top: 80px;
	margin-left: auto;
	margin-right: auto;
	max-width: 750px;
}
.modal.image-modal {
	padding:10px 115px 0px 83px;
	background: rgba(255,255,255,0.9)!important;
}

.modal.image-modal .modal-content {
	height: 100%;
    width: 100%;
    max-width: 900px;
    padding: 0px;
    margin-top: 0px;
    box-shadow: 10px 10px 60px rgba(0,0,0,0.08);
    overflow: visible;
}

.modal-header {
	width: 100%;
	border-bottom: solid 1px #efefef;
	padding:20px;
}

.modal-header h2 {
	font-weight: 300;
	margin:0;
	padding:0;
	font-size: 22px;
}

.modal-footer {
	display: flex;
}
.modal-footer .btn {
	margin:0px 10px;
	border-radius: 30px!important;
}

.modal .modal-footer .btn-empty {
	color: #333;
	background: transparent;
	background: #fff;
}
.modal .modal-footer .btn-empty:hover {
	background:#fafafa;
}

#closeContents {
	transform: scale(1.4,1.4);
	top: 40px;
	right: 40px;
	left: auto;
}
#settingsModal .modal-content {
	max-width: 800px;
}

.promo-modal-container.live-mode {
	opacity: 0;
	position: fixed;
	bottom:0;
	z-index: 400;
	animation: fadeInBottom 0.6s forwards;
	animation-delay: 1s;
	visibility: visible;
}
.promo-modal-container.live-mode .close {
	left: auto;
	top: 10px;
	right: 10px;
	filter: invert(1);
}
.promo-modal-container.live-mode .button-set img {
	position: relative;
	width: 80px;
	margin-left: 20px;
}


.promo-modal-container {
	position: absolute;
	bottom: 0px;
	right: calc(50% - 250px);
	padding:20px 20px 20px 220px;
	background:#fff;
	border-radius: 6px 6px 0 0;
	box-shadow: 0 -8px 16px rgba(0,0,0,0.2);
	width: 100%;
	max-width: 500px;
	font-size: 15px;
	display: flex;
	transition: all 0.5s;
	opacity: 0;
	bottom: -50px;
	visibility: hidden;
	color: #fff;
	background:linear-gradient(180deg, #3c4665 0%, #21283F 100%);
}
.promo-modal-container img {
	width: 215px;
	position: absolute;
	left: 0;
	bottom: 0;
}
.active .promo-modal-container {
	opacity: 1;
	bottom: 0;
	transition-delay: 1s;
}

.promo-modal-container h3 {
	margin:0;
	padding:0;
	font-size: 20px;
}

.promo-modal-container .button-set {
	display: flex; 
	justify-content: flex-start;
	align-items: center;
	margin-top: 15px;
}

.promo-modal-container .button-set .btn,
.promo-modal-container .button-set .publish-btn {
	padding:10px 20px;
	margin: 0;
}

.promo-modal-container .button-set .btn-empty {
	background:transparent;
	color: #333;
	color: #fff;
}

.modal.image-modal .modal-form {
	width: 100%;
	padding:30px 50px;
	    display: flex;
    flex-direction: column;
    justify-content: center;
}
.modal.image-modal .modal-form input.title-input {
	font-size: 20px;
	font-weight: 300;
}
.modal.image-modal .modal-form input, .modal.image-modal .modal-form textarea {
	box-shadow: none;
	border: none;
	border-bottom: solid 1px rgba(0,0,0,0.1);
	border-radius: 0;
	padding-left: 0px;
}

.modal.image-modal .modal-form input:focus, .modal.image-modal .modal-form textarea:focus,
.modal.image-modal .modal-form input:hover, .modal.image-modal .modal-form textarea:hover {
	border-bottom-color: #000;
	padding-left: 0px;
	/*background: #f9f9fa;*/
}



.modal.image-modal .modal-form .input-group {
	margin-top: 0;
	margin-bottom: 10px;
}

.modal.active {
	visibility: visible;
	opacity: 1;
}

.modal.side {
	background: rgba(0,0,0,0);
}

.modal.side .modal-content.narrow {
	width: 300px;
}

#productsInCategory.modal.side .modal-content {
	animation:fadeIn 0.3s forwards;
	transform: none!important;
}

#navBarModal.modal.side .modal-content {
	height: calc(100vh - 140px);
    bottom: 10px;
    top: auto;
    padding: 0;
}

#navBarModal.modal.side .modal-content .input-group {
	padding:20px;
	border-bottom: solid 1px #efefef;
	margin:0;
}

#navBarModal.modal.side .modal-content .input-group label {
	margin-bottom: 5px;
	margin-top: 5px;
}

#navBarModal.modal.side .modal-content .close {
	left: auto;
	right: 10px;
	top: 10px;
}

.nav-layout-picker {
	width: 100%;
	display: flex;
	border:solid 1px #efefef;
	border-radius: 6px;
	overflow: hidden;
	box-shadow: 0 6px 12px rgba(0,0,0,0.1);
}
.nav-layout-picker span {
	width: 14.2%;
	border-right:solid 1px #efefef;
	font-size: 15px;
	color: #ccc;
	padding:10px;
	cursor: pointer;
	text-align: center;
}
.nav-layout-picker span:last-child {
	border:none;
}
.nav-layout-picker span:hover {
	color: #333;
	background: #fafafa;
}
.nav-layout-picker span.active {
	background: linear-gradient(0deg, #3c4665 0%, #21283F 100%);
	color: #fff;
}

.modal.side .close:before,
.modal.side .close:after {
	background: #333;
}

#contentsModal .modal-content {
	width: 500px;
}
.modal-footer.new-page-footer {
	position: fixed;
	background: #fff;
	box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
	bottom:0;
	right: 0;
	transform: translateY(80px);
	opacity: 0;
	visibility: hidden;
	transition:all 0.3s;
}
.modal-footer.new-page-footer.active {
	opacity: 1;
	visibility: visible;
	transform: none;
}

.page-type-title {
	font-size: 15px;
	font-weight: bold;
	width: 100%;
	margin:30px 0px 10px 0;
}
.page-type-title span {
	background: #21283F;
	color: #fff;
	font-weight: 600;
	padding:10px 20px;
	border-radius: 40px;
	margin-left: 10px;
}
.modal.side .modal-content {
	height: calc(100vh - 50px);
	width: 350px;
	position: absolute;
	right:0px;
	border-radius: 0px;
	top: 50px;
	box-shadow: -10px -10px 30px rgba(0,0,0,0.1);
	transform: translateY(0) translateX(100px);
	background: #fff;
	color: #333;
	animation: modalContentIn 0.3s forwards;
}

.modal.side#imagePickerModal {
	transition: .2s;
}

.slider-edit-mode .modal.side#imagePickerModal .modal-content {
	left: 0;
	height: calc(100vh - 50px);
	top: 50px;
	width: 80vw;
}

.modal.side#imagePickerModal .modal-content {
	width: 80vw;
    max-width: 1200px;
    padding: 0;
    height: calc(100vh - 120px);
    right: auto;
    top: 60px;
    visibility: visible;
    opacity: 1;
    animation:none;
}

.modal.side#imagePickerModal {
	display: none;
	transition:none;
}
.modal.side.active#imagePickerModal {
	display: flex;
	animation: fadeIn 0.2s forwards;
}
#imagePickerModal .modal-content .unsplash-images .image-picker-thumb {
	width: calc(33.333% - 10px);
	height: auto;
	margin:5px;
}
#imagePickerModal .modal-content .unsplash-images .image-picker-thumb:before {
	padding-top: 100%;
	display: block;
	content: " ";
}
#imagePickerModal .modal-content .unsplash-images .image-picker-thumb img {
	width: 100%;
	position: absolute;
	top: 0;
	height: 100%;
	left: 0;
	border-radius: 0;
}
.modal.side#imagePickerModal .modal-content.with-tabs:before {
	display: none;
}
.modal.side#imagePickerModal .modal-content .tab-content {
	height: 100%;
	overflow: auto;
	flex: 1;
	padding:20px;
	max-width: initial;
}

.modal.side#imagePickerModal .modal-content .tab-content .unsplash-content {
	margin-top: 0;
}
.image-picker-sidebar {
	width: 250px;
	padding:20px 20px;
	background: #25293e;
}

.image-picker-sidebar.blur,
.unsplash-content.blur {
	/*transition: 0.2s;
	filter: blur(2px);*/
}

.image-picker-sidebar hr {
	display: block;
	width: 100%;
	border-color: #25293e;
	margin:20px 0;
}

.image-picker-sidebar-item {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding:10px 15px;
	border-radius: 8px;
	cursor: pointer;
	margin-bottom: 10px;
	margin-bottom: 4px;
}
.image-picker-sidebar-item img,
.image-picker-sidebar-item .socicon {
	width: 15px;
	height: 15px;
	object-fit: contain;
	margin-right: 17px;
}

 .image-picker-sidebar-item:hover, 
 .image-picker-sidebar-item.current {
 	background: #303550;
 }
#fontSettingsModal.modal.side .modal-content {
	width: 100vw;
	max-width: initial;
	height: 233px;
	top: auto;
	bottom: 0;
	right: 0;
	padding:0px;
	display: block;
	animation:modalBottomIn 0.3s forwards;
}
@keyframes modalBottomIn {
	0% {transform: translateY(200px);}
	100% {transform: translateY(0px);}
}
#fontSettingsModal .font-tabs {
	width: 100%;
	font-weight: bold;
	float: left;
	font-size: 16px;
	background: #f5f4f4;
}

#fontSettingsModal .font-tabs div {
	float: left;
	padding:20px 40px;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	color: rgba(0,0,0,0.5);
}

#fontSettingsModal .font-tabs div img {
	width: 15px;
	height: 15px;
	object-fit: contain;
	margin-right: 7px;
	opacity: 0.5;
}

#fontSettingsModal .font-tabs div:hover,
#fontSettingsModal .font-tabs div.active {
	opacity: 1;
	background: #fff;
	color: rgba(0,0,0,1);

}

#fontSettingsModal .close-modal-hotspot {
	background: transparent;
	box-shadow: 0;
}

#fontSettingsModal .font-tabs div:hover img,
#fontSettingsModal .font-tabs div.active img {
	opacity: 1;
}
.font-group {
	display: none;
	margin-top: 20px;
	white-space: nowrap;
	width: calc(100% + 40px);
	overflow: auto;
	padding-bottom: 16px;
}
.font-group.active {
	display: flex;
}

.font-group div.font-preview-card {
	font-size: 25px;
    font-weight: bold;
    display: inline-block;
    flex-shrink: 0;
    width: 180px;
    padding: 10px 15px;
    border: solid 1px #efefef;
    white-space: normal;
    margin-right: 20px;
    margin-top: 20px;
    border-radius: 10px;
    height: 130px;
    cursor: pointer;
    transition: 0.2s;
    overflow: hidden;
}

.font-group div.font-preview-card:first-child {
	margin-left: 30px;
}
.font-group.font-group-body div{
	font-size: 18px;
}

.font-group div.upload-custom-font:after {
	display: none;
}

.font-group div.upload-custom-font {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	text-align: center;
	font-size: 16px!important;
	background: #F0F3F7;
	position: relative;
	border: none;
	font-weight: 600!important;
}

.font-group div.upload-custom-font .pro-badge {
	position: absolute;
	top: 8px;
	right: 8px;
}
.font-group div.upload-custom-font:hover {
	box-shadow: none;
	background: #fafafa;
}

.font-group div.upload-custom-font img {
	width: 17px;
	height: 17px;
	object-fit: contain;
	margin-bottom: 5px;
}


.font-group div.font-preview-card:after {
	display: block;
	content: "The quick brown fox jumps over the lazy dog.";
	font-size: 14px;
	opacity: 0.4;
	width: 100%;
	font-weight: normal;
	font-family: Source Sans pro;
	margin-top: 10px;
}
.font-group.font-group-body div.font-preview-card:after {
	opacity: 1;
    font-size: 17px;
    line-height: 27px;
    font-family: inherit!important;
}

.font-group.font-group-header div:after,
.font-group.font-group-footer div:after {
	display: none;
}

.font-group.font-group-header div,
.font-group.font-group-footer div {
	font-size: 16px;
}

.font-group.font-group-footer div strong {
	width: 100%;
	text-align: left;
	font-size: 16px;
}

.font-group.font-group-footer div {
	font-weight: normal;
	text-align: center;
	font-size: 14px;
}

.font-group.font-group-header div i {
	font-style: normal;
	font-size: 15px;
	width: 100%;
	display: block;
	margin-top: 7px;
	font-weight: normal;
}
.font-group div.font-preview-card.active,
.font-group div.font-preview-card:hover {
	box-shadow: 0 8px 16px rgba(0,0,0,0.1);
	opacity: 1;
}

.font-group div.font-preview-card.active {
	border-color: #333;
}

#fontSettingsModal.modal.side .modal-content .close:not(.burger) {
	left: auto;
	right: 20px;
}
#fontSettingsModal.modal.side .modal-content h1 {
	margin-top: 0;
}
@keyframes modalContentIn {
	0% {opacity: 0; transform: translateX(100px);}
	100% {opacity: 1; transform: translateX(0);}
}

.modal.side#imagePickerModal .modal-content,
.modal.side#videoPickerModal .modal-content,
.modal.side#musicPickerModal .modal-content,
.modal.side#mapPickerModal .modal-content {
	background: #303550;
	color: #fff;
}

.modal.side#videoPickerModal .modal-content .close,
.modal.side#mapPickerModal .modal-content .close {
	filter: invert(1);
}

.modal.side.active .modal-content {
	transform: translateY(0) translateX(0px);
}

.modal-bottom-footer {
	position: absolute;
	bottom:0;
	left: 0;
	width: 100%;
	border-top:solid 1px #efefef;
	background: #fafafa;
	display: flex;
	justify-content: center;
	padding:10px;
}

.modal-bottom-footer .btn,
.modal-bottom-footer .save-btn {
	margin-top: 0!important;
}

.modal-sticky-footer {
	position: sticky;
	bottom: 0;
	right: 0;
	width: 100%;
	display: flex;
	justify-content: space-between;
	height: 50px;
	align-items: center;
}

.modal-sticky-footer.white {
	background: #fff;
	border-top:solid 1px rgba(0,0,0,0.1);
    z-index: 60;
    width: calc(100% + 70px);
    /* left: -35px; */
    margin-left: -35px;
    margin-right: -35px;
    padding: 0 35px;
    /*box-shadow: 0 -6px 12px rgba(0,0,0,0.1);*/
    height: 60px;
}

.modal-sticky-footer .save-btn {
	margin-top: 0;
}

.settings-content .save-btn {
	float: right;
}


.settings-content .modal-sticky-footer {
	width: calc(100% + 100px);
    margin-left: -50px;
    padding:0 50px;
    bottom: 0px;
    background:rgba(255,255,255,0.9);
    box-shadow: 0 -6px 12px rgba(0,0,0,0.05);
    opacity: 0;
    animation:fadeInBottom 0.6s forwards;
    animation-delay: 0.6s;
    justify-content: flex-end;
}



.modal-content {
	height: calc(100vh - 125px);
	width: 70%;
	max-width: 730px;
	background: #fff;
	box-shadow: -10px -10px 30px rgba(0,0,0,0.1);
	padding:35px;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
	align-content: start;
	position: relative;
	overflow: scroll;
	visibility: hidden;
	opacity: 0;
	transition: all 0.3s ease;
	transform:translateY(100px);
}

#settingsModal .modal-content {
	height: calc(100vh - 60px);
	  padding:0;
	  border-radius: 10px 10px 0 0;
	  overflow: hidden;
}

.modal.active .modal-content {
	opacity: 1;
	visibility: visible;
	transform:translateY(0);
}


.modal.side .extension {
	width:400px;
	height: 100%;
	position: absolute;
	background: #fff;
	padding:40px;
	top: 0;
	z-index: 4;
	transition: all 0.3s ease;
	right: -400px;
	border-left: solid 1px #efefef;
}

.modal.active.expand .extension {
	right: -10px;
	box-shadow: 0px 0px 15px rgba(0,0,0,0.1);
}

.modal-content h1 {
	font-weight: 700;
	font-size: 26px;
	width: 100%;
	margin:20px 0 0 0;
	padding:0;
}
.modal-content .modal-description {
	font-size: 16px;
	margin:0 0 15px 0;
	opacity: 0.5;
	width: 100%;
}

#recommedationModal .custom-file-upload, #editCoverLetterModal .custom-file-upload {
	position: relative;
	width: 200px;
	height: 200px;
	border-radius: 50%;
	font-size: 12px;
	margin:0 auto;
	border: solid 1px rgba(0,0,0,0.05);
}
#recommedationModal .custom-file-upload strong, #editCoverLetterModal .custom-file-upload strong {
	font-size: 12px;
	margin-top: 110px;
	text-align: center;
}
#pageSeo.modal .modal-content {
	opacity: 0;
	animation: fadeIn .5s forwards;
	right: 0px;
	height: calc(100vh - 0px);
	top: 0px;
	background: #fafafa;
	border-right:dashed 1px #efefef;
	width: 420px;
	border-radius: 0;
}
.input-group.addon .addon-line {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.input-group.addon .addon-line input {
	width: 100%;
	margin-left: 20px;
}
.input-group.dates label {
	width: 100%;
}
.input-group.dates {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: row;
	flex-wrap: wrap;
}
.input-group .date-input {
	position: relative;
	width: 45%;
}
.input-group .date-input img {
	position: absolute;
	width: 15px;
	top: 17px;
	left: 13px;
	filter: invert(1);
	z-index: 2;
}
.input-group .date-input input {
	padding-left: 35px;
	width: 100%;
}
.input-group.link-input {
	position: relative;
	cursor: pointer;
}
.load-more-messages {
	padding:10px 20px;
	border-radius: 20px;
	border:solid 1px #ccc;
	color: #ccc;
	display: flex;
	justify-content: center;
	align-content: center;
	margin:20px auto;
	cursor: pointer;
	align-items: center;
	width: 220px;
	position: relative;
}

.load-more-messages:before,
.load-more-messages:after {
	width: 40px;
	height: 1px;
	content: " ";
	background: #efefef;
	position: absolute;
	left: -45px;
	top: 50%;
}
.load-more-messages:after {
	left: auto;
	right: -45px;
}
.load-more-messages i {
	margin-right: 8px;
	transform: translateY(1px);
}
.load-more-messages:hover {
	border-color: #333;
	color: #333;
}
.input-group.link-input .fa {
	position: absolute;
	bottom: 5px;
	left: 5px;
	border-radius: 6px 0 0 6px;
	background: #fff;
	border:solid 1px rgba(0,0,0,0.1);
	height: 40px;
	width: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.input-group.link-input .fa:after {
	width: 6px;
	height: 6px;
	transform: rotate(45deg);
	border-top:solid 1px rgba(0,0,0,0.1);
	border-right:solid 1px rgba(0,0,0,0.1);
	position: absolute;
	top: calc(50% - 3px);
	right: -4px;
	background: #fff;
	content: " ";
}
.input-group.link-input input {
	padding-left: 60px;
	cursor: pointer;
}

.input-group.link-input:hover input {
	box-shadow: rgba(0, 123, 255, 0.5) 0px 0px 0px 1px, rgba(0, 123, 255, 0.25) 0px 0px 0px 0.2rem;
    background: #fff!important;
}
.inner-input-group {
	width: 100%;
	padding:0 15px 15px 15px;
	background: #fafafa;
	margin-top: -1px;
}
.input-group .inner-input-group .date-input {
	width:100%;
}
.inner-input-group select,
.inner-input-group input {
	width: 100%;
}
.input-group .inner-input-group label {
	margin-top: 15px!important;
	display: block;
	width: 100%;
}
.input-group .inner-input-group .date-input input {
	padding-left: 15px;
}

.input-group .inner-input-group label {
	margin-top: 15px;
}
.form-well .switch-table {
	margin-bottom: 15px;	
}
.input-group {
	width: 100%;
	display: flex;
	flex-direction: column;
	margin-top: 15px;
	justify-content: space-between;
}

.input-group legend {
	margin-top: 5px;
	color: #8895a0;
	font-size: 14px;
}

#addlistitem {
	outline: none;
	background: #21283F;
	border:none;
	color: #fff;
	padding:10px 20px;
	display: flex;
	justify-content: center;
	border-radius: 30px;
	cursor: pointer;
	align-items: center;
	margin-top: 20px;
}
#addlistitem:hover {
	background: #313b5c;
}
#addlistitem:before {
	content: "+";
	font-size: 20px;
	margin-right: 10px;
	margin-left: -5px;
	transform: translateY(-2px);
}
.input-group.menu-item {
	display: flex;
	flex-direction: row;
	position: relative;
	margin-top: 10px;
}

.input-group.menu-item input {
	width: 100%;
	height: 40px;
}

.input-group.menu-item input:last-child {
	width: 100px;
	margin-left: 20px;
}

.input-group .remove-menu-item {
	position: absolute;
	left: -20px;
	top: 16px;
	opacity: 0;
	cursor: pointer;
	transition: all 0.2s;
	width: 15px;
	transition-delay: 0.2s;
}

.input-group:hover .remove-menu-item {
	opacity: 1;
	transition-delay: 0s;
}

.input-group.half-width label {
	width: 100%;
}
.input-group.half-width {
	flex-direction: row;
	flex-wrap: wrap;
	align-items: flex-end;
}
.input-group.half-width input, .input-group.half-width select {
	width: 48%;
	background: #fff;
}

.input-group.half-width select {
	margin-top: 10px;
	text-indent: 12px;
}
.input-group.half-width select option {
	padding-left: 20px;
}

.input-group label {
	font-size: 12px;
	text-transform: uppercase;
	font-weight: bold;
	margin-bottom: 4px;
	margin-top: 10px;
	position: relative;
}
.input-group label .publish-switch {
	top: -5px;
	right: 0;
	position: absolute;
}
.input-group input:focus, .input-group textarea:focus {
	/*box-shadow: 4px 4px 17px rgba(0,0,0,0.07);*/
}

.modal-content .input-group select {
	background: #f1f1f1;
}

.extension .input-group input {
	font-size: 18px;
}
.extension .input-group textarea {
	min-height: 400px;
	border: solid 1px rgba(0,0,0,0.1);
	padding:20px;
	border-radius: 6px;
	box-shadow: 0 10px 20px rgba(0,0,0,0.03);
}
.extension .input-group textarea:focus {
	border-color:#000;
}

.extension .modal-footer {
	display: flex;
	justify-content: space-between;
}

.extension .modal-footer .btn-empty {
	background: transparent;
	border: none;
	color: #333;
}

.input-group.size-set {
	width: calc(100% - 100px);
	padding-right: 10px!important;
}

.input-group.size-set h3 {
	margin: 0;
    height: 50px;
    width: 100%;
    padding: 13px 15px;
    border: solid 1px #efefef;
    border-radius: 6px;
    font-size: 16px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
}

.input-group.size-set h3:hover {
	background: #fafafa;
	border-color: #333;
}

.input-group.size-set-smaller {
	width: 100px;
	padding-left: 0!important;
}
.input-group.size-set-smaller:before {
	content: " ";
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	z-index: 1;
}
.input-group.size-set-smaller span {
	width: 20px;
    height: 20px;
    border-radius: 4px;
    border: solid 1px #ccc;
    background: #fff;
    position: absolute;
    z-index: 2;
    left: 3px;
    bottom: 35px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    cursor: pointer;
}
.input-group.size-set-smaller span:hover {
	background: #fafafa;
}
.input-group.size-set-smaller span.plus {
	left: auto;
	right: 23px;
}
.input-group.size-set-smaller input {
	font-size: 14px;
	text-align: center;
}
.input-group input, .input-group select, .input-group textarea {
	height: 50px;
	outline: none!important;
	transition: all 0.2s ease;
	line-height: 50px;
	font-size:16px;
	font-family: Source Sans Pro;
	border-radius: 0px;
	/*box-shadow: 4px 4px 13px rgba(0,0,0,0.04);*/
	border: none;
	padding:0 15px;
	border-radius: 6px;
	margin-bottom: 0px;
	background: #f1f1f1;
}

.input-group input[type="checkbox"],
.input-group input[type="radio"] {
	height: 15px;
    width: 15px;
    padding: 0;
    flex-shrink: 0;
}
.input-group input:focus, .input-group select:focus, .input-group textarea:focus {
		box-shadow: rgba(0, 123, 255, 0.5) 0px 0px 0px 1px, rgba(0, 123, 255, 0.25) 0px 0px 0px 0.2rem;
		background: #fff!important;
}

.input-group textarea {
	min-height: 150px;
	height: auto;
	line-height: normal;
	padding:20px;
}

button.btn {
	border:none;
	outline: none;
}

.btn {
	cursor: pointer;
	transition: all 0.2s ease;
}
.btn:hover {
	opacity: 0.8;
}

.modal .btn {
	font-size: 14px;
	text-transform: uppercase;
	border-radius: 30px;
	color: #fff;
	background: #333;
	padding:15px 30px;
	display: block;
	text-align: center;
	margin-top: 20px;
}
.btn.green {
	background: linear-gradient(-135deg, #81FBB8 0%, #28C76F 100%);
    box-shadow: 0 6px 18px 0 rgba(0,0,0,0.11);
	color: #fff;
	border-radius: 20px;
}

.btn.red {
	background: linear-gradient(-135deg, #ff000085 0%, #ff0000 100%);
    box-shadow: 0 6px 18px 0 rgba(0,0,0,0.11);
	color: #fff;
	border-radius: 20px;
}

.close:not(.burger) {
	    width: 20px;
    height: 20px;
    position: absolute;
    left: 10px;
    z-index: 3;
    top: 10px;
    cursor: pointer;
}
.close:not(.burger):before, .close:not(.burger):after {
	width: 100%;
	height: 1px;
	position: absolute;
	top: 50%;
	left: 0px;
	content: " ";
	background: #000;
	transform: rotate(-45deg);
}

.with-tabs .close:not(.burger):before,
.with-tabs .close:not(.burger):after {
	background: #fff;
}
.close:not(.burger):before {
	transform: rotate(45deg);
}


.modal-subtitle {
	font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 20px;
    margin-top: 20px;
}
h3.modal-sub-title {
	margin-top: 30px;
    margin-bottom: 5px;
    width: 100%;
    font-size: 17px;
}

h1.modal-title,
h2.modal-title {
	font-family: Source Sans Pro!important;
	margin-top: 0!important;
	margin-bottom: 0!important;
}
.modal-title h2 {
	margin:0;
}
.title-badge {
	text-transform: uppercase;
	font-weight: 500;
	color: #fff;
	background: #9ea9c1;
	display: inline-block;
	padding:4px 8px;
	border-radius: 20px;
	font-size: 13px;
	margin-left: 10px;
    transform: translateY(-5px);
}

.title-badge.limit-cleared {
	background: #89C2FF!important;
	color: white!important;
}

.title-badge.limit-approached {
	background: #ffce44!important;
	color: white!important;
}

.title-badge.limit-reached {
	background: #de5246!important;
	color: white!important;
}

.modal-title p {
	margin:0;
}

#contentsModal .modal-title h2 {
	margin:0;
}

#contentsModal .modal-title p {
	margin:10px 0 0 0;
	font-size: 14px;
	opacity: 0.8;
	padding:0;
	font-weight: 500;
}
#contentsModal .modal-title {
	margin-bottom: 30px;
}
.badge {
	background: #D7CF8C;
	border-radius: 10px;
	font-size: 12px;
	display: inline-block;
	font-style: normal;
	padding:2px 5px;
	margin-left: 5px;
	transform: translateY(-8px);
	color: #fff;
}

h4.badge {
		display: block;
    float: left;
    font-size: 16px;
    padding: 6px;
    text-align: center;
}

.locked-state {
	font-size: 13px;
	text-transform: uppercase;
	padding:2px 7px;
	position: absolute;
	top: 10px;
	color: #fff;
	background: linear-gradient(to right, #4facfe 0%, #00f2fe 100%)!important;
	left: auto;
	transform: none;
	right: 10px;
	border-radius: 6px;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 2;
}
.locked-state img {
	width: 12px;
    box-shadow: none;
    border-radius: 0;
    border: none;
}

/***SWITCH***/
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.switch-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
}

.switch-slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
}

input:checked + .switch-slider {
  background-color: #5FBF80;
}

input:focus + .switch-slider {
  box-shadow: 0 0 1px #5FBF80;
}

input:checked + .switch-slider:before {
  transform: translateX(16px);
}

/* Rounded sliders */
.switch-slider.round {
  border-radius: 34px;
}

.switch-slider.round:before {
  border-radius: 50%;
}
.spotify-tabs {
	width: 100%;
	display: flex;
	justify-content: space-between;
	margin-top: 20px;
	margin-bottom: 20px;
}
.spotify-tabs div {
	padding:10px 15px;
	color: #fff;
	border-radius: 40px;
	width: calc(33.333% - 5px);
	background: #21283F;
	text-align: center;
	font-size: 13px;
	cursor: pointer;
	transition: all 0.2s;
	text-transform: uppercase;
}
.spotify-tabs div.active,
.spotify-tabs div:hover {
	color: #21283F;
	background: #fff;
}
.unsplash-content {
	display: flex;
	flex-direction: row;
	margin-top: 35px;
	align-items: flex-start;
	flex-wrap: wrap;
	width: 100%;
}
.infinite-scroll-component__outerdiv,
.unsplash-images {
	width: 100%;
}
#youtube-video-container {
	display: flex;
	flex-direction: row;
}
.video-card {
	width: calc(50% - 20px);
	margin:10px;
	position: relative;
	display: flex;
	flex-direction: column;
}
.video-card-image {
	width: 100%;
	position: relative;
	margin-bottom: 0px;
}
.video-card-image:before {
	display: block;
	padding-top:66.666%;
	content: " ";
}
.video-card .video-card-description {
	width: 100%;
}

.video-card .video-card-description p {
	width: 100%;
	margin:0;
	font-size: 14px;
	opacity: 0.7;
}
.video-card .video-card-description span {
	margin-right: 10px;
	opacity: 0.5;
	font-size: 12px;
}
.video-card .video-card-duration {
	position: absolute;
	top: 6px;
	left: 6px;
	font-size: 12px;
	padding:3px 6px;
	color: #fff;
	background: #000;
	font-style: normal;
	cursor: pointer;
	z-index: 4;
	border-radius: 3px;
}
.video-card img {
	width: 100%!important;
	height: 100%!important;
	flex-shrink: 0;
	position: absolute;
	top: 0;
	margin:0 0px 0 0!important;
}

.video-card.music img {
	height: 125px!important;
}
.video-card.music  {
	margin-bottom: 20px;
}
.video-card h4 {
	margin:10px 0 0 0;
	font-size: 16px;
	font-weight: 600;
}

.video-card p {
	display: none;
}

.wide .unsplash-images.upload-confirm .image-picker-thumb:hover img {
	border:none!important;
}

.image-picker-thumb .action-buttons {
	position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image-picker-thumb:hover .action-buttons {
	opacity: 1;
}

.image-picker-thumb .image-upload-warning {
	font-size: 11px;
	color:#fff;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 3px;
	position: absolute;
	top: 3px;
	left: 3px;
	padding:3px;
	background: #E49758;
}

.side .unsplash-images .image-picker-thumb .image-upload-warning img {
	filter: invert(1);
	height: 12px;
	width: 12px;
	margin:0 3px 0 3px;
	cursor: default;
	background: transparent;
	border-radius: 0;
	object-fit: contain;
}

.upload-warning-well {
	background: #E49758;
	font-size: 14px;
	padding:10px 10px 10px 10px;
	position: relative;
	display: flex;
	align-items: center;
}
.upload-warning-well img {
	height: 22px;
	width: 22px;
	border-radius: 0;
	object-fit: contain;
	margin:0 8px 0 0;
	filter: invert(1);
	background: transparent;
	flex-shrink: 0;
	margin-right: 15px;
	margin-left: 7px;
}
.action-buttons .button {
	cursor: pointer;
}


.image-upload-loader {
	    width: calc(100% - 0px);
    background: rgba(0,0,0,0.1);
    height: 2px;
    position: absolute;
    bottom: 0px;
    border-radius: 0px;
    overflow: hidden;
    left: 0px;
}

.image-upload-loader span {
	font-size: 10px;
    color: #fff;
    position: absolute;
    height: 100%;
    line-height: 10px;
    z-index: 4;
    padding: 0 3px;
}

.image-upload-loader div {
	position: absolute;
	transition: 0.2s;
	z-index: 2;
	height: 100%;
	background: #28B8EA;
}

.unsplash-images.upload-confirm {
	/*position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 40;
	padding:30px;
	background: linear-gradient(0deg, #3c4665 0%, #21283F 100%);*/
}

.confirm-upload-bg {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	background: rgba(48, 53, 80, 0.8);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 44;
}

.confirm-upload-wrapper {
	width: 350px;
	background: #3C4367;
	border-radius: 6px;
	box-shadow: 0 8px 16px rgba(0,0,0,0.3);
	z-index: 5;
	display: flex;
	flex-direction: column;
	animation: fadeInBottom 0.2s forwards;
}
.confirm-upload-scroll {
	flex:1;
	overflow: auto;
	max-height: 400px;
}
.confirm-upload-header {
	background: #1d2131;
	display: flex;
	justify-content: space-between;
	height: 50px;
	padding:10px;
	color: #fff;
	font-weight: 100;
	align-items: center;
}
.confirm-upload-header h4 {
	font-weight: normal;
	font-size: 14px;
	margin:0;
}
.confirm-upload-footer {
	display: flex;
    justify-content: space-between;
    background: #1d2131;
    width: 100%;
    box-shadow: 0 -4px 20px rgb(0 0 0 / 3%);
}
.confirm-upload-footer button {
	padding: 15px 20px;
    width: 50%;
    outline: none;
    border: none;
    background: #28B2E3;
    text-transform: uppercase;
    color: #fff;
    cursor: pointer;
}

.confirm-upload-footer button:hover {
	background: #1A8EB6
}
.confirm-upload-footer button.empty {
    background: #1d2131;
}
.confirm-upload-footer button.empty:hover {
	background: #25293e;
}
.image-size-warning {
	font-size: 12px;
    background: #E49758;
    padding: 3px 8px;
    border-radius: 4px;
    margin-top: 5px;
}
.image-size-warning .fa {
	    margin-right: 5px;
    font-size: 10px;
}
.upload-image-thumb {
	display: flex;
	align-items: center;
	padding:10px;
	border-bottom: solid 1px rgba(0,0,0,0.1);
	width: 100%;
	position: relative;
}
.upload-image-thumb:hover {
	background:rgba(0,0,0,0.05);
}
.upload-image-thumb .remove-thumb {
	margin-left: auto;
	width: 25px;
	height: 25px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	font-size: 12px;
	cursor: pointer;
	opacity: 0.6;
}
.upload-image-thumb .remove-thumb:hover {
	opacity: 1;
}
.upload-image-thumb .remove-thumb:hover {
	background: #1d2131;
}
.upload-image-thumb img {
	width: 75px;
	height: 75px;
	box-shadow: 0 6px 12px rgba(0,0,0,0.1);
	object-fit: cover;
	margin-right: 15px;
}
.unsplash-images {
	flex-wrap: wrap;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	/*height: 523px;
	overflow:auto;*/
	align-content: flex-start;
	padding-top: 6px;
}
.unsplash-images.full-height {
	/*height: calc(100vh - 200px);*/
}
#unsplashModal .input-group.with-icon,
#instagramModal .input-group.with-icon {
	margin-top: 0;
}
.modal .input-group.with-icon img {
	left: 15px;
}
.modal .input-group.with-icon .socicon,
.modal .input-group.with-icon i {
	left: 15px;
	position: absolute;
	top: 15px;
}
.modal .input-group.with-icon i {
	font-weight: bold;
	font-style: normal;
	color: #333;
}

.click-product-info .input-group.with-icon img {
	    position: absolute;
    left: 14px;
    top: 18px;
    width: 16px;
    height: 16px;
}

.modal .input-group.with-icon input {
	padding-left: 40px;
}
.unsplash-images img {
	width: calc(33.333% - 10px);
    height: 150px;
    margin-bottom: 20px;
    object-fit: cover;
    transition: all 0.2s ease;
    box-shadow: 0 5px 15px rgba(0,0,0,0.1);
    cursor: pointer;
}

 .side .unsplash-images img {
 	width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 0.2s;
    box-shadow: 0 5px 15px rgba(0,0,0,0.1);
    cursor: pointer;
    display: inline-block;
	border-radius: 6px;
	background: rgba(0,0,0,0.2);
 }

 .modal.side.wide .modal-content {
	width: 768px;
}

.wide .unsplash-images .image-picker-thumb {
	width: calc(33.333% - 10px);
}


.unsplash-images .image-picker-thumb.selected img {
	border:solid 7px #28B2E3;
}

.wide .unsplash-images .image-picker-thumb:hover img {
	box-shadow: inset 0 0 6px #28B2E3;
}

.wide .unsplash-images .image-picker-thumb .insta-likes img {
	border: none!important;
	opacity: 1!important;
}

.selected-images {
	display: flex;
    font-size: 14px;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    margin-top: 10px;
    position: absolute;
	bottom: 0px;
	background: #303550;
}
.selected-images button {
	background: #28B2E3;
	padding:13px 30px;
	color: #fff;
	border: none;
	outline: none;
    font-size: 14px;
    text-transform: uppercase;
    font-family: source sans pro;
	box-shadow: 0 6px 12px rgba(0,0,0,0.3);
	cursor: pointer;
	margin-left: 10px;
	width: 220px;
	margin-right: 0px;
}
.selected-images button.inactive {
	background: #ccc;
	cursor: no-drop;
}
.selected-images p {
	margin: 0;
	padding:5px 10px;
	border-radius: 20px;
	background: rgba(0,0,0,0.6);
}
.unsplash-images .image-picker-thumb.selected:hover img {
	transform: none;
}
.unsplash-images .image-picker-thumb {
	width: calc(33.333% - 10px);
    height: 150px;
    margin-bottom: 10px;
    position: relative;
    margin-right: 10px;
}

.image-picker-thumb .fa-trash {
	position: absolute;
	width: 25px;
	height: 25px;
	top: 5px;
	right: 5px;
	cursor: pointer;
	border-radius: 50%;
	background: #303550;
	justify-content: center;
    align-items: center;
    font-size: 12px;
    display: none;
}
.image-picker-thumb:hover .fa-trash {
	display: flex;
}

.image-picker-thumb .fa-trash:hover {
	background: #1f2234;
}

.unsplash-images .image-picker-thumb .insta-likes {
	box-sizing: border-box;
    position: absolute;
    z-index: 2;
    display: flex;
    bottom: 0;
    padding: 0;
    margin: 0;
    font-size: 16px;
    justify-content: flex-start;
    align-items: flex-end;
    padding: 10px 10px 7px 10px;
    /*width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.3) 100%);*/
}
.unsplash-images .image-picker-thumb .insta-likes img {
	width: 14px;
    height: 14px;
    object-fit: contain;
    background: transparent;
    box-shadow: none;
    border-radius: 0;
    margin-right: 5px;
    margin: 0 5px 0 0px;
    transform: translateY(-2px);
}
.unsplash-images .image-picker-thumb .unsplash-credit {
	font-size: 10px;
	color: #fff;
	background: #333;
	padding:2px 4px;
	border-radius: 2px;
	position: absolute;
	bottom:5px;
	left:5px;
	opacity: 0;
	visibility: hidden;
	transition: all 0.2s;
}

.unsplash-images .image-picker-thumb:hover .unsplash-credit {
	opacity: 1;
	visibility: visible;
}
.side .unsplash-images img.current {
	box-shadow: 0 8px 16px rgba(0,0,0,0.05), 0 0 0 5px rgba(40, 199, 111, 0.4);
}
.unsplash-images img:hover {
	box-shadow: 0 5px 20px rgba(0,0,0,0.1);
}

#unsplashModal .unsplash-categories {
	width: 140px;
	display: flex;
	flex-direction: column;
}
#unsplashModal .unsplash-categories a {
	margin-bottom: 8px;
}
#unsplashModal .modal-content {
	max-width: 900px;
	padding-bottom: 0;
}

#instagramModal .unsplash-categories {
	width: 140px;
	display: flex;
	flex-direction: column;
}
#instagramModal .unsplash-categories a {
	margin-bottom: 8px;
}
#instagramModal .modal-content {
	max-width: 900px;
	padding-bottom: 0;
}
.image-button-set {
	display: flex;
	flex-direction: column;
	margin:15px auto 15px auto;
	justify-content: center;
}
.profile-image-edit {
	width: 120px;
	height: 120px;
	border-radius: 50%;
	margin:0 auto;
	object-fit: cover;
}
.custom-file-upload.button {
	position: relative;
	height: auto;
	background: #000;
	color: #fff;
	width: auto;
	padding:10px 20px;
	border-radius: 20px;
	margin-bottom: 5px;
}
.custom-file-upload.button:hover {
	opacity: 0.8;
}
.custom-file-upload.button strong {
	margin-top: 0;
	opacity: 1;
	font-size: 13px;
	font-weight: 400;
	text-transform: uppercase;
}
.image-button-set .image-button  {
	opacity: 1;
	font-size: 13px;
	font-weight: 400;
	text-transform: uppercase;
	padding:10px 20px;
	cursor: pointer;
	border-radius: 30px;
	margin:5px 0;
}
.image-button-set .image-button:hover {
	opacity: 0.8;
}
.image-button-set .image-button.instagram-color {
	color: #fff;
}
.image-button-set .image-button.unsplash-color {
	color: #000;
	border:solid 1px #000;
	background: transparent;
}
.side .modal-content .modal-footer {
	margin-top: 20px;
}

.modal-content .image-container {
	display: flex;
	flex-wrap: wrap;
	margin-top: 10px;
}

.modal-content .image-container .List {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}
.modal-content .image-container .draggable {
	width: calc(50% - 5px)!important;
}
.modal-content .image-container .draggable.dragged {
	width: 160px!important;
}
.modal-content .image-container .draggable .img-thumb {
	width: 100%;
}
.modal-content .image-container .draggable.placeholder {
	height: 120px!important;
	width: calc(50% - 5px)!important;
	border:dashed 1px #ccc;
	background: #efefef;
	display: flex!important;
	justify-content: center!important;
	align-items: center!important;
	font-size: 14px;
	text-align: center;
	line-height: 120px;
	font-weight: bold;
	color: #ccc;
	border-radius: 6px;
	margin-bottom: 10px;
}

.modal-content .image-container .img-thumb.add-image {
	border:dashed 1px #ccc;
	cursor: pointer;
	font-size: 12px;
	display: flex;
	justify-content: center;
	align-content: center;
	text-transform: uppercase;
	border-radius: 6px;
	align-items: center;
	flex-direction: column;
	box-shadow: 0 6px 10px rgba(0,0,0,0);
	transition:all 0.3s;
	width: 160px;
}
.modal-content .image-container .img-thumb.add-image:before {
	display: block;
	content: "+";
	width: 30px;
	height: 30px;
	font-size: 20px;
	margin-bottom: 7px;
	color:#fff;
	text-align: center;
	line-height: 30px;
	border-radius: 50%;
	background: linear-gradient(-135deg, #81FBB8 0%, #28C76F 100%);
}
.modal-content .image-container .img-thumb.add-image:hover {
	box-shadow: 0 6px 10px rgba(0,0,0,0.1);
}
.modal-content .image-container .img-thumb {
	width: calc(50% - 10px);
	height: 120px;
	margin-right: 10px;
	margin-bottom: 10px;
	position: relative;
	cursor: move;
}

.modal-content .image-container .img-thumb img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 4px;
}

.modal-content .image-container .img-thumb .button-set {
	position: absolute;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    opacity: 0;
    visibility: hidden;
    transition:0.2s all;
}
.modal-content .image-container .img-thumb:hover .button-set {
	opacity: 1;
	visibility: visible;
}

.modal-content .image-container .img-thumb .button-set i {
	background: #fff;
	padding:5px 10px;
	font-size: 12px;
	color:#000;
	text-transform: uppercase;
	cursor: pointer;
	margin:2px 0;
	font-style: normal;
	border-radius: 20px;
	box-shadow: 0 4px 8px rgba(0,0,0,0.05);
	border:solid 1px #efefef;
}

.SearchInputBox {
	position: sticky;
	z-index: 5;
	top: -30px;
}

.back-to-user {
	display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #fff;
    font-size: 12px;
    margin-top: 20px;
    cursor: pointer;
    margin-bottom: -20px;
    text-transform: uppercase;
}
.back-to-categories {
	color: #000;
	width: 30px;
	height: 30px;
	background:#fff;
	position: absolute;
	top: 8px;
	z-index: 5;
	left: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

.back-to-user:hover {
	text-decoration: underline;
}

.back-to-user img {
	height: 11px;
	filter: invert(1);
	margin-right: 5px;
}

.SearchInputBox input {
	font-size: 14px;
    color: #000;
    text-transform: none;
    caret-color: #1db954;
    box-shadow: 0px 6px 12px rgba(0,0,0,0.1);
    border: none;
    width: 100%;
    padding-bottom: 10px;
    background-color: #fff;
    outline:none; 
    border-radius: 6px;
    padding:15px 10px 15px 40px;
}
.contentSpacing {
	position: relative;
}
.contentSpacing .socicon {
	position: absolute;
	color: #333;
	left: 15px;
	top: 15px;
}

.contentSpacing .imgicon {
	width: 16px;
	position: absolute;
	color: #333;
	left: 15px;
	top: 15px;
}

.contentSpacing .fa {
	position: absolute;
	color: #333;
	right: 15px;
	top: 15px;
}

.powered-by-api {
	font-size: 12px;
	text-transform: uppercase;
}

.SearchInputBox .socicon-youtube {
	color: #c4302b;
}
.SearchInputBox .socicon-instagram {
	color:  #fb3958;
}
.SearchInputBox .socicon-google {
	color: #db3236;
}
.SearchInputBox .socicon-soundcloud {
	color: #ff7700;
}
.SearchInputBox .socicon-spotify {
	color: #84bd00;
}

.SearchInputBox .socicon-vimeo {
	color: #86c9ef;
}

.SearchInputBox .socicon-twitch {
	color: #6441a5;
}

.add-page-btn {
	    background-image: linear-gradient(-135deg, #81FBB8 0%, #28C76F 100%);
    box-shadow: 0 6px 18px 0 rgba(0,0,0,0.11);
    padding: 15px 30px;
    border-radius: 40px;
    font-size: 14px;
    margin: 0 auto;
    color: #fff;
    text-transform: uppercase;
    font-weight: 400;
    cursor: pointer;
    transition: all 0.3s;
    display: inline-block;
    top: 50px;
    position: absolute;
    right: 50px;
}

.modal-content.with-tabs:before {
	height: 85px;
	width: 100%;
	left: 0;
	top: 0;
	position: absolute;
	background: #21283F;
	content: " ";
}

.modal-content.with-tabs.html-edit-tabs:before {
	height: 100px;
}
.modal-content.with-tabs.html-edit-tabs .save-btn {
	margin:auto;
}
.with-tabs .close:before,
.with-tabs .close:after {
	background:#fff;
}
.with-tabs .tabs .tab:hover, 
.with-tabs .tabs .tab.current {
	border-bottom-color: #fff;
}
.with-tabs .tabs .tab {
	border-bottom-color: #21283F;
}
.modal-content .tabs {
	z-index: 4;
}
.modal-content .tabs .tab {
	padding:15px 23px;	
	color: #fff;
}

.floating-plus-button {
	position: fixed;
    right: 40px;
    top: 35px;
    background: linear-gradient(-135deg, #81FBB8 0%, #28C76F 100%);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 14px;
    text-transform: uppercase;
    border-radius: 20px;
    height: 40px;
    padding: 0 15px;
    cursor: pointer;
}

.floating-plus-button:hover {
	background: #28C76F;
}

.floating-plus-button img {
	margin-right: 10px;
}

.modal-content .tabs .tab.tabimage {
	    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.modal-content .tabs .tab.tabimage img {
	height: 16px;
	margin-right: 8px;
}

.modal-content .tabs .tab.tabimage .socicon {
	margin-right: 8px;
}

/**GENERAL TAB STYLES***/
.tabs {
	width: 100%;
	border-bottom: solid 1px #efefef;
	position: relative;
	display: flex;
}
.tabs .tab {
	padding:15px 30px;
	text-transform: uppercase;
	font-size: 14px;
	float: left;
	cursor: pointer;
	border-bottom: solid 2px #fff;
	opacity: 0.5;
}
.tabs .tab:hover,
.tabs .tab.current {
	opacity: 1;
	border-bottom: solid 2px #000;
}

.upload-image-set {
	text-align: center;
}
#imagePickerModal .tab .react-file-reader-button {
	position: sticky;
	bottom: 0;
}
.drop-images-here {
	width: 100%;
	height: 100%;
	min-height: 400px;
	padding:20px;
	color: #fff;
	border-radius: 6px;
	border:dashed 1px rgba(255,255,255,1);
	opacity: 0.5;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 23px;
	font-weight: 600;
	flex-direction: column;
}

.drop-images-here img {
	filter: invert(1);
	width: 40px;
	height: 40px;
	margin-bottom: 10px;
	object-fit: contain;
}

#imagePickerModal .modal-content .tabs {
	overflow: auto;
	border-bottom-color: rgba(255,255,255,0.5);
}

#imagePickerModal .modal-content .tabs .tab {
	width: 33.333%;
	justify-content: center;
	padding:15px 0;
}
#imagePickerModal .tab-content #floatingupload {
	display: flex;
	justify-content: center;
	align-items: center;
}
#imagePickerModal .upload-image-set {
	position: sticky;
	top: -20px;
	z-index: 4;
	margin-bottom: -20px;
}
.upload-image-set .change-image-btn {
	background: #fff;
	color: #333;
}
.upload-image-set img {
	border-radius: 6px;
	box-shadow: 0 5px 15px rgba(0,0,0,0.1);
	margin-bottom: 10px;
	width: 100%;
}

.slide-in-modal.active {
	animation: cartIn .3s forwards;
}

.block-picker-container {
	width: 100%;
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-between;
	overflow: auto;
	padding-left: 30px;
	padding-top: 20px;
}

.modal#contentsModal,
.modal#addSection  {
	animation:fadeIn 0.3s forwards;
}

#addSection .modal-content {
	width: 100vw;
	max-width: initial;
	background: #fafafa;
	top: auto;
	bottom: 0;
	right: 0;
	border-radius: 0;
	height: auto;
	padding:25px 0;
	animation:fadeInBottom 0.3s forwards;
}
#addSection .close-modal-hotspot {
	background: rgba(0,0,0,0.6);
}
#addSection .modal-content .modal-title {
	padding-left: 30px;
}
#addSection .modal-content .close {
	left: auto;
	right: 20px;
}
.block-picker-container h4 {
	width: 100%;
    margin: 30px 0 10px 0;
    text-transform: uppercase;
    font-weight: bold;
    border-bottom: solid 1px rgba(255,255,255,0.1);
    padding-bottom: 5px;
}
.block-picker-container-item {
	width: 250px;
    margin-right: 40px;
    cursor: pointer;
    transition: 0.2s;
    border-radius: 4px;
    position: relative;
    flex-shrink: 0;
}

/*.block-picker-container-item:before {
	width:30px;
	height: 30px;
	border-radius: 50%;
	box-shadow: 0 4px 8px rgba(0,0,0,0.1);
	content: "+";
	text-align: center;
	line-height: 30px;
	font-size: 18px;
	color: #fff;
	position: absolute;
	left: calc(50% - 15px);
	top: 40px;
	transition: all 0.2s;
	background: linear-gradient(-135deg, #81FBB8 0%, #28C76F 100%);
	opacity: 0;
}
.block-picker-container-item:hover:before {
	opacity: 1;
	top: 34px;
}*/
.block-picker-container-item img {
	transition: 0.2s;
	height: 144px;
	object-position: 0 0;
    width:100%;
    object-fit: cover;
    margin-bottom: 5px;
    box-shadow: 0 8px 26px rgba(0,0,0,0.1);
    transition: 0.3s;
}

@keyframes imgBlockSprite {
  0% {object-position: 0 0}
  1.9% {object-position: 0 0}
  2% {object-position: -255px 0}
  33% {object-position: -255px 0}
  33.1% {object-position: -500px 0}
  66% {object-position: -500px 0}
  66.1% {object-position: 0px 0}
  100% {object-position: 0px 0}
}

.block-picker-container-item:hover img {
  box-shadow: 0 8px 36px rgba(0,0,0,0.15);
  transform: translateY(-5px);
  animation: imgBlockSprite 3s infinite;
  animation-delay: 0.3s;
}

.block-picker-container-item span {
	font-weight: bold;
	font-size: 16px;
	display: block;
}
.block-picker-container-item p {
	font-size: 14px;
    margin: 0;
    width: 100%;
}

.modal-main-image-preview,
.modal-main-video-preview {
	width: 100%;
	position: relative;
	margin-top: 0px;
	padding:0 0px;
}
.modal-main-video-preview {
	padding:0;
	height: 200px;
}

.modal-main-video-preview iframe {
	width: 100%;
	height: 100%;
	outline: none;
	border: none;
	border-radius: 6px;
	box-shadow: 0 8px 16px rgba(0,0,0,0.1);
}

.modal-image-form .input-group:last-of-type {
	margin-bottom: 50px;
}
.modal-main-image-preview:hover .hover-box,
.modal-main-video-preview:hover .hover-box {
	opacity: 1;
	visibility: visible;
}
.modal-main-video-preview .hover-box {
	width:auto;
	height: auto;
	left: auto;
	top: auto;
	right: 20px;
	bottom: 20px;
}
.modal-main-image-preview img {
	width: 100%;
	box-shadow: 0 8px 16px rgba(0,0,0,0.1);
	max-height: 300px;
	object-fit: cover;
	border-radius: 10px;
}
.modal-image-form {
	width: 100%;
}
.create-product-form {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
}
.create-product-form .variants {
	padding-left: 30px;
	width: 80%;
}
.create-product-form .input-group.product-imgs {
	width: 20%;
	padding-right: 30px;
}

.side .modal-content .modal-image-form .modal-footer {
	/*position: absolute;
    top: 0px;
    right: 10px;
    margin: 0;
    width: auto;
    padding-top: 0;*/
}



.side .modal-footer .cancel-btn {
	color: #333;
}
.side .modal-footer .cancel-btn:hover {
	color: #000;
}
.modal-image-form textarea {
	min-height: 100px;
}
.block-slide-products-container {
	width: 100%;
}
.block-slide-products-container .separator {
	opacity: 0.1;
	margin:20px auto;
}
.block-slide-product {
	width: 100%;
	display: flex;
	border-radius: 6px;
	padding: 10px;
	box-shadow: 0 8px 16px rgba(0,0,0,0.05);
	align-items: center;
	margin-bottom: 10px;
	transition: all 0.2s;
	cursor: pointer;
	position: relative;
	background: #3C4367;
	color: #fff;
}

.block-slide-product h4,
.block-slide-product span,
.page-pick-item h4,
.page-pick-item span,
.modal-content p {
	font-family: Source Sans Pro!important;
}
.block-slide-product:hover {
	background: #495282;
}
.block-slide-product.disabled {
	opacity: 0.7;
}
.block-slide-product.disabled:hover {
	opacity: 1;
}

.block-slide-product:hover {
	box-shadow: 0 8px 16px rgba(0,0,0,0.1);
}

.block-slide-product img {
	width: 60px;
	height: 60px;
	object-fit: cover;
	margin-right: 10px;
	flex-shrink: 0;
	border-radius: 6px;
}

#pageSeo .block-slide-product img {
	display: none;
}

#pageSeo .block-slide-product {
	padding:15px;
	cursor: pointer;
}



.block-slide-product h4 {
	margin:0;
	font-size: 15px;
	padding-right: 30px;
	font-weight: 600;
}

.block-slide-product span {
	font-size: 14px;
	opacity: 0.5;
	display: none;
	margin-top: -2px;
}
.remove-slide-product {
	width: 20px;
	height: 20px;
	border-radius: 50%;
	background: #000;
	content: "+";
	color: #fff;
	line-height: 20px;
	text-align: center;
	transform: rotate(45deg);
	position: absolute;
	right: -5px;
	top: -5px;
	cursor: pointer;
}

.chart-type-dropdown-wrapper {
	width: 100%;
	height: 52px;
	position: relative;
	z-index: 4;
}

.chart-type-dropdown-wrapper .dropdown-arrow {
	position: absolute;
    right: 12px;
    top: 24px;
    width: 10px;
    z-index: 7;
}
.chart-type-dropdown-wrapper:hover,
.chart-type-dropdown-wrapper.active {
	z-index: 6;
}

.chart-type-dropdown {
	width: 100%;
	border:solid 1px #efefef;
	border-radius: 6px;
	font-size: 15px;
	font-weight: 500;
	position: absolute;
	top: 0;
	left: 0;
	transition: all 0.2s;
	z-index: 4;
	background: #fff;
	color: #21283F;
}
.chart-type-dropdown:hover {
	box-shadow: 0 8px 16px rgba(0,0,0,0.05);
	z-index: 5;
}
.chart-type-dropdown div {
	width: 100%;
	padding:10px 20px;
	cursor: pointer;
	height: 50px;
	display: none;
	align-items: center;
	justify-content: flex-start;
}

.chart-type-dropdown:hover div,
.chart-type-dropdown div.active {
	display: flex;
    font-size: 21px;
}

#fontSettingsModal .chart-type-dropdown-wrapper {
	overflow: hidden;
	border-radius: 6px;
}

#fontSettingsModal .chart-type-dropdown-wrapper.active {
	overflow: visible;
}
.chart-type-dropdown:hover div.active,
.chart-type-dropdown:hover div:hover {
	background: #fafafa;
}

.chart-type-dropdown div img {
	width: 14px;
	margin-right: 10px;
}

.chart-form {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	justify-content: space-between;
}
.chart-form.card {
	padding: 15px 15px 5px 15px;
    border-radius: 6px;
    position: relative;
    margin-bottom: 30px;
    border:solid 1px #efefef;
}
.chart-form.card:last-child {
	margin-bottom: 0;
}
.chart-form.card .input-group {
	margin-top: 0;
}
.chart-form h4.chart-label {
	font-size: 18px;
    margin: 0;
    position: absolute;
    left: 0px;
    top: -24px;
    opacity: 0.3;
}
.chart-form .color-select {
	margin-bottom: 0;
}
.chart-form .input-group.half {
	width: calc(50% - 10px);
}

.chart-form .input-group.half input {
	width: 100%;
	margin-bottom: 10px;
}
.chart-form-input {
	position: relative;
}

.chart-form-input.with-color-select {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.chart-form-input.with-color-select .color {
	width: 39px;
	transform: translateY(-5px);
	height: 30px;
	border-radius: 50%;
	cursor: pointer;
	margin-left: 10px;
}
.remove-chart-value {
	position: absolute;
    left: -20px;
    top: 18px;
    transition: all 0.1s;
    opacity: 0.3;
    cursor: pointer;
    width: 13px;
}
.chart-form-input .remove-chart-value:hover {
	opacity: 1;
}
.modal .product-variables {
	display: flex;
	position: relative;
	justify-content: space-between;
}

.modal .product-variables.wrap {
	flex-wrap: wrap;
    transition: 0.2s;
}

#editProductModal .wrap .default-prices {
	flex-wrap: wrap;
}

#editProductModal .wrap .default-prices .input-group {
	width: calc(11% - 10px)!important;
	margin:0 0 10px 0!important;
}
.product-variables .remove-product-img {
	position: absolute;
	left: -20px;
	bottom: 28px;
	transition: all 0.2s;
	opacity: 0;
	cursor: pointer;
	width: 13px;
}
.product-variables:hover .remove-product-img {
	opacity: 1;
}
#editProductModal .modal-content {
	padding-bottom: 0;
    width: calc(100vw - 40px);
    max-width: initial;
    animation: productModalIn 0.5s forwards;
    display: flex;
    top: auto;
    bottom: 0;
    border-radius: 0;
    height: calc(100vh - 50px);
    right: 20px;
}

@keyframes productModalIn {
	from {transform: translateY(100px); opacity: 0}
	to {transform: translateY(0px); opacity: 1}
}
#editProductModal .modal-content .image-container .List {
	justify-content: flex-start;
}
#editProductModal .modal-content .image-container .draggable {
	width: calc(50% - 5px)!important;
	margin-left: 2px;
	margin-right: 2px;
	transition: 0.1s;
	margin-bottom: 5px;
}
#editProductModal .modal-content .image-container .draggable.dragged {
	width: 90px!important;
}
#editProductModal .modal-content .image-container .draggable:first-of-type {
	width: 100%!important;
}
#editProductModal .modal-content .image-container .draggable:first-of-type .img-thumb {
	height: 160px;
}
#editProductModal .modal-content .image-container .img-thumb {
	height: 100px;
}
#editProductModal .modal-content .image-container .img-thumb {
	margin-bottom: 0px!important;
}
#editProductModal .edit-product-basic-info {
	width: 370px;
	position: relative;
}

@media (max-width: 779px) { 
	#editProductModal .modal-content {
		width: 100vw!important;
		height: 100vh;
		top: 0;
		right: 0;
		flex-direction: row;
	}
	#editProductModal .edit-product-basic-info,
	#editProductModal .edit-product-extra {
		width: 100%;
		margin-bottom: 30px;
	}


}
#editProductModal .default-prices {
	display: flex;
	margin-bottom: 0px;
	justify-content: space-between;
	width: 100%;
}

.product-option-switch {
	    font-size: 15px;
    padding: 15px;
    box-shadow: 0 6px 12px rgba(0,0,0,0.05);
    margin-bottom: 20px;
    border: solid 1px #efefef;
    border-radius: 10px;
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
}
.product-option-switch:before {
	    width: 40px;
    height: 20px;
    border-radius: 10px;
    background: #fafafa;
    border: solid 1px #efefef;
    display: inline-block;
    content: " ";
    margin-right: 10px;
    transition: 0.2s;
}

.product-option-switch:after {
	width: 18px;
	height: 18px;
	background: #fff;
	border-radius: 50%;
	border:solid 1px #efefef;
	content: " ";
	position: absolute;
	top: 16px;
	left: 15px;
	transition:0.2s;

}

.product-option-switch.active:after {
	left: 37px;
}

.product-option-switch.active:before {
	background: #28C76F;
}


#editProductModal .default-prices .input-group {
	width: calc(50% - 10px);
	margin-bottom: 0!important;
	margin-top: 15px;
}
#editProductModal .add-product-image {
	width: 100%;
	padding:15px 0;
	border-radius: 6px;
	border:dashed 1px #efefef;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 600;
    box-shadow: 0 4px 8px rgba(0,0,0,0.06);
    cursor: pointer;
    display: flex;
}
#editProductModal .add-product-image:before {
	width: 25px;
	height: 25px;
	content: "+";
	line-height: 25px;
	font-size: 17px;
	color: #fff;
	border-radius: 50%;
	margin-right: 10px;
	display: inline-block;
	text-align: center;
	transition: 0.3s;
	background: linear-gradient(-135deg, #81FBB8 0%, #28C76F 100%);
}
#editProductModal .add-product-image:hover:before {
	transform: scale(1.2);
}
#editProductModal .edit-product-extra {
	width: 300px;
}
#editProductModal .modal-content .tab-content {
	height: calc(100vh - 85px);
	padding-top: 0;
	padding-bottom: 100px;
}
#editProductModal .modal-content .switch-table {
	margin-top: 15px;
	border:solid 1px rgba(0,0,0,0.2);
	background: #fafafa;
}
.modal .product-variables label {
	width: calc(25% - 10px);
}

.modal .product-variables label:nth-child(1) {
	width: 100%;
}

.modal .product-variables .input-group {
	width: calc(28% - 10px);
}
.modal .product-variables .input-group input {
	padding:0 0 0 6px;
}
.modal .product-variables .input-group:nth-child(1) {
	width: 40%;
}

.input-group label.switch {
	margin-bottom: 0;
}
.product-modal-footer {
	width: 100%;
	position: absolute;
	bottom: 0;
	left: 0;
	height: 70px;
	border-top: solid 1px #efefef;
	display: flex;
	justify-content: center;
	align-items: center;
}
.product-modal-footer .save-btn,
.product-modal-footer .cancel-btn {
	margin:0 10px;
	cursor: pointer;
}
.modal #categoryTable {
	margin-bottom: 15px;
}

.modal #categoryTable .table-row div {
	width: auto!important;
}

.modal .product-variables label {
	font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 5px;
    margin-top: 0px;
}
.modal .product-variables .input-group {
	margin-top: 0;
	margin-bottom: 10px;
}

.modal .nav-position {
	display: flex;
	flex-wrap: wrap;
}
.modal .nav-position span {
	width: calc(50% - 10px);
	margin:5px 5px 0 5px;
	opacity: 0.4;
	cursor: pointer;
}
.modal .nav-position span:hover,
.modal .nav-position span.active {
	opacity: 1;
}
.modal .nav-position span img {
	width: 100%;
	border:solid 1px #efefef;
}
.color-settings-well {
	width: 100%;
	display: flex;
	justify-content: space-between;
	border-radius: 6px;
	border:solid 1px #efefef;
	align-items: center;
	padding:10px;
}
.nav-logo-settings {
	display: flex;
	background: #efefef;
	justify-content: flex-start;
	align-items: center;
	border-radius: 4px;
	border:solid 1px #efefef;
	height: 50px;
	position: relative;
	padding-left: 40px;
}
.nav-logo-settings img {
	height: 100%;
    width: 100%;
    cursor: pointer;
    object-fit: contain;
    padding: 5px;
}

.hide-nav-element {
	width: 26px;
	height: 26px;
	border-radius: 4px;
	position: absolute;
	left: 5px;
	bottom: 11px;
	display: flex;
	justify-content: center;
	align-items: center;
	background: #fff;
	border:solid 1px #efefef;
	font-size: 12px;
	cursor: pointer;
}

.hide-nav-element:hover {
	background: #efefef;
}

.hide-nav-element.active .fa.fa-eye-slash:before {
	content: "\f06e"
}

.hide-nav-element span {
	width: 60px;
	text-align: center;
	padding:4px 0px;
	background: #333;
	position: absolute;
	left: calc(50% - 30px);
	bottom: 100%;
	opacity: 0;
	font-size: 11px;
	color: #fff;
	border-radius: 4px;
	visibility: hidden;
	transition:0.2s;
	z-index: 3;
}

.hide-nav-element:hover span {
	opacity: 1;
	visibility: visible;
}
.nav-logo-settings img:hover {
	opacity: 0.5;
}

.nav-logo-settings .publish-switch {
	transform: scale(0.8);
}
.logo-size-settings {
	width: calc(100% - 120px);
	height: 100%;
	border-right:solid 1px #efefef;
	display: flex;
	justify-content: space-between;
	font-weight: 600;
	font-size: 13px;
	padding:0 10px;
	align-items: center;
	margin-right: 5px;
}

.logo-size-settings div {
	width: 30px;
	height: 30px;
	border-radius: 6px;
	border:solid 1px #efefef;
	background: #fff;
	color: #333;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 18px;
	font-weight: 600;
	cursor: pointer;
}
.logo-size-settings div:last-child {
	margin-right: 0;
}

.logo-size-settings div:hover {
	color: #fff;
	background: #7FCA76;
}

#emailSettings .modal-content {
	height: calc(100vh - 150px);
	top:auto;
	bottom: 0;
}
#emailSettings .close-modal-hotspot {
	background: transparent;
}
#emailSettings .modal-content h4 {
	font-size: 20px;
	margin:0;
}

#emailSettings .modal-content legend {
	font-size: 14px;
	line-height: normal;
	opacity: 0.6;
	margin-bottom: 20px;
}
.email-html-holder {
	width: 100%;
	max-width: 600px;
}
.table-row .fa-pen {
	font-size: 12px;
    padding: 5px;
    border: solid 1px #efefef;
    border-radius: 4px;
    margin-right: 8px;
    margin-left: -10px;
    cursor: pointer;
}
.table-row .fa-pen:hover {
	background: #efefef;
	border-color: #ccc;
}
.switch-table {
	width: 100%;
	display: flex;
	flex-direction: column;
	border-radius: 6px;
	overflow: hidden;
	background: #fff;
}
.switch-table .table-row {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 50px;
	color: #000;
	font-size: 14px;
	font-weight: 500;
	padding:0 20px;
	border-bottom: solid 1px #efefef;
}
.switch-table .table-row h4 {
	margin:0;
	font-size: 17px;
}
.switch-table .table-row:last-of-type{
	border-bottom: none;
}

.dark-white-buttons {
	display: flex;
	border-radius: 50px;
	overflow: hidden;
	justify-content: flex-start;
	margin:0;
	position: absolute;
	right: 0;
	top: -11px;
	border:solid 1px #0F121C;
	background: #0F121C;
	box-shadow: 0 0 0 2px rgba(255,255,255,0.1);
}

.dark-white-buttons.not-absolute {
	position: relative;
	top: auto;
	justify-content: space-between;
	margin:10px auto;
	float: left;
}
.dark-white-buttons.not-absolute button {
	width: auto;
	padding:10px 20px;
}
.dark-white-buttons button {
	outline: none;
	text-transform: uppercase;
	border:none;
	width: 70px;
	margin:0;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding:10px 0;
	background: #0F121C;
	border-radius: 50px;
	cursor:pointer;
	color: #fff;
}

.dark-white-buttons button:not(.active):hover {
	background: #181C29;
}

.dark-white-buttons button.active {
	background: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(229,229,229,1) 100%); 
	color: #21283F;
}

.dark-white-buttons button:first-child {
	border-right: solid 1px #efefef;
}
.modal .ace_editor {
	width: 100%!important;
}
.picker-iframe {
	width: 100%;
	margin-top: 30px;
}

#mapPickerModal iframe {
	width: 100%;
	margin-top: 30px;
	border-radius: 10px;
	border: 0;
}

.loader-modal.modal .modal-content {
  height: auto;
  min-height: 280px;
  max-width: 500px;
  border-radius: 0 0 10px 10px;
}

.loader-modal.modal .modal-content .success-icon {
	margin:20px auto;
	transform: scale(0.7);
}
.loader-modal.modal .modal-content .modal-footer {
	padding:0;
}

.loader-modal.modal .modal-content {
	transform: translateY(-50px);
}
.loader-modal.modal.active .modal-content {
	transform: translateY(0px);
}

.loader-modal .modal-content .modal-title {
  width: 100%;
  text-align: center;
}

.loader-modal .modal-content .modal-title h2,
.loader-modal .modal-content .modal-title {
  margin:0;
}

.loader-modal.modal {
	justify-content: flex-start;
}

.loader-modal .page-loader {
  position: relative;
    opacity: 1;
    left: auto;
    top: auto;
    visibility: visible;
    margin:30px auto;
    transform: scale(1.2,1.2);
}
.publish-progress,
.publish-success {
  width: 100%;
}

.success-icon {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border:solid 2px #5FBF80;
  position: relative;
  margin:50px auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.success-icon:before {
  width: 30px;
  height: 15px;
  border-bottom: solid 2px #5FBF80;
  border-left:solid 2px #5FBF80;
  transform: rotate(315deg);
  margin-top: -10px;
  content: " ";
}

.publish-share-container {
	width: 100%;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	margin-top: 10px;
	width: 100%;
	max-width: 500px;
	margin:0 auto;
	align-items: center;
	animation:fadeInBottom 1s forwards;
}
.publish-share-container h1 {
	margin:15px 0;
	font-size: 20px;
	color: #fff;
	width: 100%;
	text-align: center;
}
.publish-share-card {
	width: 290px;
	border-radius: 6px;
	overflow: hidden;
	position: relative;
	background: #fff;
}
.publish-share-card  .publish-share-card-description {
	padding:15px;
}
.publish-share-card:hover .hover-box {
	opacity: 1;
	visibility: visible;
}

.publish-share-card .hover-box .change-image-btn img {
	filter: invert(1);
	margin-right: 10px;
}
.publish-share-card h4 {
	margin:0;
	font-size: 16px;
}
.publish-share-card p {
	font-size: 14px;
	margin:0;
	width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.publish-share-card span {
	font-size:12px;
	opacity: 0.4;
	margin:20px 0 0 0;
	display: inline-block;
}
.publish-share-card img {
	height: 160px;
	width: 100%;
	object-fit: cover;
}

.publish-share-card-legend {
	width: 230px;
	font-size: 12px;
	margin-top: 10px;
	color: #fff;
}

.publish-share-card-legend i {
	text-decoration: underline;
}
.publish-share-icons {
	padding:0 0px;
	margin-top: 20px;
	color: #fff;
	width: 200px;
	display: flex;
	justify-content: space-between;
}

.publish-share-icons div {
	margin-bottom: 15px;
	cursor: pointer;
	display: flex;
	justify-content: flex-start;
	position: relative;
	align-items: center;
	outline: none;
}

.publish-share-icons div span {
	position: absolute;
	opacity: 0;
	width: 70px;
	text-align: center;
	padding:4px;
	background: #000;
	font-size: 12px;
	color: #fff;
	border-radius: 4px;
	 bottom: -26px;
	 left: calc(50% - 35px);
	 transition: 0.2s;
	 visibility: hidden;
	 transform: translateY(5px);
}

.publish-share-icons div:hover span {
	opacity: 1;
	visibility: visible;
	transform: none;
}
.publish-share-icons .socicon,
.publish-share-icons .fa {
	width: 30px;
	height: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	font-size: 13px;
}

.publish-share-icons .fa {
	background: #fff;
	color: #000;
}



#helpModal .modal-content {
	width: 100%;
	max-width: 500px;
	border-radius: 8px;
	height: auto;
}
#helpModal .modal-title p {
	font-size: 16px;
	margin-bottom: 20px;
}
#helpModal .modal-title a {
	color: #99A6C0;
}
#helpModal .modal-title a:hover {
	text-decoration: underline;
}
#helpModal .input-group {
	margin-top: 12px;
}
#helpModal .modal-content .close {
	left: auto;
	right: 20px;
}
#helpModal .modal-content h1 {
	margin-top: 0;
}

.modal.loader-modal {
	display: none;
}

.modal.loader-modal.active {
	display: flex;
	background: rgba(0,0,0,0.8);
	background: linear-gradient(0deg, rgba(60,70,101,1) 0%, rgba(33,40,63,0.7) 100%);
	z-index: 40000;
}

.help-modal-table {
	width: 100%;
	margin-top: 20px;
}
.help-modal-table a {
	float: left;
	padding:20px 10px;
	border-top: solid 1px #efefef;
	width: 100%;
	display: flex;
	align-items: center;
}
.help-modal-table a:hover {
	background-color: #fafafa;
}
.help-modal-table a .socicon {
	margin-right: 10px;
}

/**GENERAL TAB STYLES END***/

@media (max-width: 779px) {
	#editProductModal .modal-content .image-container {
		flex-wrap: wrap;
		flex-direction: column-reverse;
	}
	.modal.side.wide .modal-content {
		width: 100vw;
	}
	.wide .unsplash-images .image-picker-thumb {
		height: 80px;
	}

	#editProductModal .add-product-image {
		border:none;
		height: auto;
		width: 130px;
		width: auto;
		padding:10px 20px;
		background: linear-gradient(-135deg, #81FBB8 0%, #28C76F 100%);
		flex-direction: row;
		color:#fff;
		margin-bottom: 20px;
	}
	#editProductModal .add-option-button {
		    width: 100%!important;
    text-align: center;
    border-radius: 6px;
	}
	#editProductModal .add-product-image:before {
		background: transparent;
	}
	#editProductModal .modal-content .image-container .draggable {
		width: 80px!important;
	}
	#pageSeo.modal .modal-content {
		width: 100vw;
		right: 0;
	}
	.modal.side .modal-content {
		width: 100%!important;
	}
	.promo-modal-container {
		right: 0;
		padding:20px;
	}
	.promo-modal-container .promo-modal-img {
		display: none;
	}
	.modal.side .modal-content {
	/*height: 100vh; */
	height: 100%;	
	top: 0;
	right: 0;
	border-radius: 8px 0 0 8px;
	}
	.side#pageSeo .modal-content .modal-footer {
		position: relative;
		margin-top: 0;
	}
	
	.create-product-form .variants {
		padding-left: 0px;
		width: 100%;
	}
	.create-product-form .input-group.product-imgs {
		width: 100%;
		padding-right: 0px;
	}
	#editProductModal .wrap .default-prices {
		flex-wrap: nowrap;
	}
	#editProductModal .wrap .default-prices .input-group {
		flex-shrink: 0;
		width: 100px!important;
		margin-right: 10px!important;
	}

	#fontSettingsModal.modal.side .modal-content {
		padding:0;
		height: 238px;
		width: 100vw!important;
		border-radius: 0;
	}
	#fontSettingsModal.modal.side .modal-content .close {
		display: none!important;
	}
	#fontSettingsModal .font-tabs div {
		padding:10px 13px;
	}
	#fontSettingsModal .font-group {
		padding-left: 20px;
	}
	.font-group div {
		font-size: 19px;
	}
	.unsplash-images .image-picker-thumb {
	width: calc(50% - 10px)!important;
	height: 150px!important;
	margin: 5px;
	}
	.modal.side .modal-content.with-tabs {
		padding:50px 0px;
	}
	.modal.side#imagePickerModal .modal-content.with-tabs {
		padding-top: 20px;
	}


	.floating-plus-button  {
		top: 10px;
		right: 10px;
	}
	.modal-content .tab-content {
		padding:20px;
	}

	.modal.side#imagePickerModal .modal-content, 
	.modal.side#videoPickerModal .modal-content, 
	.modal.side#musicPickerModal .modal-content, 
	.modal.side#mapPickerModal .modal-content {
		width: calc(100% - 0px)!important;
		animation: slideInRight 0.8s forwards;
	}

	.modal.side#mapPickerModal .modal-content  {
		padding:30px 0px;
	}
	.modal.side#videoPickerModal .modal-content {
		padding-left: 0px;
		padding-right: 0px;
	}
	.modal-content .tabs .tab {
		padding:15px!important;
	}

	.html-tabs .dark-white-buttons {
		position: fixed;
		top: 10px;
		right: 10px;
	}
	.modal#menuLinksModal .modal-content {
		width: 100%;
		padding:20px;
		padding-bottom: 0;
		height: 100vh;
	}


}

#themeSettingsModal {
	justify-content: flex-end;
}

#themeSettingsModal .modal-content {
	visibility: visible;
	opacity: 1;
	transform: none;
	animation:fadeInBottom 0.5s forwards;
	border-radius: 6px 6px 0 0;
	width: 80%;
	max-width: 1000px;
	height: calc(100vh - 80px);
	padding:0;
	display: block;
}
#themeSettingsModal .close {
	left: auto;
	right: 20px;
}
.themes-navigation {
	width: 270px;
	padding:15px;
	overflow: scroll;
	height: 100%;
	float: left;
	background: #25293e;
}

.themes-navigation.narrow-fit {
	width: 220px;
}

.themes-navigation.narrow-fit div:after {
	/*width: 100%;
	height: 1px;
	background: #efefef;
	bottom: -2px;
	content: " ";
	position: absolute;
	left: 0;*/
}

.themes-navigation div {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding:15px;
	border-radius: 6px;
	margin-bottom: 6px;
	opacity: 0.7;
	position: relative;
	color: #fff;
	cursor: pointer;
}

.themes-navigation div img {
	width: 17px;
	height: 19px;
	object-fit: contain;
	margin-right: 15px;
	filter: invert(1);
}
.themes-navigation div h4 {
	margin:0;
	font-size: 16px;
	font-weight: normal;
}

.themes-navigation div h4 span {
	font-size: 13px;
	line-height: 13px;
	font-weight: normal;
	width: 100%;
	float: left;
}

.themes-navigation div.active, 
.themes-navigation div:hover {
	opacity: 1;
	background: #303550;
}

.page-name-content {
	width: calc(100% - 220px);
	height: 100%;
	display: none;
	position: absolute;
	right: 0;
	top: 0;
	background: inherit;
    display: none;
    flex-direction: column;
    justify-content: space-between;
    background: #303550;
    color: #fff;
}
.page-name-content.active {
	display: flex;
	animation:fadeIn 0.4s forwards;
}
.page-name-form {
	width: 100%;
    padding: 40px;
    max-width: 500px;
    margin: 50px auto 0 auto;
    animation:fadeInTop 0.5s forwards;
}

.page-limit-reached-container {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-content: center;
	flex-direction: column;
	position: absolute;
	right: 0;
	left: 0;
	background: #fff;
	padding:70px;
	text-align: center;
}

.page-limit-reached-container h1,
.page-limit-reached-container h3 {
	margin:0;
}

.page-limit-reached-container h3 {
	font-weight: normal;
}

.page-limit-reached-container img {
	width: 300px;
	margin:30px auto;
}

.page-limit-reached-container .btn {
	margin:0 auto;
	font-size: 16px;
    border-radius: 40px;
}

.page-name-thumb {
	width: 320px;
	margin:0 auto;	
	border-radius: 10px 10px 0 0;
	border:solid 1px #efefef;
	/*background: #fff;*/
	position: relative;
	box-shadow: 0px 0px 26px rgba(0,0,0,0.1);
	animation:fadeInBottom 0.5s forwards;
	margin-bottom: 0px;
}

.page-name-thumb .page-name-thumb-img {
	width: 100%;
	display: block;
	border-radius: 6px 6px 0 0;
}

.page-name-thumb .layout-tip {
	height: 70px;
	height: 70px;
	object-fit: contain;
	top: 10px;
	left: 10px;
	position: absolute;
	animation:sizeIn 0.8s forwards;
}

.page-name-tip {
	width: 100%;
	position: absolute;
	bottom: 0;
	right: 0;
	background: #efefef;
	display: flex;
	padding:20px;
	font-size: 16px;
	align-items: center;
	justify-content: flex-start;
	animation:fadeInBottom 0.5s forwards;
}
.tip-icon {
	width: 20px;
    height: 20px;
    margin-right: 30px;
    object-fit: contain;
}

.page-name-tip p {
	margin:0;
}

.page-name-tip p img {
	width: 14px;
    height: 14px;
    object-fit: contain;
    transform: translateY(3px);
}
.page-limit-number {
	height: 26px;
    padding: 6px 12px;
    position: absolute;
    background: #89C2FF;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    right: 32px;
    top: 3px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    cursor: pointer;
    z-index: 20;
    box-shadow: 0 6px 12px rgba(0,0,0,0.08);
    opacity: 0;
    animation:sizeIn 0.3s forwards;
    animation-delay: 1s;
}

.page-limit-number.limit-approached{
	background: #ffce44;
}

.page-limit-number.limit-reached{
	background:#de5246;
}

@keyframes sizeIn {
	0% {opacity: 0; transform: scale(0.7);}
	100% {opacity: 1; transform: scale(1);}
}

.page-limit-number span {
	position: absolute;
	left: 0;
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	opacity: 0;
	text-transform: uppercase;
	transition: 0.2s;
	background: inherit;
}

.page-limit-number:hover span {
	opacity: 1;
}

.themes-config-content {
	width: calc(100% - 270px);
	position: relative;
	padding:40px;
	float: left;
	display: flex;
	flex-wrap: wrap;
	height: 100%;
	overflow: scroll;
	align-content: flex-start;
	background: #303550;
	color: #fff;
}

.themes-config-content.wide-fit {
	width: calc(100% - 220px);
}

.themes-config-content.wide-fit .theme-config-card,
.themes-config-content.wide-fit .theme-config-card img {
}

.themes-config-content.wide-fit .theme-config-card {
	box-shadow: 0 8px 16px rgba(0,0,0,0.1);
}

.themes-config-title {
	width: 100%;
	margin-bottom: 20px;
	float: left;
}

.themes-config-title h2,
.themes-config-title p {
	margin:0;
}
.add-to-favs {
	width: 30px;
	height: 30px;
	border-radius: 6px;
	background: #fff;
	border:solid 1px #efefef;
	display: flex;
	justify-content: center;
	align-items: center;
	opacity: 0;
	visibility: hidden;
	transform: scale(0.8);
	transition: 0.1s;
	position: absolute;
	right: 4px;
	top: 4px;
	z-index: 20;
	font-size: 14px;
}

.add-to-favs .far,
.add-to-favs .fas,
.add-to-favs .fa {
	transform: translateY(1px);
}

.add-to-favs.active {
	background: #E1306C;
	color: #fff;
}

.add-to-favs.active:hover {
	border:solid 1px transparent;
	background: #b91f53;
}

.add-to-favs:hover {
	border:solid 1px #333;
	background: #fafafa;
}

.theme-config-card:hover .add-to-favs {
	opacity: 1;
	visibility: visible;
	transform: none;
}
.theme-config-card {
	width: calc(50% - 30px);
	margin:15px 15px 15px 15px;
	border-radius: 6px;
	cursor: pointer;
	position: relative;
	transition: 0.2s;
}

.theme-config-card:hover img {
	box-shadow: 0 0 0 3px #28B8EA;
}


.theme-config-card img {
	position: relative;
	z-index: 2;
	width: 100%;
	display: block;
	border-radius: 6px;
	transition: 0.2s;
	box-shadow: 0 0 0 0px #28B8EA;
}

.theme-config-card p {
	width: 100%;
	margin:0;
	padding:10px;
	font-size: 12px;
	text-align: center;
	color: rgba(0,0,0,0.5);
	background: #fff;
	position: relative;
	border-radius: 0 0 6px 6px;
	z-index: 2;
}
.theme-config-card:after {
	content: " ";
	height: 20px;
	width: calc(100% - 80px);
	background: #000;
	filter: blur(10px);
	position: absolute;
	left: 40px;
	opacity: 0;
	bottom: 0;
	transition: 0.2s;
}

.theme-config-card:hover:after,
.theme-config-card.active:after {
	opacity: 0.4;
}

.theme-config-card.active,
.theme-config-card:hover {
	box-shadow: 0 8px 16px rgba(0,0,0,0.1);
}

.theme-config-card span {
	position: absolute;
	top: 5px;
	left: 5px;
	border-radius: 6px;
	padding:5px;
	text-transform: uppercase;
	font-size:12px;
	color: #fff;
	background: linear-gradient(-135deg, #81FBB8 0%, #28C76F 100%);

}
#themeSettingsModal .font-group {
	display: block;
	white-space: normal;
	overflow: initial;
    margin: 0 0 20px 0;
    width: 100%;
    padding-bottom: 0;
}

#themeSettingsModal .font-group .font-config-preview {
	padding:10px 0px;
	position: relative;
}

#themeSettingsModal .font-group div.font-config-preview:first-child {
	padding-top: 0;
}

#themeSettingsModal .font-group div.font-config-preview:last-child {
	padding-bottom: 0;
}

#themeSettingsModal .font-group .font-config-preview h1 {
	font-size: 60px;
	margin:0;
}
#themeSettingsModal .font-group .font-config-preview h2 {
	font-size: 50px;
	margin:0;
}

#themeSettingsModal .font-group .font-config-preview h3 {
	font-size: 40px;
	margin:0;
}

#themeSettingsModal .font-group .font-config-preview h4 {
	font-size: 30px;
	margin:0;
}

#themeSettingsModal .font-group.font-group-settings {
	padding:0px 0 30px 0;
	border-top:solid 1px #efefef;
}

.input-group .input-group-inner {
	width: 100%;
	position: relative;
	margin-bottom: 10px;
	display: flex;
    align-items: center;
    justify-content: space-between;
}

.input-group .input-group-inner .fa-trash {
	width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background: #333;
    border-radius: 50%;
    position: absolute;
    right: 10px;
    top: calc(50% - 10px);
    cursor: pointer;
    opacity: 0;
    visibility: hidden;
    transform: scale(0.8);
    transition: 0.2s;
    font-size: 13px;
}

.input-group .input-group-inner:hover .fa-trash {
	opacity: 1;
	visibility: visible;
	transform: none;
}

.input-group-inner .delete-icon {
	position: absolute;
	width: 20px;
	height: 20px;
}
.input-group .input-group-inner .custom-value-input {
	width: 100%;
}
#customFormModal {
	width: 300px;
	left: auto;
	right: 0;
}

#customFormModal .input-group {
	    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 0px;
    justify-content: space-between;
    padding-bottom: 20px;
    margin-bottom: 10px;
    border-bottom: solid 1px #efefef;
}

#customFormModal .input-group label {
	margin-top: 0;
	margin-bottom: 7px;
}

#customFormModal .modal-sticky-footer {
	position: absolute;
    bottom: 10px;
    left: -10px;
    width: 100%;
    background: #fafafa;
    justify-content: center;
    border-radius: 0 0 6px 6px;
    border: solid 1px #efefef;
    padding: 6px 0;
    height: 60px;
    opacity: 0;
    animation:fadeInBottom 0.3s forwards;
    animation-delay: 0.4s;
}

#customFormModal .modal-content {
	width: 100%;
	padding:20px 20px 90px 20px;
}
.form-element-item {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	font-size: 16px;
	font-weight: 600;
	border-radius: 6px;
	border:solid 1px #efefef;
	margin-bottom: 12px;
	padding:13px;
	width: 100%;
	cursor: grab;
}
.form-element-item:hover {
	border-color:#ccc;
}
.form-element-item img {
	width: 15px;
	height: 15px;
	object-fit: contain;
	margin-right: 8px;
}

.modal-content.no-padding {
	padding:0!important;
}

.modal-content-scroll {
	padding:30px;
	height: 100%;
	width: 100%;
	overflow: auto;
	display: flex;
	flex-wrap: wrap;
}

@media (max-width: 779px) {
	.page-name-content {
		width: 100vw;
	}
	#themeSettingsModal .modal-content {
		width: 100%;
		height: 100%;
		border-radius: 0;
	}

	.themes-navigation.narrow-fit {
		width: 150px;
		padding:5px;
	}

	.themes-navigation.narrow-fit div {
		padding:10px;
	}

	.themes-navigation.narrow-fit div img {
		margin-right: 10px;
		width: 15px;
	}
	#themeSettingsModal .close {
		right: 10px;
		top: 10px;
	}
	.themes-config-content.wide-fit {
		width: calc(100% - 150px);
		padding:20px;
	}

	.themes-config-content.wide-fit .theme-config-card {
		width: 100%;
		margin:10px 0;
	}

	.themes-navigation div h4 {
		font-size: 14px;
	}
	#customFormModal .modal-sticky-footer {
		right: 0;
		bottom: 0;
	}
	.edit-body-mode .custom-form-item-wrapper {
		border: dashed 1px rgba(0,0,0,0.3)!important;
		margin-bottom: 15px;
	}
	.custom-form-item-wrapper .edit-custom-form-item {
		opacity: 1;
		visibility: visible;
	}

	#legalModal .modal-content-scroll {
		padding:0;
	}
	#legalModal .modal-content {
		width: 90%;
	}
	.unsplash-images {
		overflow: unset!important;
	}
}






.font-config-toolbar {
	width: 100%;
	display: flex;
	margin-bottom: 20px;
	margin-top: 20px;
	justify-content: space-between;

}


.font-config-toolbar-group {
	font-size: 14px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-shrink: 0;
}
.font-config-toolbar-group strong {
	margin-right: 10px;
	font-weight: 600;
}
.font-config-toolbar-group span {
	padding:5px 10px;
	border:solid 1px #efefef;
	cursor: pointer;
}

.font-config-toolbar-group span:hover {
	background: #fafafa;
}

.font-config-toolbar-group span:after {
	width: 5px;
	height: 5px;
	border-top: solid 2px #333;
	border-right: solid 2px #333;
	transform: rotate(45deg);
	margin-left: 6px;
	content: " ";
	display: inline-block;
}

.h-size-dropdown {
	position: absolute;
	right: 0px;
	top: calc(50% - 15px);
}

.font-config-toolbar select,
.h-size-dropdown select {
	background: #fff;
	border:solid 1px #efefef;
	outline: none;
	border-radius: 4px;
	margin-left: 5px;
	width: auto;
	height: 30px;
	font-size: 12px;
}

.h-size-dropdown select {
	margin-left: 0;
	background: #fff;
}

.nav-font-config-preview {
	width: 100%;
	height: 50px;
	border:solid 1px #efefef;
	box-shadow: 0 8px 16px rgba(0,0,0,0.1);
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 16px;
	font-weight: 600;
}

.nav-font-config-preview a {
	margin:0 15px;
}

.button-config-preview {
	width: 100%;
	position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    float: right;
    padding: 50px 0;
    background-image: url(/assets/img/theme-config/bg.png);
    background-repeat: repeat;
    background-size: 15px;
    box-shadow: inset 0 0 208px rgba(255,255,255,1);
}
.button-config-preview .live-preview-btn {
	font-size: 11px;
	padding:3px 6px;
	border-radius: 4px;
	text-transform: uppercase;
	display: flex;
	justify-content: center;
	background: linear-gradient(0deg, #3c4665 0%, #21283F 100%);
	color: #fff;
	position: absolute;
	top: 0px;
	left: 0px;
}


.button-config-preview .explore-btn {
	margin-top: 0;
}


.button-config {
	float: left;
	width: 100%;
}
.button-color-config {
	width: 50%;
	float: left;
}

.button-switch-config {
	float: right;
	width: 50%;
}
.button-config .button-shape-selector {
	margin-bottom: 20px;
}

.color-config {
	width: 100%;
}

.color-config hr {
	opacity: 0.2;
	margin:50px 0 30px 0;
}
.color-config label {
	font-weight: bold;
	font-size: 14px;
	width: 100%;
	margin-bottom: 8px;
	display: block;
}
.color-palette-config {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	margin-bottom: 20px;
	transition: 0.2s;
}
.color-palette-config div {
	width: 25%;
	position: relative;
	display: block;
	cursor: pointer;
}

.color-palette-config div:before {
	padding-bottom: 100%;
	width: 100%;
	content: " ";
	display: block;
}

.color-palette-config div span {
	position: absolute;
	font-size: 14px;
	width: 100%;
	bottom: 10px;
	text-align: center;
	color: #fff;
	font-weight: 600;
	text-transform: uppercase;
}
.color-palette-config.selected div:after {
	width: calc(100% - 20px);
	height: calc(100% - 20px);
	top: 10px;
	left: 10px;
	border:dashed 1px #fff;
	opacity: 0;
	border-radius: 6px;
	position: absolute;
	content: " ";
	font-size: 22px;
	color: #ccc;
	display: flex;
	justify-content: center;
	align-items: center;
}

.color-palette-config div:hover:after {
	opacity: 1;
}

.color-palette-config.selected div.empty:after {
	border-color: #ccc;
	opacity: 1;
	content: "+";
}

.color-palette-config div.empty:hover:after {
	border-color: #333;
	opacity: 1;
}


.color-palette-config:not(.selected):hover {
	box-shadow: 0 0 0 10px rgba(0,0,0,0.1);
}

/****SLIDE MODALS***/
.chrome-picker input,
.chrome-picker input:focus,
.slideout-content .chrome-picker input,
.slideout-content .chrome-picker input:focus {
	background: #fff!important;
}

.themes-color-select .switch {
	height: 16px;
}

.themes-color-select .switch input:checked + .switch-slider {
	background: #28B2E3;
}
.themes-color-select .switch  input:checked + .switch-slider:before {
	transform: translateX(21px);
}
.themes-color-select .switch .switch-slider:before {
	height: 20px;
    width: 20px;
    bottom: -2px;
    left: 0;
}

.select-font-dropdown-wrapper {
	width: 100%; 
	position: relative;
	cursor: pointer;
	z-index: 4;
	margin-top: 25px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 20px;
	flex-wrap: wrap;
}

.select-font-dropdown-wrapper .active-font {
	height: 40px;
	border-radius: 8px;
	background: #1d2131;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding:10px;
}

.select-font-dropdown-wrapper .active-font:after {
	width: 6px;
	height: 6px;
	border-right:solid 2px #fff;
	border-bottom: solid 2px #fff;
	transform: rotate(45deg);
	position: absolute;
	content: " ";
	right: 10px;
	top: 34px;
	opacity: 0.4;
}
.select-font-dropdown-wrapper.active {
	z-index: 5;
}
.select-font-dropdown-wrapper label {
	font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 4px;
    position: relative;
    width:100%;
    display:block;
}

.select-font-dropdown-wrapper.active .select-font-dropdown {
	display: block;
}

.select-font-dropdown {
	position: absolute;
    width: 100%;
    background: #fff;
    border-radius: 6px;
    top: 100%;
    max-height: 185px;
    overflow: auto;
    display: none;
}

.select-font-dropdown div {
	width: 100%;
	padding:10px;
	cursor: pointer;
	color: #333;
}
.select-font-dropdown div:hover {
	background: #fafafa;
}

.edit-body-mode .master-container {
	transform-origin: 50% 0%;
}
.edit-body-mode .master-container.theme-editing-active {
	transform-origin: 100% 0!important;
}

.nav-bar-fixed-top.slider-edit-mode .nav-bar:not(.nav-2),
.nav-bar-fixed-top .master-container.theme-editing-active .nav-bar:not(.nav-2) {
	position: relative!important;
	top: 0!important;
}

.slider-edit-mode .cai-bot-modal {
	transform: translateX(-20vw);
}
.slider-edit-mode .master-container {
	transform: scale(0.8);
    transform-origin: 0% 0%!important;
    box-shadow: 0 0 80px rgb(0 0 0 / 30%);
    overflow: hidden;
}
.slideout-content hr {
	border-color: rgba(0,0,0,0.4);
    margin: 30px 0;
}
.slideout-content .input-group.link-input input {
	padding-left: 45px;
}
.slideout-content .input-group.link-input:hover input {
	background: #1d2131!important;
}
.slideout-content .input-group.link-input .fa {
	background: #3C4367;
	width: 30px;
	height: 30px;
	font-size: 12px;
	border-radius: 6px;
}
.slideout-content .input-group.link-input .fa:after {
	display: none;
}
.slideout-wrapper {
	width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.4);
    position: fixed;
    left: 0px;
    top: 0px;
    z-index: 22222;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s ease;
    background: transparent;
}
.slideout-wrapper.active {
	visibility: visible;
    opacity: 1;
}
.slideout-content {
	position: fixed;
	bottom: 0;
	right: 0;
	height: calc(100vh - 50px);
	width: calc(20% - 0px);
	color: #fff;
	background: transparent;
	flex-direction: column;
	opacity: 0;
	animation: fadeInRight 0.3s forwards;
	animation-delay: 0.25s;
	display: none;
}

#menuLinksModal .slideout-content {
	animation-name: fadeIn;
}
.active .slideout-content {
	display: flex;
    background: #fff;
    opacity: 1;
    height: 100vh;
    width: 100%;
	max-width: 500px;
    margin: 0px;
    position: absolute;
    bottom: 0px;
}
.slideout-content .input-group textarea,
.slideout-content textarea {
	background:#1d2131!important;
	color: #fff;
	padding:10px!important;
}
.slideout-content input,
.slideout-content select,
.slideout-content .input-group select {
	width: 100%;
    height: 40px;
    border-radius: 8px;
    padding: 0 8px;
    color: #fff;
    font-weight: 100;
    font-size: 16px;
    font-family: Source Sans Pro;
    font-weight:normal;
}

.slideout-content .edit-font-size-set input {
	height: 100%;
	font-size: 13px;
}

.slideout-content .input-group .date-input img {
	filter: none;
    width: 15px;
    height: 15px;
    object-fit: contain;
    top: 13px;
}

.slideout-content textarea::placeholder,
.slideout-content input::placeholder {
	color: rgb(121 149 179 / 50%);
	font-size: 14px;
}

.slideout-content .slideout-content-scroll {
	flex:1;
	padding: 0px 20px;
	overflow: auto;
	position: relative;
}
.slideout-description,
.slideout-title {
	margin:0;
}

.slideout-title-set {
	margin-bottom: 20px;
}
.slideout-title-set h2,
.slideout-title-set p {
	margin:0;
}

.slideout-title-set h2,
.slideout-title {
	font-size: 21px;
}
.slideout-title-set p,
.slideout-description {
	font-size: 16px;
}
.carousel-slides-order-item.empty {
	border-color:rgb(121 149 179 / 100%);
	color: rgb(121 149 179 / 100%);
}
.slideout-actions {
	display: flex;
	justify-content: space-between;
	background: #303550;
	width: 100%;
    box-shadow: 0 -4px 20px rgba(0,0,0,0.03);
}

.slideout-actions button,
.slideout-actions a {
	padding:15px 20px;
	width: 50%;
	outline: none;
	border:none;
	background: #28B2E3;
	text-transform: uppercase;
	color: #fff;
	cursor: pointer;
}
.slideout-actions button:hover,
.slideout-actions a:hover {
	background: #1A8EB6;
}
.slideout-actions button.empty {
	background: #3C4367;
}
.slideout-actions button.empty:hover {
	background: #25293e;
}
.edit-in-themes {
	display: flex;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
	font-size: 14px;
}
.slideout-content .hero-app-buttons img {
	display: none;
}
.slideout-content .hero-app-buttons {
	margin-top: 0;
}


.slider-edit-mode .close-modal-hotspot {
	background: transparent;
	box-shadow: none;
}
@media (max-width: 1120px) {
 	.slider-edit-mode .master-container {
 		/*transform: scale(0.75) translateX(-20px) translateY(20px);*/
 		transform: scale(0.75)
 	}
 	.edit-body-mode  .slideout-content {
 		width: calc(25% - 0px);
 	}
 	.slider-edit-mode .cai-bot-modal {
		transform: translateX(-25vw);
	}
	.slider-edit-mode .modal.side#imagePickerModal .modal-content {
		width: 75vw;
	}
}

@media (max-width: 600px) {

	/* .slideout-content{
		width: 100%!important;
		margin: 0px!important;
		height: 100%!important;
		border-radius: 0px!important;

	} */

}
	