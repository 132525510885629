.swal-modal {
	width: 280px!important;
	height: auto!important;
	padding:5px;
	border-radius: 0 0 6px 6px;
	margin:0;
}

.swal-overlay {
	z-index: 99999;
	align-items: flex-start;
	justify-content: center;
	background: rgba(0,0,0,0.05);
	padding:0px;
}

.swal-icon {
	margin:0 auto!important;
	transform: scale(0.5);
}

.swal-title {
	font-size: 18px;
	font-weight: bold;
	padding-top: 0;
}
.swal-text {
	font-size: 14px;
	text-align: center;
}
.swal-footer {
	margin-top: 0;
}

.swal-button.swal-button--cancel {
	background: #fff;
    border-color: green;
    font-weight: 500;
    color: green;
    border: solid 1px green;
    margin-bottom: 5px;
}

.swal-button.swal-button--cancel:hover {
	background: #efefef!important;
}

.swal-overlay--show-modal .swal-modal {
	/*animation: swalAppear 0.5s forwards;*/
}

.swal-button.swal-button--confirm swal-button--danger {

	background: red;
}
.swal-overlay {
	align-items: center!important;
	background: rgba(0,0,0,0.25)!important;
}
.swal-overlay .swal-modal {
	border-radius: 10px;
	padding:0;
	box-shadow: 5px 10px 35px rgba(0,0,0,0.2);
	border:none;
}
.swal-modal .swal-icon {
	border-width: 6px;
	margin-bottom: -13px!important;
}
.swal-icon .swal-icon--warning__body {
	width: 6px;
}
.swal-icon .swal-icon--warning__dot {
	width: 8px;
	height: 8px;
}
.swal-overlay.swal-overlay--show-modal .swal-modal {
	animation: swalModalIn .3s forwards;
}

.swal-overlay.active .swal-modal {
    animation: swalModalIn .3s forwards;
}

.swal-overlay.active {
    opacity: 1;
    pointer-events: auto;
}
.swal-modal .swal-footer {
	border-top: solid 1px #efefef;
    padding: 10px;
    background: #fafafa;
	margin-top: 20px;
	text-align: center;

}

.swal-button {
    background-color: #fd6270;
    color: #fff;
    border: none;
    box-shadow: none;
    border-radius: 5px;
    font-weight: 600;
    font-size: 14px;
    margin: 0;
    cursor: pointer;
	width: 100%;
}

.swal-button-container{
	width: 100%;
	margin: 0px;
}

.swal-button:not([disabled]):hover{
	background-color: #e98e95;
}

.swal-button--danger{
	background-color: #e64942!important;
}

.swal-content__img{
	width: 200px;
    height: 200px;
    object-fit: cover;
    border-radius: 10px;
    border: solid 1px #00000019;
}

@keyframes swalAppear {
	from {opacity: 0; transform: translateY(-50px);}
	from {opacity: 1; transform: translateY(0px);}
}