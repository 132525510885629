.btn.insta-btn {
	background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
	color: #fff;
	border:none;
	margin-left: 10px;
}
.btn.insta-btn.dribbble-color {
	background: #ea4c89!important;
}

.btn.dribbble-btn {
	background: #ea4c89!important;
}

.btn img {
	width: 13px;
	height: 13px;
	object-fit: contain;
	margin-right: 5px;
}
.btn .next-icon {
	margin-right: 0;
	margin-left: 13px;
	margin-top: 2px;
	margin-right: 0!important;
	filter: invert(1);
}
.btn.white .next-icon {
	filter: none;
}
.btn.green {
	background: #2DD06E;
}
.btn.blank {
	background: #fff;
	border: solid 1px #efefef;
	box-shadow: 0 10px 18px rgba(0,0,0,0.04);
	color: #000;
	float: left;
}
.btn.small {
	padding:10px 20px;
	font-size: 13px;
	text-transform: uppercase;
	margin-bottom: 0;
}



.btn {
	padding:8px 15px;
	font-size: 14px;
	font-weight: 400;
	color: #fff;
	background: #21283F;
	border-radius: 20px;
	display: flex;
    justify-content: center;
    cursor: pointer;
    transition: all 0.1s;
    align-items: center;
    font-family: Source Sans Pro!important;
}

.btn.black {
	background: #333!important;
	display: block;
	text-align: center;
	border-radius: 40px;
}

.btn.dark {
	width: 100%;
    background: rgba(0,0,0,0.4);
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    height: 40px;
    transition:0.1s;
}

.btn .fa {
	margin-right: 7px;
}

.btn.dark:hover {
	background: rgba(0,0,0,0.8);
	opacity: 1!important;
}
.btn.black img {
	height: 55px;
	text-align: center;
	padding:10px 0px!important;
}
.btn.red {
	color: #fff;
	font-size: 12px;
	background: #c64040;
	border: none;
}

.btn.white {
	color: #333;
	background: #fff;
	border: 1px solid #efefef;
	box-shadow: 0 6px 12px rgba(0,0,0,0.1);
	margin: 0 3px;
}
.btn.disabled {
	background: #ccc;
	cursor: not-allowed;
}
.btn.white img {
	filter: none;
}


.button-box {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-evenly;
}
.btn .button-icon {
	height: 20px;
	margin-right: 0px;
}
.button-box .btn i {
	font-size: 15px;
}
.button-box .btn span {
	font-size: 12px;
	position: absolute;
	bottom: -30px;
	opacity: 0;
	transition:all 0.2s ease;
	text-transform: none;
	color: #000;
}

.button-box .btn:hover span {
	bottom: -20px;
	opacity: 1;
}

.btn.facebook, .btn.instagram, .btn.linkedin, .btn.google, .btn.shopify, .btn.youtube, .btn.click, .btn.powerpoint, .btn.amazon, .btn.dribbble{
	/*width: 50px;
	height: 50px;
	margin-bottom: 5px!important;
	margin-top: 5px!important;
	text-align: left;
	position: relative;
	border-radius: 30px;*/
}

.btn .socicon {
	margin-right: 5px;
	transform: translateY(1px);
	display: inline-block;
}

.btn.youtube {
	background: #cc3a31!important;
}
.btn.shopify{
	background: #98b554!important;
}
.btn.facebook{
	background: #3b5998!important;
}

.btn.instagram{
	background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%)!important; 
}

.btn.linkedin{
	background: #0077B5!important;
}

.btn.google{
	background: #d9534f!important;
}

.btn.click{
	background: #ea8e47!important;
}

.btn.powerpoint{
	background: #e64a19!important;
}

.btn.amazon{
	background: #231f20!important;
}

.btn.dribbble {
	background: #ea4c89!important;
}

.btn:hover {
	border-color:#333;
}
.btn:hover .arrow {
	width: 15px;
}
.btn.empty.no-border {
	border: none;
	padding-left: 0px;
	font-size: 14px;
}
.btn.empty.no-border .arrow {
	margin-left: 7px;
}
.btn.empty {
	background: #fff;
	color: #333;
	border: solid 1px #333;
}
.btn.empty.plus-icon {
	display: flex;

}
.btn.empty.plus-icon:before {
	width: 20px;
	height: 20px;
	content: "+";
	font-size: 18px;
	border-radius: 50%;
	background: #333;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 7px;
	color: #fff;
	font-weight: bold;
}

.btn.green {
	background: linear-gradient(-135deg, #81FBB8 0%, #28C76F 100%);
	border: none;
	color: #fff;
	padding:15px 20px;
	transition: all 0.2s;
}

.change-btn {
	 font-size: 13px;
    text-transform: uppercase;
    color: #fff;
    padding: 10px 20px;
    background: #21283F;
    border-radius: 20px;
    align-self: center;
    position: absolute;
    left: calc(50% - 60px);
    z-index: 2;
    top: calc(50% - 16px);
    cursor: pointer;
    opacity: 0;
    transition:0.2s all;
}

.change-image-btn {
	font-size: 13px;
    color: #fff;
    padding: 10px 20px;
    background: linear-gradient(-45deg, #3c4665 0%, #21283F 100%);
	box-shadow: 0 3px 6px rgba(0,0,0,0.3);
    border-radius: 6px;
    align-self: center;
    display: inline-block;
    margin:1px auto 1px auto;
}

