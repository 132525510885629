.slider-container {
  width: 100%;
  padding: 24px 0px;
  position: relative;
}

.slider {
  position: relative;
  width: 100%;
}

.thumb {
  -webkit-appearance: none;
  appearance: none;
  margin-top: 4px;
  height: 0;
  width: 100%;
  background: transparent;
  position: absolute;
  pointer-events: none;
}

.thumb::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  height: 24px;
  width: 24px;
  background-color: #26bfbf;
  border-radius: 50%;
  pointer-events: all;
  position: relative;
  z-index: 2;
}

.thumb::-moz-range-thumb {
  height: 24px;
  width: 24px;
  background-color: #26bfbf;
  border-radius: 50%;
  pointer-events: all;
  position: relative;
  z-index: 2;
}

.thumb--left {
  z-index: 3;
}

.thumb--right {
  z-index: 4;
}

.slider__track {
  position: absolute;
  height: 8px;
  background: rgba(38, 191, 191, 0.2);
  width: 100%;
  z-index: 1;
  border-radius: 4px;
}

.slider__range {
  position: absolute;
  height: 8px;
  background: #26bfbf;
  z-index: 2;
  border-radius: 4px;
}

.slider__values {
  position: absolute;
  top: -45px;
  width: 100%;
}

.slider__value {
  position: absolute;
  transform: translateX(-50%);
  background-color: #757575;
  color: white;
  padding: 5px;
  border-radius: 4px;
  font-size: 12px;
  min-width: 24px;
  text-align: center;
}

.slider__value::after {
  content: '';
  position: absolute;
  bottom: -10px; /* Adjust to position the arrow */
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: #757575 transparent transparent transparent;
}

.slider__labels {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  color: #757575;
  font-size: 14px;
}
