.landing-page-footer {
	width: 100%;
	max-width: 1000px;
	text-align: center;
	padding-top: 30px;
	border-top: solid 1px #efefef;
	margin:0 auto 100px auto;
}

.landing-page-footer-wrapper {
	background: #fafafa;
	padding:50px 50px;
	box-shadow: 0 4px 8px rgb(0 0 0 / 5%);
	margin-top: 70px;

}

.landing-footer {
	display: flex;
	max-width: 1000px;
	margin:0 auto;
	background: #fafafa;
}

.landing-footer .footer-section {
	width: 25%;
	display: flex;
	flex-direction: column;
	color: #000;
	font-weight: 200;
	white-space: pre-line;
}
.landing-footer .footer-section:not(.first) {
	padding-left: 60px;
}

.landing-footer .footer-section h4 {
	margin:0 0 10px 0;
	font-size: 16px;
}
.landing-footer .footer-section .click-logo img {
	width: 140px;
}
.footer-social .socicon {
	margin-right: 10px;
}

.landing-footer a {
	color: #000;
	font-weight: 200;
	margin-bottom: 5px;
}


@media (max-width: 779px) { 
	
	.landing-footer {
		flex-direction: column;
	}

	.landing-footer .footer-section {
		width: 100%;
		margin-bottom: 40px;
		padding-left: 0!important;
	}

}
