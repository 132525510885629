.inbox_chat_container{
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    padding: 20px;
    overflow: auto;
    height: 400px;
}

.inbox_navbar{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 50px;
    background-color: #ffffff;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    box-shadow: rgb(0 0 0 / 8%) 0px 1px 12px;
}

.admin_inbox_navbar{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 50px;
    background-color: #ffffff;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    box-shadow: rgb(0 0 0 / 8%) 0px 1px 12px;
}


.inbox_navbar img{
    width: 35px;
    height: 35px;
    border-radius: 50px;
    margin-right: 5px;
    margin-right: 5px;
    cursor: pointer;
    object-fit: cover;
}

.inbox_all_messages{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 50px;
    background-color: #ffffff;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    box-shadow: rgb(0 0 0 / 8%) 0px 1px 12px;
    margin-bottom: 10px;
    padding: 20px;
}

/* Input */
.inbox_input{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 50px;
    background-color: #ffffff;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    box-shadow: rgb(0 0 0 / 8%) 0px 1px 12px;
    position: absolute;
    bottom: 0px;
    left: 0px;
}

.inbox_input input{
    width: 76%;
    margin-left: 10px;
    height: 38px;
    padding-left: 10px;
    border-radius: 15px;
    outline: none;
    border: none;
}

.inbox_button{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    width: 40px;
    height: 40px;
    border-radius: 50px;
    background: #ccc;
    cursor: not-allowed;
}


/* Chat */
.chat_date {
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    color: #00000050;
    margin-bottom: 15px;
}

.chat_date_message{
    font-size: 12px;
    color: #00000090;
    margin-right: 10px;
    margin-left: 10px;
    margin-top: 3px;
}

.bubble_master {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: 8px;
}

.bubble_master_other {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 8px;
}

.bubble_user {
    padding: 8px 16px;
    border-radius: 20px;
    cursor: pointer;
    width: auto;
    max-width: 200px; 
    float: right; 
    background: #fd6270;
    color: #ffffff;
    font-size: 16px;
    white-space: pre-wrap
}

.bubble_other {
    padding: 8px 16px;
    border-radius: 20px;
    cursor: pointer;
    width: auto;
    max-width: 200px;
    float: left;
    background: #E4E6EB;
    color: #000000;
    font-size: 16px;
    white-space: pre-wrap
}

/* Conversations */
.conversation_container{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    background-color: #ffffff;
    border-bottom: solid 1px #f1f1f1;
    padding: 10px;
    width: 100%;
    cursor: pointer;
}

.conversation_container img{
    width: 50px;
    height: 50px;
    background-color: #ccc;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 15px;
    margin-top: 5px;
}

.conversation_placeholder{
    width: 50px;
    height: 50px;
    background-color: #ccc;
    border-radius: 50%;
    margin-right: 15px;
    margin-top: 5px;
}

.conversation_inner{
    display: flex;
    flex-direction: column;
    width: 78%;
}

.conversation_top {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

.conversation_top h4{
    margin: 0px;
    text-transform: capitalize;
    font-size: 16px;
}

.conversation_inner p{
    margin: 0px;
    margin-top: 3px;
    white-space: pre-line;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
}


@media (max-width: 660px){

    .inbox_chat_container{
        height: calc(100vh - 80px);
        padding-bottom: 100px;
        padding-top: 100px;
    }

    .inbox_input{
        height: 70px;
        z-index: 223;
    }

    .inbox_navbar{
        position: absolute;
        top: 0px;
        left: 0px;
        z-index: 222;
        height: 65px;
        padding: 10px;
    }
}