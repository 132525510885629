/* LISTING */
.list-products {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 20px;
}

.list-products li {
  display: flex;
  position: relative;
  transition: transform 0.2s ease-out;
  z-index: 1;
}

.list-products li:nth-last-child(5),
.list-products li:nth-last-child(6) {
  display: none;
}

.list-products li:not(:first-child) {
  margin-right: -28px;
}

.product-avatar {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  width: 80px;
  height: 80px;

  border-radius: 150px;
  background: var(--white);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
  overflow: hidden;
}

.product-avatar img {
  width: 100%;
  height: 100%;

  transform: (rotate(180deg));
  transform-origin: 0px 0px;

  border-radius: 100%;
}

.product-info {
  display: none;
  margin: 20px 0px;

  justify-content: space-between;

  font-family: 'Quicksand', sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;

  border: 1px black solid;
  border-radius: 60px;
}

.product-info .pointer,
.product-info h4 {
  display: flex;
  align-items: center;

  margin: 0px;
  padding: 0px;

  gap: 4px;
}

.product-info h4 {
  display: flex;
  justify-content: center;

  padding: 11px 5px 11px 11px;

  min-width: max-content;
  width: 100%;
}

.product-info .pointer {
  flex-shrink: 0;
  justify-content: end;
  padding: 11px 5px 11px 11px;

  width: 100px;

  color: var(--white);
  font-weight: bold;

  border-radius: 60px;
  box-shadow: 3px 0 0 1px rgba(252, 97, 112, 1);
  background: var(--color-ba);

  transition: transform 0.3s ease-out;
}

.product-info .pointer:hover {
  transform: translateX(10px);
}

.list-grid {
  display: grid;
  grid-template-columns: repeat(2, minmax(130px, 1fr));
  gap: 0.5rem;
}

.listing-item {
  width: 100%;
  max-width: 340px;
}

.category-item {
  flex: 0 0 auto;
  min-width: max-content;
  width: 18.735%;
}

@media (min-width: 768px) {
  .list-product-container h3 {
    text-align: center;
  }

  .product-info {
    display: flex;
  }

  .list-products li:nth-last-child(5),
  .list-products li:nth-last-child(6) {
    display: flex;
  }

  .list-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
}
