@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700');

::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
}

* {
	box-sizing: border-box;
}

html, body {
	padding:0px;
	margin:0px;
}
body {
	font-family: Source Sans Pro;
	background: #fff;
	user-select: none;
}

select,   
textarea,   
input[type="text"],   
input[type="password"], 
input[type="datetime"], 
input[type="datetime-local"], 
input[type="date"], 
input[type="month"], 
input[type="time"], 
input[type="week"], 
input[type="number"], 
input[type="email"], 
input[type="url"], 
input[type="search"], 
input[type="tel"], 
input[type="color"] { 
	font-size: 16px; 
}

a {
	text-decoration: none;
	color: #000;
}

#dashboard_master_container {
	background: #f0f5f9;
	box-shadow:0 0px 0px 1000px #f0f5f9;
}

.product-search-input {
	width: 100%;
	position: relative;
	height: 50px;
	margin:20px 0;
}

.product-search-input button {
	height: calc(100% - 10px);
	padding:10px 20px;
	border-radius: 6px;
	background: linear-gradient(-135deg, #28C76F 0%, #28C76F 100%);
	outline: none;
	border:none;
	position: absolute;
	top: 5px;
	right: 5px;
	color: white;
}

.product-search-input .fa {
	position: absolute;
	left: 15px;
	top: 19px;
}
.edit-product-card .megadraft-editor .toolbar__input {
	color: #000;
}
.product-search-input input {
	border-radius: 6px;
	background: #f1f1f1;
	border:solid 1px #efefef;
	width: 100%;
	height: 100%;
	padding-left: 40px;
	padding-right: 90px;
	outline: none;
	font-size: 15px;
	transition:0.1s;
}

.product-search-input input:focus {
	box-shadow: rgba(0, 123, 255, 0.5) 0px 0px 0px 1px, rgba(0, 123, 255, 0.25) 0px 0px 0px 0.2rem;
    background: #fff!important;
}

.side-nav {
	width: 200px;
	height: 100vh;
	position: fixed;
	left: 0;
	top: 0;
	z-index: 400;
	padding:10px;
	background:#fff;
	transition: 0.3s all cubic-bezier(0.165, 0.84, 0.44, 1);
	display: flex;
	flex-direction: column;
	justify-content: center;
	overflow: hidden;
}

.website-sidebar-actions {
	width: 178px;
	position: absolute;
	top: 78px;
	display: flex;
	flex-shrink: 0;
	flex-wrap: nowrap;
	justify-content: space-between;
	padding-left: 12px;
	transition: 0.2s;
}


.website-sidebar-actions a {
	width: 38px;
    height: 38px;
    border-radius: 6px;
    color: #fff;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    transition: 0.2s;
    border: solid 1px rgba(255,255,255,0.1);
    cursor: pointer;
    letter-spacing: -0.2px;
    flex-shrink: 0;
}

.side-nav .website-sidebar-actions a.website-edit-action {
	white-space: nowrap;
	overflow: hidden;
	width: 120px;
	box-shadow: inset 0 -2px 0 rgba(0,0,0,0.2);
}

.side-nav .website-sidebar-actions a.website-edit-action img {
	margin-right: 10px;
}

.side-nav .website-sidebar-actions a.website-edit-action:hover {
	background: linear-gradient(-135deg, #28C76F 0%, #28C76F 100%);
}

.website-sidebar-actions a:first-child {
	background: linear-gradient(-135deg, #81FBB8 0%, #28C76F 100%);
	margin-right: 30px;
	border:none;
}

.website-sidebar-actions a img {
	filter: invert(1);
	width: 16px;
	height: 16px;
	object-fit: contain;
}

.website-sidebar-actions a span {
	font-size: 11px;
    width: 100%;
    text-align: center;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    bottom: -18px;
    left: 0;
    line-height: 12px;
    white-space: nowrap;
    transition: 0.2s;
}
.website-sidebar-actions a:hover span {
	opacity: 1;
	visibility: visible;
}
.side-nav .nav-links a {
	width: 100%;
	color: #000;
	/* color: transparent; */
	font-weight: normal;
	font-size: 15px;
	width: 100%;
	padding:0;
	height: 30px;
	float: left;
	margin:8px 0;
	white-space: nowrap;
	text-transform: none;
	transition: all 0.3s;
	position: relative;
}

.side-nav .nav-links .nav-separator {
	display: flex;
    width: 100%;
    color: rgba(0,0,0,0.3);
    font-size: 11px;
    text-transform: uppercase;
    white-space: nowrap;
    font-weight: bold;
    height: 1px;
    transform: translateY(-7px);
    padding-left: 14px;
    transition: 0.2s;
    opacity: 0;
    display: inline-block;
    margin:8px 0;
}

.side-nav .nav-links .nav-separator:after {
	height: 1px;
    width: 100%;
    content: " ";
    background: rgba(0,0,0,0.3);
    transform: translateY(7px);
    margin-left: 5px;
}

.side-nav .nav-links a span {
	display: inline-block;
	width: 17px;
	height: 17px;
	object-fit: contain;
	margin-right: 15px;
	opacity: 0.8;
	margin-left: 22px;
	transition: all 0.1s;
}


.side-nav .nav-links a span {
	color: #fd6270;
}

.side-nav .logo {
	width:140px;
	transition: 0.3s all cubic-bezier(0.165, 0.84, 0.44, 1);
	position: absolute;
	top: 20px;
	left: 18px;
}

#dashboard_master_container:before {
	z-index: 3;
	width: 30px;
	height: 30px;
	content: " ";
	position: fixed;
	top: 65px;
	left: 80px;
	background-size:100%;
}

.master_container {
	width: calc(100vw - 200px);
	height: auto;
	background: #fff;
	position: relative;
	padding:85px 40px;
	float: right;
}
.header {
	height: 65px;
	width: calc(100vw - 200px);
	position: fixed;
	top: 0;
	right: 0;
	z-index: 40;
	padding:0 0px 0 0px;
	background: #fd6270;
	display: flex;
	justify-content: space-between;
	color: #fff;
}

.header h3 {
	margin:0;
	display: flex;
	justify-content: center;
	align-items: center;
	align-content: center;
	font-size: 18px;
	margin-left: 20px;
}
.header h3 img {
	height: 70px;
	width: 70px;
	border-radius: 50%;
	object-fit: cover;
	border:solid 1px #efefef;
	margin-right: 20px;
}
.header .right,
.header .left {
	display: flex;
	flex-direction: row;
} 

.header .left {
	justify-content: center;
	height: 100%;
	align-items: center;
}

.header .left a img {
	width: 15px;
	margin-right: 20px;
	margin-top: 7px;
	height: auto;
}

.header .left .notifications-dropdown img  {
	margin:0;
	transform: translateY(3px);
	filter: invert(1);
}

.header .left .notifications-dropdown:after {
	width: 6px;
	height: 6px;
	content: " ";
	position: absolute;
	right: 11px;
	top: calc(50% - 3px);
	border-right: solid 2px #ccc;
	border-top: solid 2px #ccc;
	transform: rotate(45deg);
}
.header .left .notifications-dropdown:before {
	display: none;
}

.header .left .notifications-dropdown  {
	border:none;
}
.side-nav:hover .website-switch {
	width: 180px;
    border: solid 1px rgba(255,255,255,0.1);
}
.website-switch-trigger {
	width: 100%;
	height: 100%;
	position: absolute;
	background:  #222941;
	display: flex;
	justify-content: center;
	align-items: center;
	opacity: 0;
	transition: 0.2s;
	z-index: 2;
	left: 0;
	cursor: pointer;
}

.website-switch-trigger:hover {
	opacity: 1;
}

.website-switch-trigger img {
	height: 15px;
	margin-right: 10px;
}
.website-switch {
	width: 180px;
	display: flex;
	flex-wrap: nowrap;
	height: 60px;
	position: absolute;
	top: 10px;
	left: 10px;
	padding:8px;
	align-items: center;
	color: #fff;
	transition: 0.4s;
	font-size: 15px;
	overflow: hidden;
	border: solid 1px rgba(255,255,255,0);
	border-radius: 8px;
}
.website-switch:after {
	position: absolute;
    right: -6px;
    top: 17px;
    content: "...";
    transform: rotate(90deg);
    font-size: 20px;
    opacity: 0.4;
}
.website-switch span {
	opacity: 0;
	transition: 0.3s;
}
.side-nav:hover .website-switch span {
	opacity: 1;
}
.website-switch .switch-logo {
	width: 45px;
	height: 45px;
	border-radius: 8px;
	object-fit: contain;
	margin-right: 10px;
}
.profile-dropdown {
	flex-shrink: 0;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	font-size: 14px;
	position: relative;
	padding:23px 19px;
	margin-right: 8px;
}
.profile-dropdown .bar-dropdown-content {
	flex-direction: column;
	left: auto;
	width: 200px;
	right: 0;
}
.profile-dropdown .bar-dropdown-content:before {
	left: auto;
	right: 23px;
}
.profile-dropdown:hover .bar-dropdown-content {
	display: flex;
}

.profile-dropdown .bar-dropdown-content h3 {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}
.profile-dropdown .bar-dropdown-content img {
	filter: none;
}
.profile-dropdown.bar-dropdown-content span {
	display: block;
}
.profile-dropdown.languages {
	padding:0 10px;
}
.profile-dropdown.languages img {
	width: 20px;
	height: 20px;
	transform: translateY(-2px);
	margin-right: 0;
}

.profile-dropdown.languages .profile-dropdown-content {
	width: 150px;
}
.profile-dropdown.languages .profile-dropdown-content img {
	transform: none;
	border-radius: 50%;
	position: relative;
	left: auto;
	margin-right: 10px;
}
.profile-dropdown.languages img {
	position: relative;
	left: auto;
}
.profile-dropdown.languages .profile-dropdown-content a {
	padding:10px 20px;
}
.profile-dropdown img {
	width: 17px;
	height: 17px;
	object-fit: contain;
	filter: invert(1);
	transform: translateY(-2px);
	opacity: 0.6;
}

.profile-dropdown:hover img {
	opacity: 1;
}

.profile-dropdown .arrow-down {
	border-bottom: solid 2px #fff;
	border-right: solid 2px #fff;
	transform: rotate(45deg);
	margin-left: 10px;
	width: 6px;
	height: 6px;
}

.profile-dropdown:hover .profile-dropdown-content {
	opacity: 1;
	visibility: visible;
	transform: none;
}

.profile-dropdown-content {
	position: absolute;
	width: 180px;
	display: flex;
	flex-direction: column;
	background: #fff;
	box-shadow: 0 8px 16px rgba(0,0,0,0.15);
	top: 80%;
	right: 0px;
	opacity: 0;
	visibility: hidden;
	transform: translateY(-10px);
	transition: all 0.2s;
	border-radius: 6px;
	border:4px solid transparent;
}

.profile-dropdown-content:before {
	content: " ";
	width: 8px;
	height: 8px;
	position: absolute;
	background: #fff;
	right: 20px;
	top: -8px;
	transform: rotate(45deg);
}

.profile-dropdown-content img {
	filter: none;
}

.profile-dropdown-content a {
	width: 100%;
	padding:10px 15px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.profile-dropdown-content a img {
	height: 15px;
	margin-right: 8px;
	object-fit: contain;
	border-radius: 0;
	box-shadow: none;
	width: 15px;
}


.profile-dropdown-content a:hover {
	background: rgba(0,0,0,0.02);
}

.notifications-dropdown {
	border-left: solid 1px #efefef;
	padding:23px 30px;
	position: relative;
}

.right .notifications-dropdown {
	padding:23px 19px;
	border:none;
}
.right .notifications-dropdown img {
	filter: invert(1);
}
.notifications-dropdown:before {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 0;
	background: linear-gradient(-128deg, #455173 0%, #21283F 100%);
	content: " ";
	transition: all 0.2s;
}

.notifications-dropdown strong {
	width: 1px;
    position: absolute;
    text-align: center;
    left: 50%;
    bottom: 10px;
    font-size: 12px;
    font-weight: normal;
    text-transform: uppercase;
    opacity: 0;
    transform: translateY(5px);
    transition: all 0.2s;
    color: #fff;
    white-space: nowrap;
    display: flex;
    justify-content: center;
}

.notifications-dropdown strong span {
	padding:3px 8px;
	background: #000;
	border-radius: 5px;
	position: relative;
	margin-top: 4px;
}

.notifications-dropdown strong span:before {
	content: " ";
	width: 6px;
	height: 6px;
	background: inherit;
	position: absolute;
	left: calc(50% - 3px);
	top: -3px;
	transform:rotate(45deg);
}

.notifications-dropdown:hover strong {
	transform: translateY(16px);
	opacity: 1;
}

.notifications-dropdown img {
	width: 17px;
	height: 17px;
	object-fit: contain;
	opacity: 0.6;
	transition: all 0.2s;
}
.notifications-dropdown:hover img {
	transform: scale(1.15);
	opacity: 1;
}
.notifications-dropdown .messages {
	padding:1px 6px;
	z-index: 2;
	border-radius: 20px;
	color:#fff;
	position: absolute;
	border:solid 2px #fff;
	top: 18px;
	right: 10px;
	font-size: 12px;
	background: linear-gradient(-135deg, #81FBB8 0%, #28C76F 100%);
}

.section-title {
	width: 100%;
	font-size: 13px;
	text-transform: uppercase;
	font-weight: 400;
	opacity: 0.5;
	margin:30px 0 0 0px;
	background: transparent!important;
}
.dashboard-container {
	max-width: 1000px;
	flex-wrap: wrap;
	margin:0 auto;
}
/***SWITCH***/
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.switch-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
}

.switch-slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
}

input:checked + .switch-slider {
  background-color: #5FBF80;
}

input:focus + .switch-slider {
  box-shadow: 0 0 1px #5FBF80;
}

input:checked + .switch-slider:before {
  transform: translateX(16px);
}

/* Rounded sliders */
.switch-slider.round {
  border-radius: 34px;
}

.switch-slider.round:before {
  border-radius: 50%;
}


.create-btn {
	padding:12px 20px;
	position: relative;
	background-image: linear-gradient(-135deg, #81FBB8 0%, #28C76F 100%);
	box-shadow: 0 6px 18px 0 rgba(0,0,0,0.11);
	border-radius: 32.5px;
	color: #fff;
	border-radius: 30px;
	font-size: 14px;
	cursor: pointer;
	text-transform: uppercase;
	display: inline-block;
}

.create-btn:hover {
	background: #28C76F;
}

#createModal {
	justify-content: flex-end;
}
#createModal .modal-content {
	height: calc(100vh - 50px);
	align-items: center;
	width: calc(100vw - 300px);
	padding-bottom: 0;
	max-width: initial;
}
#createModal .modal-content .preview-screen {
	width: 700px;
	object-fit: contain;
	margin-bottom: 0;
	position: absolute;
	left: calc(50% - 350px);
	bottom: 0;
}
#createModal .modal-title {
	width: 100%;
	text-align: center;
}
#createModal .modal-title h2 {
	width: 100%;
	font-size: 30px;
	margin-bottom: 0;
	font-weight: 100;
}
#createModal .modal-title .subtitle {
	font-size: 16px;
	opacity: 0.5;
	font-weight: 200;
}

.section-title:before {
	display: none;
}



/*.input-field.expandable-field input {
	width: 50px;
	height: 50px;
	border-radius: 50%;
	padding: 0;
    text-indent: 50px;
}

.input-field.expandable-field .socicon {
	left: 18px;
}

.input-field.expandable-field input:valid,
.input-field.expandable-field input:focus {
	width: 100%;
	border-radius: 6px;
}*/

.two-columns {
	display: flex;
	justify-content: space-between;


}

.two-columns .form {
	width: calc(50% - 20px);

}

.tab-title {
	margin:0 0 20px 0;
	line-height: 20px;
	font-size:20px;
}
.tab-title span {
	font-size: 14px;
	font-weight: 400;
	opacity: 0.5;
}
.save-btn {
	background: linear-gradient(-135deg, #81FBB8 0%, #28C76F 100%);
    padding: 10px 20px;
    text-transform: none;
    border-radius: 6px;
    font-size: 16px;
    color: #fff;
    font-weight: 400;
    cursor: pointer;
    transition: all 0.3s;
    margin-top: 15px;
    display: inline-block;
    box-shadow: inset 0 -2px 0 rgba(0,0,0,0.2);
}

.save-btn:hover {
	background: linear-gradient(-135deg, #28C76F 0%, #28C76F 100%);
}

.browser-tab {
	width: 100%;
	height: 100px;
	background: url(/assets/img/dashboard/tab.jpg);
	display: block;
	position: relative;
	background-size: 300px;
    background-repeat: no-repeat;
}
.browser-tab img {
	width: 30px;
	height: 30px;
	border-radius: 5px;
	object-fit: contain;
	    position: absolute;
    left: 107px;
    top: 29px;
}


.share-preview {
	border:solid 1px #D8D8D8;
	padding:10px 10px 20px 10px;
	overflow-y: hidden;
	position: relative;
	width: 100%;
	max-width: 350px;
	border-radius: 10px;
}

.share-preview img {
	width: 100%;
	object-fit: cover;
	border-radius: 10px;
	max-height: 240px;
	height: auto;
}

.share-preview .text-placeholder {
	width: 80%;
	height: 10px;
	background: #D8D8D8;
	margin:10px 0 0 20px;
}
.share-preview .text-placeholder:after {
	width: 50%;
	background: #D8D8D8;
	height: 10px;
	content: " ";
	display: block;
	margin-top:17px;
	float: left;
}
.share-preview:hover .change-btn, .browser-tab:hover .change-btn {
	opacity: 1;
}

.burger {
	display: none;
}
.publish-progress,
.publish-success {
  width: 100%;
}

.success-icon {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border:solid 2px #5FBF80;
  position: relative;
  margin:50px auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.success-icon:before {
  width: 30px;
  height: 15px;
  border-bottom: solid 2px #5FBF80;
  border-left:solid 2px #5FBF80;
  transform: rotate(315deg);
  margin-top: -10px;
  content: " ";

}

#inboxContainer {
	background: #fafafa;
}

@keyframes inboxIn {
	from {opacity: 0; transform:translateY(20px);}
	to {opacity: 1; transform:translateY(0px);}
}




.cancel-btn {
	padding: 10px 20px;
	margin-top: 15px;
	font-size:14px;
    border-radius: 20px;
    color: #ccc;
    text-transform: uppercase;
    float: left;
}
.cancel-btn:hover {
	color: #000;
}

.upgrade-btn {
	background-image: linear-gradient(-135deg, #81FBB8 0%, #28C76F 100%);
    box-shadow: 0 6px 18px 0 rgba(0,0,0,0.11);
    padding: 10px 20px;
    border-radius: 40px;
    font-size: 14px;
    color: #fff;
    text-transform: uppercase;
    font-weight: 400;
    cursor: pointer;
    transition: all 0.3s;
    display: inline-block;
}

.upgrade-btn.blue {
	background-image: linear-gradient(-45deg, #455173 0%, #21283F 100%);
}

.dashboard-home-container {
	max-width: 900px;
	width: 100%;
	position: relative;
	margin:30px auto;
	z-index: 4;
}

.dashboard-home-container .phone-screen-startup {
	z-index: 2;
	position: relative;
	margin-top: 30px;
	left: 0;
	margin-left: 15px;
	width: 250px;
	min-height: 420px;
	background: #333;
	height: 544px;
	flex-shrink: 0;
	overflow: visible;
	box-shadow: 0px 0px 0px 5px #1f1f1f, 0px 0px 0px 8px #191919, 0px 0px 0px 13px #111, 0 40px 46px #738796;
}
.dashboard-home-container .phone-screen-startup iframe {
	width: 414px;
    height: 900px;
    border-radius: 20px;
    transform: scale(0.6);
    transform-origin: 0 0;
	border:none;
}
.dashboard-home-container .phone-screen-startup:before {
	z-index: 2;
	height: 70px;
	width: 5px;
	border-radius: 0px 2px 2px 0;
	top: 50px;
	left: auto;
	right: -19px;
}
.dashboard-home-container .phone-screen-startup:after {
	z-index: 2;
	height: 50px;
	width: 5px;
	border-radius: 0px 2px 2px 0;
	top: 120px;
	left: auto;
	right: -19px;
}
.dashboard-home-container .phone-screen-startup img {
	width: 100%;
}

.dashboard-home-content {
	display: flex;
	margin-top: 60px;
}

.dashboard-home-content .right {
	width: calc(100% - 330px);
	flex-shrink: 0;
	margin-left: 60px;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: flex-start;
	align-content: flex-start;
}

.dashboard-home-content .right h3 {
	font-size: 17px;
    font-weight: 600;
    margin: 0px 0 20px 0;
    width: 100%;
    text-transform: uppercase;
    position: relative;
}

.dashboard-publish-card {
	width: 100%;
    margin-bottom: 20px;
    background: #e6ecf1;
    color: #383131;
    display: flex;
    justify-content: space-between;
    padding: 5px 10px 5px 20px;
    align-items: center;
    position: relative;
    border-radius: 8px;
    font-size: 15px;
}
.dashboard-publish-card .fa {
	margin-right: 15px;
	color: #00f2fe;
    font-size: 20px;

}
.dashboard-publish-card button {
	outline: none;
	border:none;
	background: #262E47;
    padding: 10px 15px;
    border-radius: 4px;
    color: #fff;
    margin: 5px;
    display: flex;
    align-items: center;
    font-size: 15px;
    cursor: pointer;
    margin-left: 20px;
}

.dashboard-publish-card button:hover {
	background: linear-gradient(-135deg, #28C76F 0%, #28C76F 100%);
}
.q-a-card .socicon {
	font-size: 25px;
	margin-right: 15px;
}
.q-a-card {
	width: calc(50% - 10px);
	border-radius: 8px;
	background: #fff;
	box-shadow: 0 8px 16px rgba(115,135,150,0.1);
	height: 100px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-bottom: 20px;
	padding:20px;
	transition: 0.2s;
	cursor: pointer;
}
.q-a-card.upgrade {
	color: #fff;
	background: #262E47;
}
.q-a-card.instagram {
	color: #fff;
	background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
}
.q-a-card.facebook {
	color: #fff;
	background: #4267b2;
}
.q-a-card.dribbble {
	color: #fff;
	background: #ea4c89;
}
.q-a-card.unsplash {
	color: #fff;
	background: #000;
}
.q-a-card.twitter {
	color: #fff;
	background: #0084b4;
}
.q-a-card.pinterest {
	color: #fff;
	background: #c8232c;
}
.q-a-card.linkedin {
	color: #fff;
	background: #0077B5;
}
.q-a-card.youtube {
	color: #fff;
	background: #c4302b;
}


.q-a-card.upgrade img,
.q-a-card.instagram img {
	filter: invert(1)!important;
}
.q-a-card:hover {
	box-shadow: 0 8px 16px rgba(115,135,150,0.3);
}
.q-a-card h4 {
	margin:0;
	font-size: 18px;
	font-weight: 600;
}

.q-a-card p {
	margin:0;
	font-size: 14px;
	opacity: 0.6;
}
.q-a-card img {
	    width: 25px;
    height: 25px;
    object-fit: contain;
    margin-right: 20px;
    margin-left: 9px;
    flex-shrink: 0;
    filter: none;
    transition: 0.2s;
}
.q-a-card:hover img {
	filter: invert(42%) sepia(86%) saturate(2132%) hue-rotate(164deg) brightness(98%) contrast(104%);
	transform: scale(1.1);
}
.dashboard-home-bg:before,
.dashboard-home-bg:after {
	position: absolute;
    bottom: -50px;
    width: 25px;
    height: 50px;
    background: #262E47;
    left: 0px;
    content: " ";
    z-index: 2;
}

.dashboard-home-bg:before {
	z-index: 3;
    background: #f0f5f9;
    border-radius: 25px 0 0 0;
}
.dashboard-home-left {
	width: calc(100% - 200px);
}




.card-dropdown {
	position: absolute;
	right: 10px;
	top: 10px;
	z-index: 200;
}
.spin-loader-container {
	width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: 20px;
    min-height: 300px;
    min-width: 300px;
    margin:auto;
}
.card-dropdown-trigger {
	width: 30px;
	height: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 6px;
	color: #333;
	background: #fff;
	font-size: 30px;
	transition: 0.2s;
	border:solid 1px #efefef;
	box-shadow: 0 2px 3px rgba(0,0,0,0.1);
	z-index: 10;
}

.card-dropdown:hover .card-dropdown-trigger {
	box-shadow: 0 0 0 rgba(0,0,0,0);
}
.card-dropdown-trigger:before {
	width: 100%;
	height: 100%;
	content: "...";
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    line-height: 0;
    padding-bottom: 8px;
    padding-left: 1px;
}
@keyframes floaterIn {
	0% {transform: scale(0.5);}
	70% {transform: scale(1.2);}
	100% {transform: scale(1);}
}

.card-dropdown-content {
	position: absolute;
	right: 0;
	top: 100%;
	opacity: 0;
	visibility: hidden;
	display: flex;
	flex-direction: column;
	width: 150px;
	transition: 0.3s;
	transform: translateY(10px);
	background: #fff;
    border-radius: 6px;
    box-shadow: 0 8px 16px rgba(0,0,0,0.1);
    border: solid 1px #efefef;
}
.card-dropdown-content .btn {
	padding: 5px 10px;
	box-shadow: 0px 6px 12px rgba(0,0,0,0.1);
	margin:5px;
	margin:10px;
	position: relative;
	z-index: 2;
	font-size: 14px;
}
.card-dropdown-content a {
	width: 100%;
	padding:4px 14px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	font-size: 14px;
	cursor: pointer;
	color: #333!important;
}
.card-dropdown-content a:hover {
	background: #fafafa;
}
.card-dropdown-content a img {
	width: 10px;
	height: 10px;
	object-fit: contain;
	margin-right: 4px;
}


.card-dropdown:hover .card-dropdown-content {
	opacity: 1;
	visibility: visible;
	transform: translateY(0px);
}

.card-dropdown:hover {
	z-index: 50;
}

.account-circles {
	display: flex;
	flex-wrap: wrap;
}
.social-accounts-circle {
	width: 80px;
	height: 80px;
	border-radius: 50%;
	position: relative;
	background:#efefef;
	margin-right: 20px;
	margin-bottom: 20px;
	transition: all 0.2s;
	cursor: pointer;
}
.social-accounts-circle:not(.active) {
	filter: grayscale(1);
	transform: scale(0.92);
}
.social-accounts-circle:not(.active):hover {
	transform: none;
	filter: none;
	background: #fff;
	box-shadow: 0 8px 16px rgba(0,0,0,0.1);
}

.social-accounts-circle img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	position: relative;
	z-index: 2;
	border-radius: 50%;

}
.social-accounts-circle:before {
	content: "+";
	font-size: 40px;
	color: #ccc;
	position: absolute;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	transition: all 0.6s;
	align-items: center;
}

.social-accounts-circle:hover:before {
	transform: rotate(90deg)
}
.social-accounts-circle .socicon {
	width: 30px;
	transition: all 0.2s;
	height: 30px;
	border-radius: 50%;
	position: absolute;
	color: #fff;
	font-size:14px;
	display: flex;
	justify-content: center;
	align-items: center;
	right: -10px;
	bottom: 0;
	z-index: 4;
}
.connected-accounts-dashboard h2,
.dashboard-section-title h2 {
	font-size: 27px;
	margin:0;
}
.connected-accounts-dashboard p,
 .dashboard-section-title p {
	font-size: 16px;
	font-weight: 400;
	opacity: 0.5;
	margin:0 0 20px 0;
}
.connected-accounts-dashboard  {
	width: 100%;
	margin: 100px 0 0 0;
}


.dashboard-title {
	font-size: 15px;
	margin:10px 0;
	font-weight: bold;
	text-transform: uppercase;
}

.dashboard-section-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.flex-dashboard {
	display: flex;
	justify-content: space-between;
	width: 100%;
}

.dashboard-orders {
	width: calc(50% - 40px);
}

.dashboard-orders-item {
	width: 100%;
	margin-bottom: 10px;
	box-shadow: 0 8px 27px 0 rgba(0,0,0,0.08);
	border-radius: 8px;
	display: flex;
	justify-content: space-between;
	font-size: 14px;
	align-items: center;
	align-content: center;
	padding:20px;
	height: 90px;
	/* border:solid 1px #efefef;*/
	position: relative;
	transition:0.2s all;
	cursor: pointer;
	color: #000;
	background: #fff;
}

.dashboard-orders-item:hover {
	box-shadow: 0 8px 27px 0 rgba(0,0,0,0.14);
	transform: scale(1.03,1.03)
}
.dashboard-orders-item strong {
	font-size: 16px;
}
.dashboard-orders-item-product {
	padding-left: 80px;
}
.dashboard-orders-item-product .socicon {
	width: 30px;
	height: 30px;
	border-radius: 50%;
	position: absolute;
	left: 5px;
	bottom:5px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #fff;
}
.dashboard-orders-item-product .tag {
	position: absolute;
    right: 10px;
    top: 10px;
    font-size: 13px;
    background: #28C76F;
    color: #fff;
    padding: 3px 6px;
    border-radius: 20px;
}
.dashboard-orders-item-product img {
	width: 70px;
	height: 70px;
	object-fit: cover;
	float: left;
	margin-right: 10px;
	position: absolute;
	left: 10px;
	top: 10px;
}
.dashboard-orders-item-product span {
	opacity: 0.5;
	width: 214px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
}
.dashboard-orders-item-customer {
	margin-right: 20px;
	border-left: solid 1px #efefef;
	padding-left: 20px;
	width: 30%;
}

.dashboard-orders-item-customer span {
	font-size: 11px;
	text-transform: uppercase;
}
.dashboard-orders-item-customer span.pending {
	color: #CF802A;
}

.dashboard-orders-item-customer span.complete {
	color: #71D960;
}

.dashboard-inbox {
	width: calc(50% - 40px);
}
.inbox-item-sender {
	width: 250px;
	margin-right: 0px;
}
.inbox-item-sender span {
	opacity: 0.5;
	font-size: 12px;
}




.profile-setup-container {
	margin:0 auto;
	width: 100%;
	max-width: 800px;
}
.profile-setup-image {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin: 30px 0;
}
.profile-setup-image img {
	width: 110px;
	height: 110px;
	border-radius: 50%;
	margin-right: 20px;
	box-shadow: 0 8px 16px rgba(0,0,0,0.1);
}
.profile-setup-image .btn {
	font-size: 14px;
	padding:10px 20px;
}
.profile-setup-form {
	width: 100%;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
}

.profile-setup-form h2 {
	width: 100%;
	font-weight: 200;
	margin:40px 0 0px 0;
}

.profile-setup-form .input-group {
	width: calc(50% - 20px);
}

.profile-setup-form .input-group.with-icon {
	width: calc(33.333% - 20px);
}


.social-media-connect {
	width: 100%;
	margin:20px auto 0px auto;
	opacity: 0;
    animation: stepsContent 0.7s forwards;
    animation-delay: 0s;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.social-media-connect div {
	border-radius: 30px;
	color: #fff;
	height: 50px;
	display: flex;
	justify-content: flex-start;
	font-weight: 400;
	align-items: center;
	margin-bottom: 10px;
	box-shadow: 0 8px 16px rgba(0,0,0,0.05);
	cursor: pointer;
	transition: all 0.2s;
	width: calc(33.33% - 20px);
	text-align: center;
}

.social-media-connect.connected div {
	color: #000;
	background: transparent;
	border:solid 1px #efefef;
	position: relative;
}

.social-media-connect.connected div .socicon {
	margin-right: 10px;
	margin-left: 20px;
}

.social-media-connect.connected div img.delete {
	width: 13px;
	height: 13px;
	position: absolute;
	left: auto;
	opacity: 1;
	right: 20px;
	top: 17px;
	opacity: 0;
}

.social-media-connect.connected div:hover img.delete {
	opacity: 1;
}

.social-media-connect div:hover {
	transform: scale(1.02,1.02);
	box-shadow: 0 8px 16px rgba(0,0,0,0.15);
}

.social-media-connect div .socicon {
	margin-right: 20px;
	margin-left: 40px;
}

.dashboard-tabs.audience-tabs {
	padding-left:80px;
}
.dashboard-tabs.audience-tabs div:not(.website-select-dropdown) {
	padding:0 30px;
	font-size: 15px;
}

.dashboard-tabs {
	width:100%;
	position: fixed;
	left: 0;
	top: 64px;
	display: flex;
	justify-content: center;
	height: 60px;
	font-size: 16px;
	background: #262E47;
	font-weight: 500;
	z-index: 2;
	padding-left: 80px;
}
.dashboard-tabs a sub {
	position: absolute;
    top: 8px;
    right: 8px;
    background: linear-gradient(-135deg, #81FBB8 0%, #28C76F 100%);
    border-radius: 6px;
    font-size: 11px;
    padding: 2px 6px;
}
.dashboard-tabs div:not(.website-select-dropdown),
.dashboard-tabs a {
	height: 100%;
	display: flex;
	justify-content: center;
	padding:0 25px;
	align-items: center;
	position: relative;
	cursor: pointer;
	opacity: 0.65;
	color: #ffffff;
}
.dashboard-tabs div img,
.dashboard-tabs a img {
	height: 13px;
	object-fit: contain;
	width: 13px;
	margin-right: 6px;
	filter: invert(1);
	transform: translateY(2px);

}
.dashboard-tabs div.active,
.dashboard-tabs a.active,
.dashboard-tabs div:hover,
.dashboard-tabs a:hover {
	opacity: 1;
}

.dashboard-tabs a.active:before,
.dashboard-tabs a:hover:before {
	height: 2px;
	width: 100%;
	position: absolute;
	bottom: -1px;
	left: 0;
	content: " ";
	background: #ffffff;
	
}

.dashboard-tabs div.active img,
.dashboard-tabs a.active img,
.dashboard-tabs div:hover img,
.dashboard-tabs a:hover img {
	filter: invert(1)
}

.analytics-grid {
	width: 100%;
	max-width: 900px;
	margin:80px auto;
}

.analytics-value-cards {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}


.analytics-value-card:nth-child(1),
.analytics-value-card:nth-child(2),
.analytics-value-card:nth-child(3) {
	width: calc(33.33% - 5px);
	z-index: 3;
}
.analytics-value-card {
	border-radius: 8px;
	box-shadow: 0 8px 16px rgba(0,0,0,0.05);
	width: calc(20% - 5px);
	margin-bottom: 10px;
	display: flex;
	flex-direction: column-reverse;
	justify-content: center;
	align-items: center;
	padding:50px 20px;
	position: relative;
	overflow: hidden;
	border:solid 1px #fafafa;
	z-index: 4;
	background: #fff;
}
.analytics-value-card:before {
	position: absolute;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    right: -80px;
    top: -80px;
    content: " ";
    background: #9CA9C3;
    opacity: 0.08;
}
.analytics-value-card img {
	height: 16px;
	position: absolute;
	top: 20px;
	right: 20px;
}

.analytics-value-card h3 {
	margin:0;
	font-size: 12px;
	text-transform: uppercase;
	font-weight: normal;
}

.analytics-value-card p {
	margin:0;
	font-size: 35px;
}
.analytics-table {
	margin:50px 0;
	border-radius: 6px;
}
.chart-container {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}
.chart-container  .Chart {
	width: calc(50% - 10px);
	border-radius: 8px;
	box-shadow: 0 8px 16px rgba(0,0,0,0.05);
	margin-bottom: 20px;
}

.analytics-title {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: row;
	margin:-50px 0 50px 0;
	position: relative;
}

.analytics-title .meta {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}
.analytics-title .meta h1 {
	margin:0;
}

.analytics-title .meta span {
	font-size: 14px;
	margin-top: -8px;
}
.analytics-title .meta i {
	font-style: normal;
	font-size: 12px;
	opacity: 0.4;
}
.analytics-title .meta div {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.analytics-title img {
	width: 80px;
	height: 80px;
	border-radius: 10px;
	border:solid 1px #efefef;
	box-shadow: 0 8px 16px rgba(0,0,0,0.05);
	margin-right: 20px;
	object-fit: contain;
	background: #fff;
}

.analytics-timeline-dropdown {
	border:solid 1px #efefef;
	border-radius: 6px;
	position: absolute;
	right: 0;
	top: 25px;
	z-index: 5;
	box-shadow: 0 8px 16px rgba(0,0,0,0.05);
	transition: all 0.2s;
	background: #fff;
}
.analytics-timeline-dropdown:hover {
	box-shadow: 0 8px 16px rgba(0,0,0,0.1);
}
.analytics-timeline-dropdown:before {
	width: 6px;
	height: 6px;
	border-bottom: solid 2px #ccc;
	border-right: solid 2px #ccc;
	content: " ";
	position: absolute;
	top: 15px;
	right: 15px;
	transform: rotate(45deg);
}
.analytics-timeline-dropdown div {
	display: none;
	width: 150px;
	height: 40px;
	cursor: pointer;
	padding:10px;
	opacity: 0.5;
}

.analytics-timeline-dropdown:hover div {
	display: block;
}

.analytics-timeline-dropdown div:hover {
	opacity: 1;
}

.analytics-timeline-dropdown div.active {
	display: block;
	opacity: 1;
	font-weight: 600;
}

.side-nav .close {
	display: none;
}
/***DASHBOARD HOME***/

.website-main-card {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	color: #fff;
	margin-bottom: 40px;
	padding-top: 20px;
	min-height: 120px;
}



.website-main-card-title {
	width: 100%;
}

.website-main-card-title a {
	color: #fff;
}

.website-main-card-title a:hover {
	opacity: 0.8;
}

.dashboard-home-bg {
	height: 260px;
    width: 100%;
    background: #262E47;
    z-index: 3;
    position: absolute;
    left: 0;
    top: 60px;
}

.website-main-card-title h1 {
	margin:0;
	padding:0;
	font-size: 28px;
}
.website-main-card-logo {
	width: 100px;
	height: 100px;
	object-fit: contain;
	border-radius: 8px;
	box-shadow: 0 6px 12px rgba(0,0,0,0.1);
	margin-right: 25px;
	flex-shrink: 0;
}
.website-main-card-iframe .fa-spinner {
	position: absolute;
	left: calc(50% - 5px);
	top: 50%;
}
.website-main-card-iframe:before {
	position: absolute;
    top: 0px;
    left: 5px;
    content: "...";
    line-height: 0;
    font-size: 34px;
    color: #efefef;
}

.website-main-card .button-set {
	display: flex;
	flex-shrink: 0;
}

.website-main-card .button-set .website-card-btn {
	padding:10px 25px;
	color: #fff;
	background:#fff;
	color: #262E47;
	border-radius: 44px;
	margin-right: 10px;
	transition: 0.2s;
	cursor: pointer;
}

.website-main-card .button-set .website-card-btn:hover {
	box-shadow: 0 0 0 6px rgba(0,0,0,0.1);
}

.website-main-card .button-set .website-card-btn.empty {
	background: transparent;
	border:solid 2px #fff;
	color: #fff;
}
.website-main-card .button-set .website-card-btn img {
	height: 13px;
    margin-right: 10px;
    filter: invert(1);
    transform: translateY(2px);
}

.website-main-analytics {
	width: 100%;
	background: #fff;
	border-radius: 8px;
	box-shadow: 0 8px 16px rgba(115,135,150,0.1);
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 14px;
	color: #ccc;
	text-transform: uppercase;
	overflow: hidden;
	position: relative;
}
.website-main-analytics .hover {
	width: calc(40% - 1px);
	height: 100%;
	cursor: pointer;
	position: absolute;
	left: 0;
	top: 0;
	background: rgba(255,255,255,0);
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: 0.3s;
	opacity: 0;
}
.website-main-analytics .hover:hover {
	opacity: 1;
	background: rgba(255,255,255,0.7);
}
.website-main-analytics .hover img {
	width: 17px;
	height: 17px;
	object-fit: contain;
	margin-right: 20px;
	transition: 0.3s;
	filter: invert(1);
}
.website-main-analytics .hover:hover img {
	margin-right: 10px;
}
.website-main-analytics .hover:last-of-type {
	left: auto;
	right: 0;
	width: calc(60% - 1px);
}

.website-main-analytics .hover a {
	background: #262E47;
	box-shadow: 0px 6px 12px rgba(0,0,0,0.1);
    color: #fff;
    display: flex;
    align-items: center;
    padding: 10px 20px;
    border-radius: 20px;
}
.website-main-analytics div {
	width: 20%;
	border-right: solid 1px #efefef;
	padding:20px 40px;
}
.website-main-analytics div:last-child {
	border-right:none;
}

.website-main-analytics div .fa-spinner {
	font-size: 15px;
}

.website-main-analytics div h3 {
	font-size:24px;
	color: #333;
	font-weight: 600;
	margin:0;
}

.dashboard-blogs-container {
	width: 100%;
	max-width: 768px;
	padding:20px;
	margin:80px auto;
}
.blog-dashboard-card {
	width: 100%;
	border-radius: 6px;
	border:solid 1px #efefef;
	display: flex;
	height: 140px;
	position: relative;
	padding: 30px 30px 30px 170px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	background: #fff;
	margin-bottom: 20px;
	box-shadow: 0 6px 12px rgba(0,0,0,0.05);
}
.blog-dashboard-card .action-buttons {
	position: absolute;
	left: 40px;
}
.blog-dashboard-card:hover .action-buttons {
	opacity: 1;
}
.blog-dashboard-card h4 {
	margin:0;
	font-size: 22px;
}
.blog-dashboard-card p {
	font-size: 14px;
	width: 100%;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	margin: 5px 0;
}

.blog-dashboard-card strong {
	font-size: 12px;
	opacity: 0.4;
}
.blog-dashboard-card img {
	width: 140px;
	height: 138px;
	position: absolute;
	left: 0;
	top: 0;
	border-radius: 6px 0 0 6px;
	object-fit: cover;
	transition: 0.2s;
}
.blog-dashboard-card:hover img {
	opacity: 0.5;
}

.dashboard-gallery-container {
	width: 100%;
	max-width: 768px;
	padding:20px;
	margin:50px auto;
}

.dashboard-inbox-container {
	width: 100%;
	max-width: 1020px;
	padding:20px;
	margin:30px auto;
}

.inbox-tabs {
	width: 100%;
	border-bottom: solid 1px rgba(0,0,0,0.1);
	margin-bottom: 0px;
	display: flex;
	overflow: auto;
}
.inbox-tabs div {
	padding:10px 20px;
	font-size: 16px;
	flex-shrink: 0;
	color:rgba(0,0,0,0.5);
	cursor: pointer;
	position: relative;
}

.inbox-tabs div span {
	position: absolute;
	right: 5px;
	color: #fff;
	background: linear-gradient(-135deg, #81FBB8 0%, #28C76F 100%);
	font-size: 11px;
	top: 0px;
	border-radius: 50%;
	width: 18px;
	height: 18px;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #fff;
}
.inbox-tabs div:hover,
.inbox-tabs div.active {
	color: #333;
}
.inbox-tabs div.active {
	font-weight: bold;
	border-bottom: solid 2px #333;
}
.dashboard-settings-container {
	width: 100%!important;
	max-width: 768px;
	padding:0px;
	margin:50px auto;
	display: block;
}

.dashboard-settings-container .settings-content.tab-content {
	width: 100%!important;
	padding:40px 0!important;
	overflow: visible;
	float: none!important;
}

.dashboard-gallery-container .add-product-button {
	position: relative;
	top: 0;
	display: inline-block;
	margin-top: 15px;
}

.title-button {
    padding: 15px 20px;
    box-shadow: 0 5px 15px rgba(0,0,0,0.1);
    border-radius: 40px;
    color: #fff;
    background: linear-gradient(-135deg, #81FBB8 0%, #28C76F 100%);
    text-transform: uppercase;
    font-size: 14px;
    cursor: pointer;
    font-weight: 600;
    margin-right: 10px;
    display: inline-block;
    margin-top: 10px;
}

.title-button:hover {
	background: #28C76F;
}

.title-button.empty {
	color: #333;
	background: #fff;
	border:solid 1px #efefef;
}
.title-button.empty:hover {
	background: #efefef;
}

.add-product-button.instagram {
	margin-left: 15px;
	background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
}
.add-product-button.instagram:hover {
	background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
}
.add-product-button.instagram:before {
	display: none;
}
.add-product-button .socicon {
	margin-right: 10px;
	transform: translateY(2px);
}

.initials-box {
	width: 60px;
	height: 60px;
	border-radius: 10px;
	margin-right: 20px;
	box-shadow: 0 4px 8px rgba(0,0,0,0.1);
	background: linear-gradient(-45deg, #455173 0%, #21283F 100%);
	display: flex;
	justify-content: center;
	align-items: center;
	color: #fff;
	font-size: 30px;
}

.dashboard-team-card sub {
	font-size: 14px;
	width: 20px;
	height: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	background: linear-gradient(-135deg, #81FBB8 0%, #28C76F 100%);
	color: #fff;
	position: absolute;
	top: 10px;
	right: 10px;
}

.dashboard-team-card sub {
	top: auto;
	bottom: 10px;
	border-radius: 6px;
	width: auto;
	height: auto;
	padding:4px 8px;
}

.dashboard-team-card {
	width: 100%;
	padding:20px;
	position: relative;
	display: flex;
	border-radius: 8px;
	border:solid 1px #efefef;
	background: #fff;
	transition: 0.2s;
	margin-bottom: 10px;
}
.dashboard-team-card:hover {
	box-shadow: 0 6px 12px rgba(0,0,0,0.05);
}
.dashboard-team-card .action-buttons {
	position: absolute;
	right: 0;
	top: 0;
	display: flex;
	align-items: center;
	height: 100%;
	margin-right: 20px;
}
.dashboard-team-card .action-buttons .button {
	margin-left: 5px;
}
.dashboard-team-card:hover .action-buttons {
	opacity: 1;
}
.dashboard-team-card img {
	width: 50px;
    height: 50px;
    border-radius: 10px;
    margin-right: 20px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}
.dashboard-team-card h4 {
	margin:0;
	font-size: 20px;
}

.dashboard-team-card h4 strong {
	font-size: 11px;
    background: rgb(235, 45, 70);
    padding: 2px 8px;
    color: #fff;
    display: inline-block;
    border-radius: 10px;
    font-weight: normal;
    margin-left: 5px;
    transform: translateY(-3px);
}
.dashboard-team-card h4 strong.editor {
	background: #333;
}
.dashboard-team-card h4 strong.custom {
	background: #F37A36;
}
.dashboard-team-card h4 strong.manager {
	background: rgb(40, 207, 54);
}
.dashboard-team-card h4 strong.blogger {
	background: rgb(191, 43, 236);
}
.dashboard-team-card span {
	font-size: 14px;
	opacity: 0.6;
}
.dashboard-team-card i {
	position: absolute;
	top: 10px;
	right: 10px;
	color: #ccc;
	font-size: 12px;
	font-style: normal;
}
.edit-blog-post {
	width: 100%;
	max-width: 900px;
	margin:50px auto;
}

.enable-feature-container {
	background: #fff;
	width: 100%;
	display: flex;	
	box-shadow: 0 8px 16px rgba(0,0,0,0.1);
	border:solid 1px #efefef;
	border-radius: 6px;
	margin-top: 10px;
	flex-direction: column;
	position: relative;
}

.enable-feature-container .left {
	border-bottom:solid 1px #efefef;
}
.enable-feature-container .left h4 {
	margin:0;
	line-height: 17px;
}

.enable-feature-container .left h4 span {
	font-size: 14px;
	font-weight: normal;
	opacity: 0.6;
}
.enable-feature-container .right label {
	font-size: 14px;
	text-transform: uppercase;
	width: 100%;
	margin-bottom: 10px;
}
.enable-feature-container .right img {
	width: 30px;
	height: 30px;
	border-radius: 50%;
	opacity: 0.6;
	transition: 0.2s;
	margin-right: 10px;
	cursor: pointer;
}

.enable-feature-container .right {
	flex-wrap: wrap;
}

.enable-feature-container .right img:hover,
.enable-feature-container .right img.active {
	opacity: 1;
}

.edit-blog-post-hero {
	width: 100%;
	margin-top: 20px;
	max-height: 270px;
	object-fit: cover;
	position: relative;
}

.edit-blog-post-hero:hover .hover-box {
	opacity: 1;
	visibility: visible;
}

.edit-blog-post-hero .hero-image-tag {
	position: absolute;
	top: 20px;
	left: 20px;
	font-size: 14px;
	font-weight: bold;
	background: #fff;
	padding:5px 10px;
	border-radius: 4px;
	background: #efefef;
}

.edit-blog-post-hero img  {
	max-height: 270px;
	object-fit: cover;
	width: 100%;
	border:solid 1px #efefef;
	border-radius:6px;
	background: #efefef;
}
.edit-blog-post-content,
.edit-product-description {
	width: 100%;
	position: relative;
	background: #fafafa;
	font-size: 18px;
	margin:70px 0;
	min-height: 200px;
	border:solid 1px #efefef;
	transform: scale(1);
}
.megadraft-editor .sidemenu__button {
	z-index: 22;
}

.megadraft-editor .megadraft-block{
	padding: 0!important;
	margin-bottom: 20px;
}

.megadraft-editor .paragraph + .paragraph{
	margin: 0!important;
}

.public-DraftStyleDefault-block{
	margin: 0!important;
}
.edit-blog-post-content .megadraft-editor .public-DraftEditorPlaceholder-root,
.edit-product-description .megadraft-editor .public-DraftEditorPlaceholder-root {
	padding:30px;
	width: 100%
}

.edit-blog-post-content .megadraft-editor .toolbar__arrow,
.edit-product-description .megadraft-editor .toolbar__arrow {
	display: none;
}
.edit-product-description .megadraft-editor .toolbar__item,
.edit-blog-post-content .megadraft-editor .toolbar__item {
	color: #757474;
}

.edit-product-description .megadraft-editor .toolbar__wrapper,
.edit-blog-post-content .megadraft-editor .toolbar__wrapper {
	background: #efefef!important;
}

.edit-product-card .megadraft-editor .toolbar__item:hover,
.edit-product-card .megadraft-editor .toolbar__item--active  {
  color: #333;
  background: #ccc;
}
.edit-product-description .megadraft-editor .toolbar__item:hover,
.edit-product-description .megadraft-editor .toolbar__item:hover {
	color: #333;
}
.edit-product-description .megadraft-editor .toolbar__item--active,
.edit-blog-post-content .megadraft-editor .toolbar__item--active {
	color: #333!important;
	background: #ccc!important;
}

.edit-product-description .megadraft-editor .sidebar__menu,
.edit-blog-post-content .megadraft-editor .sidebar__menu {
	left: -20px;
}
.edit-blog-post-content .megadraft-editor .toolbar__wrapper,
.edit-product-description .megadraft-editor .toolbar__wrapper {
	left: 0;
	width: 100%;
	box-shadow: none;
	border-radius: 0;
	background: #efefef;
}
.edit-blog-post-content .megadraft-editor .DraftEditor-editorContainer,
.edit-product-description .megadraft-editor .DraftEditor-editorContainer {
	padding:20px;
	font-family: Source Sans Pro;
	font-size: 18px;
	line-height: normal;
}

.edit-product-description .megadraft-editor .DraftEditor-editorContainer {
	max-height: 400px;
	overflow: auto;
}
.edit-blog-post-content .megadraft-editor .toolbar div:first-of-type,
.edit-product-description .megadraft-editor .toolbar div:first-of-type {
	width: 100%;
}

.edit-blog-post-content .megadraft-editor .toolbar__button,
.edit-product-description .megadraft-editor .toolbar__button {
	height: 45px;
}

.edit-blog-post-content .megadraft-editor .toolbar,
.edit-product-description .megadraft-editor .toolbar {
	position: sticky;
    top: 112px!important;
    left: 0!important;
    width: 100%!important;
    order: 0;
    display: block;
}


.edit-blog-post-content .megadraft-editor,
.edit-product-description .megadraft-editor {
	display: flex;
	flex-direction: column;
}

.edit-blog-post-content .megadraft-editor .sidebar,
.edit-product-description .megadraft-editor .sidebar {
	order: 2;
}
.edit-blog-post-content .megadraft-editor .DraftEditor-root,
.edit-product-description .megadraft-editor .DraftEditor-root {
	order: 3
}

.regular-action-footer {
	width: 100%;
	display: flex;
	justify-content: space-between;
}

@media (min-width: 1050px) {
	.edit-product-form-footer {
		left: auto!important;
		right: 0px;
		width: calc(100% - 200px)!important;
	}
	.dashboard-tabs:before {
	    z-index: 3;
	    width: 30px;
	    height: 30px;
	    content: " ";
	    position: fixed;
	    top:124px;
	    left: 200px;
	    background-size: 100%;
	}
	.dashboard-tabs {
		overflow: visible;
		padding-left: 200px;
	}
	#dashboard_master_container:before {
		left: 200px;
	}
	.header {
		width: calc(100vw - 200px);
		padding-left:20px;
	}
	.side-nav {
		width: 200px;
	}
	.side-nav .website-switch {
	    width: 180px;
	    border:solid 1px rgba(255,255,255,0.1);
	}
	.side-nav .website-switch span {
		opacity: 1;
	}
	.side-nav .website-sidebar-actions {
		width: 181px;
    	padding-left: 2px;
	}
	.side-nav .nav-links a {
		color: #000
	}
	/* .side-nav .nav-links a.active, 
	.side-nav .nav-links a:hover {
    	color: #fff!important;
	} */
	.side-nav .nav-links .nav-separator {
	    opacity: 1;
	    margin: 8px 0;
	}
	.master_container#dashboard_master_container {
	    width: calc(100vw - 200px);
	}
	.side-nav .website-sidebar-actions a {
	    width: 52px;
	    height: 52px;
	}
	.side-nav .website-sidebar-actions a:first-child {
		margin-right: 0;
	}
}

@media (max-width: 1050px) {
	#app .side-nav .website-sidebar-actions a.website-edit-action {
		width: 40px;
		justify-content: flex-start;
	}
	#app .side-nav .website-sidebar-actions a.website-edit-action img {
		margin-left: 13px;
	}

	#app .side-nav:hover .website-sidebar-actions a.website-edit-action {
		width: 120px;
	}

}

@media (max-width: 1170px) {
	.dashboard-upgrade-card {
		width: 100%;
		    display: flex;
    justify-content: space-between;
    height: auto;
    min-height: initial;
    align-items: center;
    margin-top: 30px;
	}

	.dashboard-upgrade-card p {
		margin:0;
	}

	.website-main-card {
		width: calc(100% - 0px);
		margin-right: 0;
	}
}

@media (max-width: 850px) {
	.website-main-analytics  {
		flex-wrap: wrap;
	}
	.website-main-analytics div {
		padding:18px;
		font-size: 12px;
	}
	.website-main-analytics div h3 {
		font-size: 20px;
	}
	.website-main-analytics div:nth-of-type(1),
	.website-main-analytics div:nth-of-type(2) {
			width: 50%;
			border-bottom: solid 1px #efefef;
	}

	.website-main-analytics div:nth-of-type(3),
	.website-main-analytics div:nth-of-type(4),
	.website-main-analytics div:nth-of-type(5) {
			width: 33.333%;
	}
	.website-main-analytics .hover {
		width: 100%;
		display: none;
	}
	.website-main-analytics .hover.last-of-type {
		top: auto;
		bottom: 0;
	}
}

@media (max-width: 1130px) {
	.dashboard-website-card-footer a {
		/*padding:0 17px;*/
	}
	.click-store-container .click-store-hero h1 {
		font-size: 40px;
		font-weight: bold;
	}
}

@media (max-width: 730px) { 
	.dashboard-upgrade-card {
		width: 100%;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	}
	.website-main-card {
		flex-direction: column-reverse;
		margin-bottom: 0;
	}

	.dashboard-home-container .website-main-card {
		    background: #262E47;
    		box-shadow: 0 0 0 80px #262E47;
	}
	.website-main-card-title {
		padding:20px;
		width: 100%;
	}
	.website-main-card-iframe {
		transform: scale(0.9);
	}
	.dashboard-site-page {
		width: 120px;
		height: 180px;
	}

}

@media (max-width: 930px) { 
	.dashboard-home-content .right {
		margin:0;
		width: 100%;
	}
	.dashboard-home-container .phone-screen-startup {
		display: none;
	}
}

@media (max-width: 779px) {
	.add-product-button.instagram {
		margin-left: 0;
	}
	.dashboard-gallery-container.white-phone,
	.dashboard-settings-container {
		padding:0;
		/*background: #fff;
		box-shadow: 0 0 0 40px #fff;*/
		color: #000;
	}

	.master_container .dashboard-gallery-container.white-phone .manage-store-title {
		color: #000;
	}
	.manage-store-title .currency-selector {
		margin-top: 32px!important;
	}
	.enable-feature-container {
		flex-wrap: wrap;
	}
	.enable-feature-container .left,
	.enable-feature-container .right {
		width: 100%;
	}

	.enable-feature-container .left {
		border-left: none;
		border-bottom: solid 1px #efefef;
	}
	.notifications-container .notif-item {
		background: #fff;
		box-shadow: 0 6px 12px rgba(0,0,0,0.1);
		border:solid 1px #efefef;
		border-radius: 8px;
		margin-bottom: 10px;
	}
	.dashboard-home-container .phone-screen-startup {
		display: none;
	}
	.dashboard-home-bg {
		display: none;
	}
	.dashboard-home-content .right {
		margin:0;
		width: 100%;
		padding-bottom: 80px;
	}
	.website-main-card-logo {
		order: 1;
		margin:0;
	}
	.dashboard-home-content {
		margin-top: 30px;
	}
	.website-main-card-title {
		text-align: center;
	}
	.q-a-card {
		width: 100%;
		height: 100px;
		justify-content: flex-start;

	}
	.website-main-card .button-set {
		margin-top: 20px;
	}
	.add-external-page {
		margin-left: 30px;
	}
	.insights-grid {
		padding:0;
	}
	.insights-card-value {
		width: 50%;
		border-bottom: solid 1px #efefef;
	}
	.insights-card {
		flex-wrap: wrap;
	}
	.insights-grid {
		width: 100%;
	}
	.insights-card-title {
		flex-wrap: wrap;
	}
	.insights-card-title .right {
		width: 100%;
		justify-content: flex-start;
	}
	.insights-card-title .btn {
		margin-left: 80px;
		margin-bottom: 20px;
	}
	.insights-filter .filter-socicons .socicon {
		display: none;
	}
	.insights-filter .analytics-timeline-dropdown {
		top: 0;
		bottom: auto;
		position: absolute;
		height: 48px;
	}
	.burger {
		width: 22px;
	    height: 16px;
	    border-radius: 0px;
	    border-top: solid 2px #000;
	    border-bottom: solid 2px #000;
	    position: relative;
	    cursor: pointer;
	    overflow: visible;
	    display: block;
	    margin-right: 18px;
	}
	.burger:before {
		    position: absolute;
    left: 0px;
    transition: all 0.1s;
    width: 100%;
    height: 2px;
    content: " ";
    background: #000;
    top: 50%;
    margin-top: -1px;
	}
	.your-apps-list {
		justify-content: center;
	}
	.header {
		width: 100%;
		height: 60px;
		padding-left: 0px;
		color: #fff;
		background: #fd6270;
		border-bottom:none;
	}
	#dashboard_master_container:before,
	#dashboard_master_container:after {
		display: none;
	}
	
	.header .burger {
		border-color: #fd6270;
	}
	.notifications-dropdown img {
		filter: invert(1);
	}
	.header .burger:before {
		background: #fd6270;
	}
	.header h3 {
		font-size: 16px;
		margin-left: 0;
		margin-top: 0px!important;
	}
	.master_container {
		padding:80px 20px 0 20px;
	}
	.notifications-dropdown {
		padding:21px 11px;
		border:none;
	}
	.dashboard-website-card.empty .dashboard-website-card-screen:nth-child(3),
	.dashboard-website-card.empty .dashboard-website-card-screen:nth-child(2) {
		position: absolute;
		bottom: -10px;
		left: 10px;
	}
	.dashboard-website-card.empty .dashboard-website-card-screen:nth-child(3) {
		left: auto;
		right: 10px;
	}
	.profile-dropdown {
		display: none;
		font-size: 0;
		padding:0 10px;
		border-left-color:transparent;
	}
	.profile-dropdown .arrow-down {
			border-color: #fff;
	}
	.profile-dropdown img {
		margin-right: 0;
	}
	.profile-dropdown .profile-dropdown-content {
		font-size: 15px;
		width: 200px;
		right: 0;
	}
	.notifications-dropdown img {
		width: 18px;
	}
	.website-sidebar-actions {
		display: none;
	}
	.side-nav {
		width: 80%;
		/*height: calc(100vh - 60px);*/
		height: 100%;
		position: fixed;
		bottom:0;
		left: 0;
		top: auto;
		box-shadow: 0 -4px 8px rgba(0,0,0,0);
		padding-bottom: 10px!important;
	    padding-top: 0!important;
	    overflow: visible;
	    background: #ffffff;
	    display: block;
	    overflow: auto;
	    border-radius: 0 30px 30px 0;
		left: -90%;
	    z-index: 6000;
	}

	.side-nav:hover {
		box-shadow: 0 -4px 8px rgba(0,0,0,0);
	}

	.side-nav.active {
		box-shadow: -8px 0px 16px rgba(0,0,0,0.2);
		left: 0;
	}

	#page-wrap {
		padding-bottom: 120px;
	}

	.side-nav .close {
		display: block!important;
		left: auto!important;
		right: 30px!important;
	}
	.side-nav .close:before,
	.side-nav .close:after {
		background: #fff!important;
	}
	.side-nav.active {
		left: 0;
	}
	.master_container {
		width: 100%;
	}

	.two-columns {
		flex-direction: column;
	}
	.two-columns .form {
		width: 100%;
	}
	.web-dashboard-header {
		padding:0 0 20px 0;
	}
	.web-dashboard-title img {
		width: 70px;
		height: 70px;
	}
	.web-dashboard-title  {
		font-weight: 700;
		font-size: 22px;
	}
	.tab-title {
		font-size: 17px;
		font-weight: 600;
	}
	.tabs {
		overflow:auto;
	}
	.tabs .tab {
		padding:15px 20px;
		white-space: nowrap;
	}
	.tabs .edit-design {
		right: auto;
		position: relative;
	}
	.notifications-dropdown .notifications-container {
		display: none;
	}
	.dashboard-quick-actions {
		margin:60px 0 0 0;
	}

	.analytics-value-cards .analytics-value-card {
		width: calc(50% - 5px);
	}
	.analytics-timeline-dropdown {
		top: auto;
		bottom: -60px;
		color: #333;
	}
	.analytics-title img {
		background: #fff;
	}
	.notifications-page {
		min-height: 500px;
		box-shadow: none!important;
	}
	.analytics-value-cards {
		padding-top: 50px;
	}

	.profile-setup-form .input-group {
		width: calc(50% - 5px);
	}
	.profile-setup-form .input-group.with-icon {
		width: calc(33.33% - 5px);
	}
	.social-media-connect.connected div {
		width: calc(50% - 5px);
		overflow: hidden;
	}
	.social-media-connect div {
		width: calc(50% - 5px);
	}
	.social-media-connect div .socicon {
		margin-left: 20px;
		margin-right: 10px;
	}
	.social-media-connect div {
		text-align: left;
	}
	.profile-setup-image img {
		width: 70px;
		height: 70px;
	}
	.profile-setup-image {
		margin:10px 0;
	}
	.side-nav .nav-links .nav-separator {
	    opacity: 0.5;
	    margin: 15px 0 10px 0!important;
	    height: auto;
	    float: left;
	    transform: none;
	    font-size: 14px;
	    color: #000;
	}
	.side-nav .nav-links a {
		display: flex;
		align-items: center;
		font-size: 30px;
		font-weight: 300;
		color: rgba(0,0,0,0.7)!important;
		height: 100%;
		margin: 15px 0px;
	}
	.side-nav .nav-links a.active {
		color: #fff!important;
	}
	.side-nav .nav-links a img {
	    width: 17px;
	    height: 17px;
	    margin-right: 12px;
	    margin-left: 33px;
	    object-fit: contain;
	}
	.side-nav .nav-links a span {
		left:28px;
    	top: 2px;
		width: 30px;
		height: 30px;
	}

	.side-nav .logo {
		display: block;
	}
	.side-nav .close {
		display: block!important;
    left: auto!important;
    right: 0px!important;
    top: 0!important;
    height: 79px!important;
    width: 70px!important;
    /* padding: 40px; */
    border: solid 25px #171b29;
	}
	.website-switch {
		position: relative;
		top: 0;
		left: 0;
		border:none!important;
		height: auto;
		width: 100%!important;
		margin: 0px 0;
	    background: #171b29;
	    padding: 20px 10px;
	    margin-bottom: 20px;
	    box-shadow: 0 -30px 0px 30px #171b29;
	    border-radius: 0;
	}
	.website-switch span {
		font-size: 26px;
		font-weight: bold;
		opacity: 1;
	}
	.website-switch-trigger {
		width: 40px;
		height: 40px;
		font-size: 0;
		left: 8px;
	}
	.website-switch:after {
		display: none;
	}
	.website-switch-trigger img {
		display: none;
		width: 100%;
	}
	.website-switch .switch-logo {
		width: 40px;
		height: 40px;
		flex-shrink: 0;
		margin-right: 10px;
	}
	.header .left {
	
	}
	.header .left .notifications-dropdown {
		display: none;
	}
	.website-switch {
		width: 50px;
	}
	.dashboard-tabs {
		padding-left: 0;
		height: 60px;
		background: #262E47;
		border-bottom: solid 1px rgba(255,255,255,0.1);
		top: 59px;
		overflow: auto;
		justify-content: flex-start;
	}
	.dashboard-tabs a.active:before, 
	.dashboard-tabs a:hover:before {
		background: #fff;
	}
	.dashboard-tabs a {
		padding:0 20px;
		white-space: nowrap;
		color: #fff;
	}
	.dashboard-tabs a:before {
		bottom: 0!important;
		height: 3px!important;
	}
	
	.dashboard-tabs a img {
		filter: invert(1)!important;
	}
	.dashboard-tabs a.active,
	.dashboard-tabs a:hover {
		background: #262E47;
		box-shadow: none;
		font-weight: bold;
	}
	.notifications-dropdown .messages {
		font-size: 0;
		width: 10px;
		height: 10px;
		border:none;
		padding:0;
	}
	.notifications-dropdown strong,
	.notifications-dropdown:before {
		display: none;
	}

	.discover-search {
		    width: calc(100% + 40px);
		    margin-left: 0;
		    padding: 10px 10px;
		    margin-left: -20px;
	}
	.discover-search-tags {
		margin-top: 10px;
	}
	.discover-search-tags div {
		margin-right: 10px;
	}
	.discover-title {
		margin:20px auto 20px auto;
	}
	.discover-title h1 {
		font-size: 35px;
	}
	.discover-website-card {
		width: calc(50% - 10px);
		margin:0 5px 10px 5px;
		height: 230px;
	}
	
	.discover-website-card .card-header,
	.discover-website-card h1 {
		padding:10px;
	}

	.empty-item-container {
		padding:30px;
		height: auto;
	}
	.empty-item-container-description {
		width: 100%;
	}

	.designs-wrapper .design-box {
		width: 40%;
		margin-left:10px;
		margin-right: 10px;
	}

	.contents-item .left input {
		width: 100%;
	}
	.contents-item .icon-set {
		opacity: 0.4;
		visibility: visible;
	}
	.new-page-content {
		width: 100%!important;
	}
	.dashboard-website-card-screen iframe {
		transform: scale(0.173);
		bottom: -14px;
	}
	.dashboard-link-mobile {
		display: flex;
	}
	.dashboard-gallery-grid {
		margin-top: 40px;
	}
	.edit-blog-post-header {
		flex-wrap: wrap;
	}
	.edit-blog-post-hero {
		width: 100%;
		margin-right: 0;
	}
	.edit-blog-post-header .edit-product-variant {
		width: 100%;
		margin-top: 20px;
		background: #fff;
	}
	.inbox-table {
		flex-wrap: wrap;
	}
	.inbox-table li {
		position: relative;
	}
	.inbox-table li .inbox-table-item {
		padding:15px;
	}
	.inbox-table .inbox-item-name {
		width: 100%;
		position: relative;
		padding:0;
	}
	.inbox-table .inbox-item-message {
		width: 100%;
		margin-top: 6px;
	}
	.inbox-table .inbox-item-type {
		position: absolute;
		width: auto;
		top: 20px;
		right: 20px;
	}

	.unread .inbox-item-message:before {
		display: none!important;
	}
	.unread .inbox-item-name:before {
	    width: 10px;
	    height: 10px;
	    border-radius: 50%;
	    position: absolute;
	    top: 7px;
	    left: -16px;
	    background: linear-gradient(-135deg, #81FBB8 0%, #28C76F 100%);
	    content: " ";
	}
	.dashboard-inbox-container {
	    padding: 0px;
	    margin: 30px auto;
	    margin-top: 77px;
	}
	.languages-sidebar {
		
	}
	

}

.side-nav .close {
	display: none;
}

.languages-sidebar {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	position: absolute;
	bottom: 15px;
	left: 10px;
}

.languages-sidebar img {
	width: 20px;
	height: 20px;
	object-fit: cover;
	border-radius: 50%;
	box-shadow: 0 6px 12px rgba(0,0,0,0.1);
	margin: 0px 8px;
	opacity: 0.4;
	cursor: pointer;
}
.languages-sidebar img.active {
	opacity: 1;
}


#fb-root iframe {
	background: none;
	box-shadow: none;
}

#fb-root iframe circle {
	fill: #455173!important;
}

.inbox-table {
	width: 100%;
	padding:0;
	margin:0;
	list-style: none;
	background: #fff;
	box-shadow: 0 8px 16px rgba(115,135,150,0.1);
}

.inbox-table li {
	width: 100%;
}
.inbox-table li .inbox-table-item {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	padding:20px;
	cursor: pointer;
	border-bottom: solid 1px #f0f5f9;
	background: #fafafa;
	position: relative;
}

.inbox-table li .inbox-table-item.unread {
	background: #fff;
}

.inbox-table li .inbox-table-item:hover {
	background: #fff;
	box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
	z-index: 2;
}

.inbox-item-name {
	width: 20%;
	padding-right:20px;
}

.inbox-item-name span {
	font-size: 16px;
}

.inbox-item-name i {
	font-size: 14px;
    font-style: normal;
    opacity: 0.4;
    text-transform: capitalize;
    display: block;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.inbox-item-message {
	width: 60%;
	position: relative;
}
.inbox-item-message span {
	font-size: 14px;
	color: #96A9C8;
	font-weight: 600;
}
.inbox-item-message strong {
	font-weight: 600;
}

.unread .inbox-item-message {
	font-weight: 600;
}
.unread .inbox-item-message:before {
	width: 10px;
	height: 10px;
	border-radius: 50%;
	position: absolute;
	top: 7px;
	left: -16px;
	background: linear-gradient(-135deg, #81FBB8 0%, #28C76F 100%);
	content: " ";
}
.inbox-product {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}
.inbox-item-type {
	padding-left: 20px;
	width: 20%;
	font-size: 14px;
    text-align: right;
    position: relative;
    text-transform: uppercase;
}

.inbox-item-type span {
	padding:4px 8px;
	border-radius: 10px;
	background: #777777;
	color: #fff;
	font-size: 12px;
}

.inbox-item-message img {
	width: 50px;
	height: 50px;
	border-radius: 8px;
	margin-right: 10px;
	object-fit: cover;
}

.form-result-box {
	width: 100%;
	padding-bottom: 15px;
	margin-bottom: 15px;
	border-bottom: solid 1px #efefef;
}

.inbox-item-name strong {
	font-size: 16px;
}

#formReply .modal-content {
	padding:0;
}

#formReply .modal-content-scroll {
	display: block;
}
#formReply time {
	display: block;
    padding: 5px;
    border: solid 1px #efefef;
    border-radius: 8px;
    text-align: center;
    font-size: 14px;
    background: #fafafa;
    margin-bottom: 15px;
}

.form-result-box-label {
	font-size: 12px;
	font-weight: bold;
	margin-bottom: 0px;
	text-transform: uppercase;
}

.admin-search-bar {
	width: 100%;
	position: relative;
	margin:30px 0px;
}

.admin-search-bar input {
	height: 60px;
	border-radius: 40px;
	background: #fff;
	transition: 0.2s;
	color: #333;
	font-size: 18px;
	padding-left: 60px;
	border:none;
	width: 100%;
	outline: none;
}

.admin-db-table {
	width: 100%;
	background: #fff;
	border:solid 1px #efefef;
	border-radius: 10px;
	box-shadow: 0 6px 12px rgba(0,0,0,0.05);
}

.admin-db-table-row {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	border-bottom: solid 1px #efefef;
	cursor: pointer;
	transition: 0.2s;
	position: relative;
}
.admin-db-table-row:hover {
	background: #fafafa;
}
.admin-db-table-row .user-badge {
	width: 80px;
	position: relative;
}

.admin-db-table-row .user-badge img {
	width: 40px;
	height: 40px;
	object-fit: contain;
	border-radius: 50%;
    margin: 0 auto;
    position: absolute;
    top: -4px;
    left: 10px;
}
.admin-db-table-row .btn {
	position: absolute;
	top:10px;
	right: 10px;
	opacity: 0;

}
.admin-db-table-row:hover .btn {
	opacity: 1;
}

.user-badge.basic span {
	background: #4CAAD0; 
	color: #307591;
}

.admin-db-table-row .user-badge.pro span {
	background: #000000; 
	color: #3D6428;
}

.admin-db-table-row.admin-db-table-header {
	cursor: default;
	background: #fafafa;
}

.admin-db-table-row.admin-db-table-header div {
	font-weight: bold;
	opacity: 1!important;
	font-size: 16px!important;
}

.user-badge.vip span {
	background: #D1CB64; 
	color: #7C783A;
}

.user-badge.admin span {
	background: #6EAF4C; 
	color: #7C783A;
}

.user-badge.unfinished span{
	background: #ee2c2c;
	color: #ffffff;
}
.user-badge span {
	font-size: 13px;
	padding:3px 6px;
	border-radius: 10px;
	color: #fff!important;
	background: #333;
}

.admin-db-table-row div {
	padding:15px 10px;
}

.admin-db-table-row div:nth-child(2),
.admin-db-table-row div:nth-child(3) {
	width: 33.333%;
}

.admin-db-table-row div:nth-child(4) {
	width: calc(33.333% - 80px);
	font-size: 14px;
	opacity: 0.4;
}

.admin-search-bar input:focus {
	background: #fafafa;
	box-shadow: 0 6px 12px rgba(0,0,0,0.1);
}
.admin-search-bar img {
	width: 20px;
	height: 20px;
	object-fit: contain;
	position: absolute;
	left: 20px;
	top: 20px;
}
.admin-stats-wrapper {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	max-width: 1000px;
	margin:0px auto;
	/*background: #262E47;
	box-shadow: 800px 0 0 #262E47, -800px 0 0 #262E47, 0px -60px 60px #262E47;
	color: #fff;*/
}

.db-user-header {
	width: 100%;
	display: flex;
	align-items: center;
	margin-top: 30px;
	padding-top: 30px;
	margin-bottom: 30px;
	padding-bottom: 30px;
	border-bottom: solid 1px #efefef;
	position: relative;
}

.db-user-header .m-since {
	font-size: 14px;
	opacity: 0.6;
	position: absolute;
	bottom: 30px;
	right: 0;
}

.db-user-header .user-badge span {
	font-size: 16px;
	padding:7px 14px;
	border-radius: 14px;
	margin-left: 5px;
	filter: grayscale(1);
	opacity: 0.25;
	transform:scale(0.8);
	cursor: pointer;
}

.db-user-header .user-badge:hover span,
.db-user-header .user-badge.active span {
	filter: none;
	transform:none;
	opacity: 1;
}

.db-user-header img {
	width: 80px;
	height: 80px;
	border-radius: 0;
	margin-right: 20px;
	object-fit: contain;
}
.db-user-header h3 {
	margin:0;
	font-size: 24px;
}


.admin-stats-wrapper:before {
	/*position: fixed;
	width: 100vw;
	height: 100vh;
	background:linear-gradient(-45deg, #455173 0%, #21283F 100%);
	top: 0;
	left: 0;
	content: " "; */
}

.admin-stats-wrapper .right,
.admin-stats-wrapper .left {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	position: relative;
	z-index: 2;
	justify-content: space-between;
}
.admin-stats-wrapper .left {
	margin:0px auto;
	border-bottom: solid 1px #efefef;
}
.admin-stats-card {
	width: 25%;
	padding:20px 20px;
	display: flex;
	flex-direction: column-reverse;
	align-items: flex-start;
	justify-content: center;
}

.admin-stats-wrapper .left .admin-stats-card{
	width: auto;
}
.admin-stats-card h4 {
	font-size: 80px;
	font-weight: 400;
	margin:0;
}
.admin-stats-card h2 {
	font-size: 15px;
	font-weight: 400;
	text-transform: uppercase;
	margin:0;
	position: relative;
	opacity: 0;
	animation: adminName 0.6s forwards;
	animation-delay: 0.4s;
}
.admin-stats-card h2:before {
	/*content: " ";
	width: 0%;
	position: absolute;
	top: 0;
	left: 0;
	height: 1px;
	background: rgba(255,255,255,0.3);
	animation: adminLine 0.6s forwards;
	animation-delay: 1s;*/
}

.right .admin-stats-card h4 {
	font-size: 40px;
}

.right .admin-stats-card {
	width: 25%;
}
@keyframes adminLine {
	0% {width: 0}
	100% {width: 100%;}
}
@keyframes adminName {
	0% {transform: translateY(-6px); opacity: 0;}
	100% {transform: translateY(-6px); opacity: 1;}
}

.notifications-dropdown .notifications-container {
	position: absolute;
	width: 300px;
	top: 100%;
	background-color: #fff;
	border-radius: 5px;
	right: 0;
	box-shadow: 0 8px 16px rgba(0,0,0,0.1);
	opacity: 0;
	visibility: hidden;
	transform: translateY(-5px);
	transition: all 0.3s;
}

.zero-notifications {
	font-size: 16px;
	text-align: center;
	font-weight: bold;
	padding:20px;
	    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.zero-notifications img {
	width: 100%;
	max-width: 340px;
	margin:0px auto;
}

.notifications-container .scroll {
	width: 100%;
	min-height: 300px;
	background: #fff;
	border-radius: 5px;
}

.notifications-dropdown .notifications-container .scroll {
	max-height: 400px;
	overflow: auto;
}
.notifications-dropdown:hover .notifications-container {
	opacity: 1;
	visibility: visible;
	transform: none;
}
.notifications-container .notif-item {
	width: 100%;
	display: flex;
	justify-content: flex-start;
	padding:20px 20px 20px 20px;
	position: relative;
	font-size: 15px;
	border-bottom: solid 1px #efefef;
}
.notifications-container .notif-item:hover {
	background: #fafafa;
}

.notifications-container .notif-item .notif-web-icon {
	width: 60px;
	height: 60px;
	border-radius: 15px;
	margin-right: 20px;
	overflow: hidden;
	transform: none!important;
	background: #efefef;
	flex-shrink: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 30px;
	font-weight: 200;
	text-align: center;
}

.notifications-container .notif-item .notif-web-icon img {
	width: 100%;
	height: 100%;
	transform: none!important;
	object-fit: cover;
}
.notifications-container .notif-item p {
	margin:0px 0 0 0;
	font-weight: normal;
}
.notifications-container .notif-item p u {
	text-decoration: none;
	font-size: 12px;
	opacity: 0.4;

}
.notifications-container .notif-icon {
	width: 25px;
	height: 25px;
	display: flex;
	justify-content:center;
	align-items: center;
	position: absolute;
	top: 57px;
	left: 15px;
	background: linear-gradient(-45deg, #455173 0%, #21283F 100%);
	border-radius: 50%;
	flex-shrink: 0;
}

.notifications-container .notif-icon.green {
	background: #5EBF65;
}

.notifications-container .notif-icon.orange {
	background: #D9894A;
}

.notifications-container .notif-icon.red {
	background: #D03838;
}

.notifications-container .notif-icon img {
	width: 13px;
	filter: invert(1);
	transform: none!important;
}

.notifications-container .see-all-notif {
	text-align: center;
	padding:6px 0;
	text-transform: uppercase;
	font-weight: normal;
	border-top: solid 1px #efefef;
	justify-content: center;
	align-items: center;
}

.notifications-page {
	width: 100%;
	max-width: 800px;
	margin:50px auto;
	box-shadow: 0 8px 16px rgba(0,0,0,0.1);
	border-radius: 10px;
}

.notifications-page .notifications-container .notif-item {
	font-size: 18px;
}

.notifications-page .notifications-container .see-all-notif {
	display: none;
}

.notifications-page .notifications-container .notif-item p {
	margin-top: 14px;
}

.notifications-page .notifications-container .notif-item p u {
	font-size: 14px;
	position: absolute;
	right: 20px;
	top: 10px;
}
.publish-status-badge {
	width: 20px;
	height: 20px;
	white-space: nowrap;
	overflow: hidden;
	font-size: 12px;
	text-transform: uppercase;
	background: #28C76F;
	color: #fff;
	position: absolute;
	left: 5px;
	top: 5px;
	transition:0.2s;
	border-radius: 5px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.publish-status-badge.unpublished {
	background: #f1855b;
}

.cart-item:hover .publish-status-badge.unpublished {
	width: 100px;
}
.cart-item:hover .publish-status-badge {
	width: 90px;
}
.cart-item:hover .publish-status-badge .fa {
	margin-right: 2px;
}
.publish-status-badge .fa {
	width: 20px;
	height: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 7px;
	flex-shrink: 0;
	transform: translateY(1px);
	transition: 0.2s;
}
.generic-tabs {
	width: 100%;
	display: flex;
	flex-shrink: 0;
	overflow: auto;
	flex-wrap: nowrap;
	border-bottom: solid 1px #efefef;
	margin-bottom: 20px;
}

.generic-tabs div {
	flex-shrink: 0;
	padding:10px 20px;
	opacity: 0.5;
	font-weight: bold;
	cursor: pointer;
	font-size: 16px;
}

.generic-tabs div:hover,
.generic-tabs div.active {
	opacity: 1;
	border-bottom: solid 1px #333;
}
.insta-warning-legend {
	font-size: 13px;
	padding:4px 8px;
}

.insta-warning-legend a {
	text-decoration: underline;
	color: #dc2743;
}
.insta-warning-legend a:hover {
	opacity: 0.8;
}
#tourModal .modal-content {
	padding:0;
	border-radius: 12px;
	overflow: hidden;
	max-width: 600px;
}

#tourModal .close {
	filter: invert(1);
}
.tour-container {
	width:100%;
}


.tour-graphics {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}
.tour-graphics img.active {
	display: block;
}
.tour-graphics img {
	display: none;
	width: 100%;
	object-fit: cover;
}
.tour-point-title.active {
	display: block;
}

.tour-point-title {
	display: none;
	padding: 40px 40px 40px 40px;
    width: 100%;
    max-width: 500px;
    text-align: center;
    margin: auto;
}

.tour-point-title h1 {
	margin:0;
	font-size: 27px;
}
.tour-point-title p {
	margin:0;
}

.tour-nav {
	display: flex;
	justify-content: space-between;
	height: 50px;
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	border-top: solid 1px #efefef;
	align-items: center;
}
.tour-nav-dots {
	display: flex;
}

.tour-url {
	position: absolute;
    bottom: 70px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.tour-url a {
	padding:8px 16px;
	border-radius: 20px;
	background: #20BB37;
	font-size: 16px;
	color: #fff;
}
.tour-url img {
	height: 11px;
    margin-left: 4px;
    filter: invert(1);
}

.tour-url a:hover {
 	background: #3CD052;
}

.tour-nav-btn {
	height: 100%;
	width: 100px;
	text-align: center;
	border-right: solid 1px #efefef;
	display: flex;
	align-items: center;
	color: #333;
	justify-content: center;
	cursor: pointer;
}

.tour-nav-btn:hover {
	color: #333;
}

.tour-nav-btn:last-of-type {
	border-right: none;
	border-left: solid 1px #efefef;
}

.tour-nav-dots div {
	width: 8px;
	height: 8px;
	background: #ccc;
	border-radius: 50%;
	margin:0 4px;
	cursor: pointer;
}

.tour-nav-dots div:hover,
.tour-nav-dots div.active {
	background-color: #435176;
}

.deleting-loader {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top:0;
	color: red;
	display: flex;
	justify-content: center;
	align-items: center;
	background: #fff;
}

.deleting-loader span {
	font-size: 25px;
	position: relative;
	display: flex;
	justify-content: center; 
	align-items:center;
	color: red;
	width: 40px;
	height: 40px;
}

.deleting-loader span strong {
	position: relative;
    z-index: 4;
    font-weight: normal;
    transform: rotate(45deg);
    font-size: 30px;
}

.deleting-loader span:before {
	content: " ";
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	box-shadow: inset 0 0 0 2px red;
	border-radius: 50%;
}

.deleting-loader span:after {
	content: " ";
	z-index: 2;
	position: absolute;
	background: #fff;
	width: 20px;
	height: 20px;
	top: 0;
	right: 0;
	transform-origin: 0% 100%;
	animation: deleteSpin 0.8s infinite;
}
@keyframes deleteSpin {
	from {transform: rotate(0deg);}
	from {transform: rotate(360deg);}
}


@media (max-width: 779px) {
	#tourModal .modal-content {
		width: 100vw;
		height: 500px;
		transform: scale(0.93);
	}
	.tour-point-title {
		text-align: left;
	}
	.tour-graphics {
		height: auto
	}
	.tour-graphics img {
		height: auto;
	}
	.tour-nav {
		height: 60px;
		padding-bottom: 0px;
	}
	.tour-url {
		bottom: 90px;
	}
	.tour-nav-btn {
		height: calc(100% + 20px);
		margin-bottom: -20px;
		padding-bottom: 20px;
	}
	.tour-nav-dots div {
		margin:0 8px;
	}
	.flex-container .accordion-toggle {
		font-size: 18px;
	}
}

@media (max-width: 779px) {
	.dashboard-blogs-container {
		margin-bottom: 150px;
	}
	.blog-dashboard-card {
		height:auto;
		padding:0px;
	}
	.blog-dashboard-card h4 {
		width: 100%;
		font-size: 20px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		padding:20px 20px 0px 20px;
	}
	.blog-dashboard-card p {
		margin:0;
		padding: 0 20px;
	}
	.blog-dashboard-card strong {
		margin-top: 10px;
		padding:0 20px 20px 20px;
	}
	.blog-dashboard-card .action-buttons {
		left: 20px;
	}
	.blog-dashboard-card img {
		width: 100%;
		height: 200px;
		position: relative;
		border-radius: 6px 6px 0 0;
	}
}

.facebook-page-card {
	width: 100%;
	box-shadow: 0 8px 16px rgba(0,0,0,0.05);
	border-radius: 10px;
	margin-bottom: 30px;
	border:solid 1px #efefef;
	display: flex;
	overflow: hidden;
	flex-direction: column;
}

.facebook-page-card .card-set {
	width: 100%;
	padding:20px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	cursor: pointer;
	transition: all 0.2s;
	border-bottom: 1px solid #efefef;
}

.facebook-page-card .card-set.column {
	flex-direction: column;
	text-align: center;
}
.facebook-page-card .card-set.column .social-accounts-circle {
	margin-right: 0;
}

.facebook-page-card .card-set.column img {
	width: 80px;
	height: 80px;
}
.facebook-page-card .card-set {
	margin-right: 0;
}

.facebook-page-card .card-set img {
	width: 65px;
	height: 65px;
}
.facebook-page-card .card-set:hover {
	background: #fafafa;
}
.facebook-page-card img {
	width: 50px;
	height: 50px;
	border-radius: 50%;
	object-fit: cover;
	margin-right: 20px;
}

.facebook-page-card h4 {
	margin:0;
	font-size: 18px;
}
.facebook-page-card span {
	font-size: 14px;
	text-transform: uppercase;
	font-weight: 400;
	opacity: 0.5;
}


.flex-container#loadingFlex {
	min-height: 60vh;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	animation:fadeTop 1s forwards;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

.cai-bot-command {
	padding: 8px 16px;
    border-radius: 20px;
    font-size: 16px;
    margin-bottom: 8px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #434b75;
}
.cai-bot-command .fa {
	transition:0.2s;
	opacity: 0;
	color: #28B8EA;
}
.cai-bot-command:hover {
	background:#636eb7;
	box-shadow: 0 6px 12px rgb(0 0 0 / 20%);
}
.cai-bot-command:hover .fa {
	opacity: 1;
}

.map_frame {
	border: none;
	border-radius: 10px;
}

