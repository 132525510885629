.carousel-container {
  width: 100%;
  max-width: calc(100vw - 2rem);
  margin: 0 auto;
}

.slick-prev,
.slick-next {
  display: none !important;
}
