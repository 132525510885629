/**.input-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  position: relative;
  margin-bottom: 20px;
}
.input-group .icon {
  position: absolute;
  height: 18px;
  right: 15px;
  top: 38px;
}
.input-group .date-input {
  width: calc(50% - 10px);
  position: relative;
}
.input-group .date-input img {
  position: absolute;
  right: 15px;
  top: 18px;
  width: 15px;
}

.input-group.image-upload {
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 30px;
}

.input-group input,
.input-group select {
  outline: none!important;
  border: solid 1px #E5E4E4;
  border-radius: 4px;
  height: 53px;
  width: 100%;
  padding:0 15px;
  font-size: 16px;
  transition:all 0.2s ease;
}
.input-group textarea {
  outline: none!important;
  border: solid 1px #E5E4E4;
  border-radius: 4px;
  height: 103px;
  width: 100%;
  padding:15px 15px;
  font-size: 16px;
  transition:all 0.2s ease;
}

.input-group input:focus,
.input-group textarea:focus {
  border-color:#9CA9C4;
  box-shadow: 0 10px 17px rgba(0,0,0,0.05);
}

.input-group label {
  width: 100%;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 5px;
  text-transform: uppercase;
}
.input-group .sm-icon {
  width: calc(33.333% - 10px);
  position: relative;
}
.input-group .sm-icon input {
  padding-left: 40px;
  font-size: 14px;
}
.input-group .sm-icon .socicon {
  position: absolute;
  left: 15px;
  top: 20px;
}

.input-group.half-width {
  width: calc(50% - 10px);
}
.input-group.phone input {
  width: calc(70% - 5px)
}
.input-group.phone select {
  width: calc(30% - 5px);
  margin-right:10px;
}

**/
.input-field.addon {
  display: flex;
  align-items: center;
}
.input-field.addon input {
  margin:0 5px;
}
.input-field.with-icon input {
  padding-left: 40px;
}
.input-field .socicon,
.input-field .icon {
  position: absolute;
  left: 15px;
  top: 18px;
  font-size: 14px;
}
.input-field .icon {
  max-width: 15px;
  max-height: 15px;
}

.input-field .socicon,
.input-field .icon {
  position: absolute;
  left: 15px;
  top: 18px;
  font-size: 14px;
}
.input-field .icon {
  max-width: 15px;
  max-height: 15px;
}
.input-field {
  width: 100%;
  position: relative;
  height: 50px;
  margin-bottom: 15px;
}
.input-field.addon {
  display: flex;
  align-items: center;
}
.input-field.addon span {
  font-weight: normal;
  font-size: 16px;
}
.input-field.addon input {
  margin:0 5px;
}
.input-field.with-icon input {
  padding-left: 40px;
}
.input-field .socicon,
.input-field .icon {
  position: absolute;
  left: 15px;
  top: 18px;
  font-size: 14px;
}
.input-field .icon {
  max-width: 15px;
  max-height: 15px;
}
.input-field input {
  width: 100%;
  height: 100%;
  font-size: 16px;
  border-radius: 6px;
  border: solid 1px #efefef;
  transition: all 0.2s;
  outline:none;
  padding:0 15px;
}

.input-field input:focus {
    box-shadow: rgba(0, 123, 255, 0.5) 0px 0px 0px 1px, rgba(0, 123, 255, 0.25) 0px 0px 0px 0.2rem;
  background: #fff;
}
.form-group {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.form-group {
  position: relative;
}

.form-group.inline {
  display: flex;
  flex-direction: row;
  overflow: hidden;
}

.form-group label {
  font-size: 11px;
  font-weight: bold;
  margin-bottom: 5px;
  display: block;
  text-transform: uppercase;
}

.form-group img {
  position: absolute;
  left: 0px;
  width: 14px;
  bottom: 33px;
  opacity: 0.4;
}

.form-group .field {
  width: 100%;
}

select {
  height: 40px;
  width: 100%;
  border: solid 1px #f1f1f1;
  outline: none!important;
  font-size: 14px;
}
.switch-checkbox {
  position: relative;
  padding-left: 20px;
  top: -40px;
}
.switch-checkbox.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
}
.switch-checkbox.switch input {
  opacity: 0;
  width: 50px;
  height: 26px;
  transform: translateY(-26px);
  position: relative;
  z-index: 2;
}
input:placeholder {
  font-weight: bold;
  text-transform: uppercase;
  color: #333;
}
.switch-checkbox.switch label {
  transform: translateY(-30px);
}
.switch-checkbox .switch-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  border-radius: 34px;
  transition: 0.4s;
}
.switch-checkbox .switch-slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}
.switch-checkbox input:checked + .switch-slider {
  background-color: #269e25;
}
.switch-checkbox input:focus + .switch-slider {
  box-shadow: 0 0 1px #269e25;
}
.switch-checkbox input:checked + .switch-slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
.switch-checkbox small {
  display: block;
  top: 0px !important;
}
.switch-checkbox input {
  position: absolute;
  top: 1px;
  left: 0px;
}

.form-group.no-margin {
  margin: 0!important;
}
.form-group .socicon {
  font-size: 14px;
}
.input-group {
  position: relative;
}
.input-group.with-hint input {
  padding-right: 34px
}
.input-group.with-tag input {
  padding-left: 45px;
}
.input-tag {
  font-size: 12px;
  font-weight: bold;
  padding:4px;
  border-radius: 4px;
  background: #fff;
  color: #333;
  text-transform: uppercase;
  position: absolute;
  left: 10px;
  bottom:13px;
  border:solid 1px #efefef;
}
.input-hint {
  width: 20px;
  height: 20px;
  background: #333;
  cursor: pointer;
  position: absolute;
  bottom: 14px;
  right: 10px;
  border-radius: 50%;
  z-index: 2;
}
.input-hint:before {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  content: "?";
  color: #fff;
  font-weight: bold;
  font-size: 14px;
}

.input-hint span {
  width: 130px;
  background: #333;
  border-radius: 5px;
  bottom: 100%;
  visibility: hidden;
  opacity: 0;
  transition: 0.2s;
  font-size: 12px;
  color: rgba(255,255,255,0.7);
  position: absolute;
  padding:10px;
  left: calc(50% - 75px);
}

.input-hint:hover span {
  opacity: 1;
  visibility: visible;
  transform: translateY(-2px);
}
.autofill-dropdown {
  position: absolute;
  background: #fff;
  box-shadow: 0 6px 12px rgba(0,0,0,0.1);
  left: 0;
  top: 100%;
  display: none;
  width: 100%;
  z-index: 4;
}

.autofill-dropdown.active {
  display: block;
  animation: dropdownIn 0.4s forwards;
}
@keyframes dropdownIn {
  from {opacity: 0;transform: translateY(2px);}
  to {opacity: 1;transform: translateY(0px);}
}
.autofill-dropdown-item {
  width: 100%;
  padding:10px;
  border-bottom: solid 1px #efefef;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  position: relative;
  border-bottom: solid 1px #efefef;
}

.autofill-dropdown-item:hover {
  background: #fafafa;
}

.autofill-dropdown-item div {
  line-height: 13px;
}
.autofill-dropdown-item h4 {
  font-size: 16px;
  margin:0;
}
.autofill-dropdown-item span {
  font-size: 12px;
  opacity: 0.5;
}
.autofill-dropdown-item img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 6px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  margin-right: 20px;
}
.autofill-dropdown-item img.remove {
  width: 13px;
  height: 13px;
  object-fit: contain;
  border-radius: 0;
  box-shadow: none;
  margin:0;
  position: absolute;
  top: 22px;
  right: 20px;
  opacity: 0.5;
}
.autofill-dropdown-item img.remove:hover {
  opacity: 1;
}
.autofill-dropdown-item .initials-box {
  width: 40px;
  height: 40px;
  border-radius: 6px;
  font-size: 16px;
}
.autofill-dropdown-item.input:hover {
  background: #fff;
}
.dropdown-check {
  width: 20px;
    height: 20px;
    border: solid 1px #efefef;
    background: #fafafa;
    border-radius: 50%;
    margin: 8px;
    position: relative;
}

.dropdown-check:before {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: inset 0 0 0px 2px #fff;
}
.dropdown-check:hover:before {
  background: #efefef
}
.dropdown-check.active:before {
  background: linear-gradient(-135deg, #81FBB8 0%, #28C76F 100%)!important;
}

.dropdown-check.add {
  border-radius: 0;
    box-shadow: none;
    border: none;
    display: flex;
    justify-content: center;
    background: transparent;
}
.dropdown-check.add:before {
  content: "+";
  border-radius: 0;
  box-shadow: none;
  border-radius: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
}

.autofill-dropdown-item input {
  padding: 0 0 0 10px!important;
    height: 30px!important;
    width: 178px;
}
.autofill-dropdown-item .btn {
  padding:10px 20px;
}
.autofill-dropdown-item .btn.green {
  margin: auto;
  padding:10px 20px;
}
.input-group.col-50 {
  width: calc(50% - 10px);
}

.input-group.col-25 {
  width: calc(25% - 10px);
}
.input-group.exp-date label {
  width: 100%;
}

.input-group.exp-date {
  flex-direction: row;
  flex-wrap: wrap;
}

.input-group.exp-date  select{
  width: calc(50% - 2px);
}

@media (max-width: 779px) { 
  input,
  textarea,
  button {
    /*-webkit-appearance: none;*/
    border-radius: 0;
  }
  input[type='text'],
  input[type='number'],
  input[type="password"],
  textarea {
    font-size: 16px!important;
  }

  .login-form .form-card input {
    padding:15px 20px 15px 50px;
  }
  .login-form .form-card .form-group img {
    bottom: 27px;
  }
}
