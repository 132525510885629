.profile_about {
    color: gray;
    margin-top: 40px;
    white-space: pre-wrap;
}

.review_container {
    display: flex; 
    flex-direction: row;
    background-color: #fff;
    border: solid 1px #00000019;
    border-left: none;
    border-top: none;
    border-right: none;
    padding: 20px;
    margin-bottom: 10px;
    box-shadow: rgb(0 0 0 / 8%) 0px 1px 12px;
    border-radius: 10px;
    max-width: 800px;
}

.review_img {
    object-fit: cover;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    margin-right: 15px;
    background-color: #fd6270;
}

.review_info {
    width: 90%;
}

.review_user {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.review_name {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 0px;
}


.review_date {
    font-size: 14px;
}

.review_text {
    font-size: 14px;
}