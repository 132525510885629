@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&family=Quicksand:wght@300..700&display=swap');

.search-container {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  margin: 24px 0px;
  padding: 0px 12px;

  width: 100%;

  border-radius: 4px;
  border: 1px #0000003b solid;
}

.search-container .outlined {
  border: none;
  border-bottom: 1px rgb(252, 97, 112) solid;
}

.search-container label {
  position: absolute;
  top: -10px;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 4px;
  background: #fff;

  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  font-family: 'Nunito Sans', sans-serif;
  letter-spacing: 0.15px;
}

.search-box {
  display: inline-flex;
  align-self: stretch;
  padding: 4px 0px;
  justify-content: flex-start;
  align-items: center;
}

.search-box input {
  flex: 1 1 0;
  padding-left: 0px;

  border: none;
  outline: none;
  background: transparent;
}

.search-result-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;

  padding: 12px 6px;

  width: 100%;
  height: 64px;
  border-bottom: 1px rgb(252, 97, 112) solid;

  font-family: 'Nunito Sans', sans-serif;
  letter-spacing: 0.15px;
}

.search-result-container:hover {
  background: rgba(243, 243, 243, 0.8);
}

.search-result {
  display: flex;
  justify-content: space-between;
  gap: 5px;
  margin-top: auto;
  color: rgba(0, 0, 0, 0.87);
}

.search-box input,
.district-name {
  font-size: 16px;
  line-height: 24px;
}

.district-name {
  font-weight: bold;
}

.district-info {
  display: flex;
  gap: 4px;
  font-size: 14px;
  line-height: 21px;
}

.passive .district-name,
.passive .district-info {
  opacity: 0.2;
  pointer-events: none;
}

.label-box-delivery {
  display: flex;
  justify-content: center;
  padding: 5px 5px 2px 5px;

  width: max-content;

  color: #fff;
  background: #797979;
  border-radius: 10px;

  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
}

.free-pickup {
  background: #0cbc13;
}

.selected-location-wrapper img {
  width: 24px;
  height: 24px;

  padding: 2px;

  background: rgba(243, 243, 243, 0.87);
  border-radius: 50%;
}

.selected-location-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  gap: 6px;

  font-weight: 400;
}
