.dropdown-container {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  padding: 0px 12px;

  width: 100%;
  height: 48px;

  border-radius: 4px;
  border: 1px rgba(0, 0, 0, 0.23) solid;
  background: #fff;
}

.dropdown-container.outlined {
  border: none;
  border-bottom: 1px rgba(0, 0, 0, 0.23) solid;
}

.dropdown-container.provider {
  border-color: #26bfbf;
}

.dropdown-container label {
  position: absolute;
  top: -10px;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 4px;
  background: #fff;

  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  font-family: 'Nunito Sans', sans-serif;
  letter-spacing: 0.15px;
}

.search-box {
  display: inline-flex;
  align-self: stretch;
  padding: 4px 0px;
  justify-content: flex-start;
  align-items: center;
}

.search-box input,
.search-box p {
  flex: 1 1 0;
  flex-wrap: nowrap;
  padding-left: 0px;
  border: none !important;
  outline: none !important;
  background: transparent;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.search-box input:focus {
  box-shadow: none !important;
}

.dropdown .head {
  box-shadow: inset 0 0 0 2px black;
  transform: rotate(180deg);
}

.dropdown-body {
  position: absolute;
  top: 45px;
  left: -1px;
  margin-top: 5px;

  min-width: max-content;
  width: 100%;
  max-height: 200px;
  overflow: auto;

  border-radius: 4px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background: #fff;

  visibility: visible;
  opacity: 1;
  transform: translateY(0);

  z-index: 10;
}

.dropdown-option {
  display: flex;
  padding: 8px 12px;
  transition: 0.2s;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  border-bottom: 1px rgba(0, 0, 0, 0.23) solid;
}

.dropdown-option-img {
  width: 24px;
  margin-right: 8px;
}

.dropdown-option:hover {
  color: #fff;
  background: rgba(0, 0, 0, 0.23);
}

.search-box input,
.dropdown-option {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dropdown-head {
  display: flex;
}

.placeholder {
  opacity: 0.3;
}

.dropdown-option.selected {
  background-color: rgba(252, 97, 112, 0.2);
}

.dropdown-option.selected.provider{
  background-color: rgba(38, 191, 191, 0.2);
}

@media (min-width: 1024px) {
  .dropdown-container,
  .date-container {
    margin: 12px 0px;
    width: 100%;
  }
}
