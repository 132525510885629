* {
    -webkit-tap-highlight-color: transparent;
}

.top_container {
   padding-top: 60px;
   padding-bottom: 200px;
   padding-left: 80px;
   padding-right: 80px;
}

.top_container.no_padding{
    padding-top: 0;
 }

.top_container.small_padding{
    padding-top: 20px;
}

.top_wrapper {
    display: flex; 
    flex-wrap: wrap;
}

.top_navbar {
    display: flex;
    justify-content: space-between;
    position: fixed;
    top: 0;
    right: 0;
    height: 65px;
    width: 100%;
    z-index: 40;
    color: #fff;
    background: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 1px 12px; 
    padding: 0px 20px;
}

.top_navbar_right{
    display: flex;
    align-items: center;
}

.top_navbar_left{
    display: flex;
    align-items: center;
}

.navlogo{
    width: 150px; 
    margin: 0; 
    margin-right: 20px;
    display: block;
}

.navsimbol{
    width: 44px;
    margin: 0;
    margin-right: 10px;
    display: none;
}

.navprofile{
    cursor: pointer;
    margin-left: 10px;
}

.navprofile img{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 45px;
    height: 45px;
    object-fit: cover;
    border: solid 1px;
    border-color: #f1f1f1;
    box-shadow: 0 6px 12px rgba(0,0,0,0.1);
}

.navprofile div{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 45px;
    height: 45px;
    border: solid 1px;
    border-color: #f1f1f1;
    box-shadow: 0 6px 12px rgba(0,0,0,0.1);
    background: #ccc;
}

.navcart {
    width: 35px;
    height: 35px;
    cursor: pointer;
}

.navcart span{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    cursor: pointer;
}

.navcart svg {
    width: 28px!important;
    height: 28px!important;
}

.navburger {
    width: 35px;
    height: 35px;
    cursor: pointer;
    display: none;
}

.navburger span{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    cursor: pointer;
}

.navburger svg {
    width: 28px!important;
    height: 28px!important;
}

.navcartcount {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fd6270;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    position: absolute;
    top: 12px;
    right: 75px;
}


.navlanguage {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    cursor: pointer;
    margin-left: 10px;
    margin-right: 5px;
}

.navlanguage img {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    box-shadow: 0 6px 12px rgba(0,0,0,0.1);
}


.navcurrency {
    color: #000;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-left: 5px;
    margin-right: 10px;
}

.navcurrency img {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    box-shadow: 0 6px 12px rgba(0,0,0,0.1);
}

.sidebar_master{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 120px;
}

.sidebar_master svg{
   height: 30px!important;
   width: 30px!important;
}

.sidebar_logo {
    display: flex; 
    width: 100%;
    height: 70px; 
    margin: 20px; 
    justify-content: flex-start; 
    align-items: center;
}

.sidebar_logo img {
    width: 150px; 
    margin: 0; 
    margin-right: 20px;
}



/* Search Bar */
.search_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 300px;
    height: 42px;
    background-color: #f1f1f1;
    border-radius: 30px;
    padding: 5px;
}

.search_container input {
    width: 90%;
    font-size: 16px;
    color: #000;
    background-color: transparent;
    border: none;
    margin-left: 5px;
}

.search_container div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: #000;
    cursor: pointer;
}

.nearby_map{
    width: 100%!important;
    height: 100%!important;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 1px 12px;
    border-radius: 20px;
    background: #ccc;
    min-height: 400px;
}

.nearby_map.min70{
    min-height: 70vh;
}

.simple_textbox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
    width: 100%;
    height: 50px;
    background-color: #fff;
    padding: 5px;
    box-shadow: 0px 0 5px -2px #888;
    margin: 10px 0px;
}

.simple_textbox input {
    background-color: transparent;
    font-size: 18px;
    border: none;
    margin-left: 20px;
    width: 100%;
}

.top_provider{
    width: 100%;
    height: 100%;
    margin-top: 20px;
}

.top_orders_details{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.top_orders_details span{
    font-size: 25px;
    font-weight: 300;
    margin-bottom: 20px;
}



/* Media Queries */
@media (max-width: 950px) { 

    .top_container {
        padding-left: 0;
        padding-right: 0;
    }

    .top_orders_details{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    .top_orders_details img{
        margin-top: 20px;
    }

}

@media (max-width: 660px) {
    .search_container {
       width: calc(100vw - 140px);
       display: none;
    }

    .search_container input{
        font-size: 14px;
    }

    .navlanguage{
        display: none;
    }

    .navprofile{
        display: none;
    }

    .top_navbar {
        padding: 0px 10px;
    }

    .top_navbar_right {
        margin-left: 5px;
    }

    .navcartcount {
        right: 45px;
    }

    .navburger {
        display: block;
    }

    .navsimbol {
        width: 35px;
        margin-right: 5px;
    }

    #nearby_container .top_wrapper{
        display: none;
    }

    #nearby_container h1{
        display: none;
    }

    #nearby_container{
        padding: 0px;
    }

    /* .nearby_map{
        height: calc(100vh - 160px)!important;
    } */

    #dashboard_menu_provider{
        display: none!important;
    }

    .top_provider{
        margin-top: 0px;
    }

    

}


.cd-breadcrumb, .cd-multi-steps {
  width: 100%;
  padding: 0.5em 1em;
  margin: 10px;
  border-radius: .25em;
}
.cd-breadcrumb:after, .cd-multi-steps:after {
  content: "";
  display: table;
  clear: both;
}
.cd-breadcrumb li, .cd-multi-steps li {
  display: inline-block;
  float: left;
  margin: 0.5em 0;
}
.cd-breadcrumb li::after, .cd-multi-steps li::after {
  /* this is the separator between items */
  display: inline-block;
  content: '\00bb';
  margin: 0 .6em;
  color: #959fa5;
}
.cd-breadcrumb li:last-of-type::after, .cd-multi-steps li:last-of-type::after {
  /* hide separator after the last item */
  display: none;
}
.cd-breadcrumb li > *, .cd-multi-steps li > * {
  /* single step */
  display: inline-block;
  font-size: 18px;
  color: #2c3f4c;
}
.cd-breadcrumb li.current > *, .cd-multi-steps li.current > * {
  /* selected step */
  color: #96c03d;
}
.no-touch .cd-breadcrumb a:hover, .no-touch .cd-multi-steps a:hover {
  /* steps already visited */
  color: #96c03d;
}
.cd-breadcrumb.custom-separator li::after, .cd-multi-steps.custom-separator li::after {
  /* replace the default arrow separator with a custom icon */
  content: '';
  height: 16px;
  width: 16px;
  background: url(./z_breadcrumb.svg) no-repeat center center;
  vertical-align: middle;
}
.cd-breadcrumb.custom-icons li > *::before, .cd-multi-steps.custom-icons li > *::before {
  /* add a custom icon before each item */
  content: '';
  display: inline-block;
  height: 20px;
  width: 20px;
  margin-right: .4em;
  margin-top: -2px;
  background: url(./z_breadcrumb.svg)  no-repeat 0 0;
  vertical-align: middle;
}
.cd-breadcrumb.custom-icons li:not(.current):nth-of-type(2) > *::before, .cd-multi-steps.custom-icons li:not(.current):nth-of-type(2) > *::before {
  /* change custom icon using image sprites */
  background-position: -20px 0;
}
.cd-breadcrumb.custom-icons li:not(.current):nth-of-type(3) > *::before, .cd-multi-steps.custom-icons li:not(.current):nth-of-type(3) > *::before {
  background-position: -40px 0;
}
.cd-breadcrumb.custom-icons li:not(.current):nth-of-type(4) > *::before, .cd-multi-steps.custom-icons li:not(.current):nth-of-type(4) > *::before {
  background-position: -60px 0;
}
.cd-breadcrumb.custom-icons li.current:first-of-type > *::before, .cd-multi-steps.custom-icons li.current:first-of-type > *::before {
  /* change custom icon for the current item */
  background-position: 0 -20px;
}
.cd-breadcrumb.custom-icons li.current:nth-of-type(2) > *::before, .cd-multi-steps.custom-icons li.current:nth-of-type(2) > *::before {
  background-position: -20px -20px;
}
.cd-breadcrumb.custom-icons li.current:nth-of-type(3) > *::before, .cd-multi-steps.custom-icons li.current:nth-of-type(3) > *::before {
  background-position: -40px -20px;
}
.cd-breadcrumb.custom-icons li.current:nth-of-type(4) > *::before, .cd-multi-steps.custom-icons li.current:nth-of-type(4) > *::before {
  background-position: -60px -20px;
}
/* @media only screen and (min-width: 768px) {
  .cd-breadcrumb, .cd-multi-steps {
    padding: 0 1.2em;
  }
  .cd-breadcrumb li, .cd-multi-steps li {
    margin: 1.2em 0;
  }
  .cd-breadcrumb li::after, .cd-multi-steps li::after {
    margin: 0 1em;
  }
  .cd-breadcrumb li > *, .cd-multi-steps li > * {
    font-size: 20px;
  }
} */