@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap');

.stepper {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;

  margin-top: 36px;
  margin-bottom: 24px;

  width: 100%;
}

.stepper .step {
  display: inline-flex;

  width: 32px;
  height: 32px;

  background: rgb(174, 174, 174);
  border-radius: 64px;
}

.step.checked {
  background: #fc6170;
}

.step .step-num {
  margin: auto;

  color: #fff;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Quicksand', sans-serif;
}
