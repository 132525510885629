.empty_container{
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding: 40px;
    margin: 0px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 1px 12px; 
}

.empty_box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: dashed 1px #ccc;
    border-radius: 10px;
    width: 100%;
}

.empty_box img{
    width: 100%;
    height: 250px;
    object-fit: contain;
    margin: 30px;
}

.empty_box h4{
    font-weight: bold;
    font-size: 22px;
    margin: 0px;
}

.empty_box p{
    font-size: 15px;
    text-align: center;
    margin: 10px 30px;
    margin-bottom: 60px;
}


@media(max-width: 500px){
    .empty_container{
        padding: 20px;
    }
}