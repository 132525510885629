/* PRODUCT PAGE */

.top_wrapper_container {
    display: flex;
}

.top_left_container {
    display: flex;
    flex-direction: column;
    padding: 20px 20px;
    background: #fff;
    box-shadow: rgb(0 0 0 / 8%) 0px 1px 12px;
    border-radius: 10px;
    height: 100%;
    margin-right: 30px;
    margin-bottom: 20px;
}

.top_right_container {
    display: flex;
    flex-direction: column;
    padding: 20px 20px;
    background: #fff;
    box-shadow: rgb(0 0 0 / 8%) 0px 1px 12px;
    border-radius: 10px;
    height: 100%;
    width: 100%;
}

.top_right_container.no-background {
    background: transparent;
    box-shadow: none;
}

.top_product_img {
    background-color: #fff;
    border: solid 1px #00000019;
}

.top_product_img img {
    object-fit: contain; 
    width: 320px; 
    height: 320px;
}

.top_product_title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.top_product_title h1 {
    font-size: 26px; 
    font-weight: bold; 
    margin-top: 20px;
    margin-bottom: 10px;
}

.top_product_price {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 30px;
}

.top_product_price h1 {
    font-size: 26px; 
    font-weight: bold; 
    margin: 0px;
    color: #fd6270;
    margin-right: 5px;
}

.top_product_price span {
    font-size: 17px; 
    font-weight: 400; 
    margin: 0px;
    color: #000000;
}


.top_add_cart {
    display: flex;
    justify-content: center;
    align-items: center;
    
    height: 50px;
    color: #ffffff;
    background-color: #fd6270;
    border-radius: 10px;

    font-size: 15px;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;

    margin: 3px 0px;
}

.top_add_cart.simple {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    color: #26BFBF;
    background-color: #fff;
    border-radius: 10px;
    border: solid 1px;

    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;

    margin: 3px 0px;
}

.top_add_cart.simple:hover {
    display: flex;
    justify-content: center;
    align-items: center;
    
    height: 50px;
    color: #fff;
    background-color: #26BFBF;
    border-radius: 10px;
    border: solid 1px;

    font-size: 13px;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;

    margin: 3px 0px;
}

.top_cards {
    display: flex;
    flex-direction: column;
    margin: 12px 0px;
}

.top_cards h1{
    font-size: 18px;
    margin: 0;
    margin-bottom: 5px;
}

.top_cards p{
    color: #000000;
    margin: 3px 0px;
    white-space: pre-line;
    font-weight: 200;
}

.top_provider_card {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    padding: 15px;
}

.top_provider_card img{
    width: 120px;
    height: 120px;
    object-fit: cover;
    border-radius: 20px;
    margin-right: 15px;
}

.top_provider_info {
    display: flex;
    flex-direction: column;
}

.top_provider_info h4{
    font-size: 18px;
    margin: 0px;
}

.top_provider_info p{
    font-size: 14px;
    margin-top: 5px;
    font-weight: 200;
}

.top_provider_info span{
    display: flex;
    justify-content: center;
    align-items: center;
    
    height: 50px;
    color: #ffffff;
    border-radius: 10px;

    font-size: 15px;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;

    min-width: 190px;
}

.top_header{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    margin-bottom: 20px;
}

.top_header h1{
    font-size: 26px;
    margin: 5px;
}

.normal_headers{
    display: flex;
    flex-direction: column;
}

.normal_headers h1{
    margin: 0px;
    margin-top: 20px;
    font-size: 26px;
}

.normal_headers p {
    color: #000000;
    white-space: pre-wrap;
    font-weight: 200;
    font-size: 14px;
    max-width: 400px;
}


/* STORE PROFILE */
.top_store_card {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    padding: 20px;
    min-width: 310px;
}

.top_store_profile{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.top_store_stats {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 10px;
    margin-top: 30px;
    text-align: center;
}

.top_store_stats h3{
   margin: 0;
}

.top_store_stats p{
   margin: 0;
   font-size: 14px;
}

.top_pricing_list {
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: space-between;
   width: 100%;
}

.top_pricing_list span{
    margin-top: 10px;
    color: #000000;
    font-size: 16px;
    font-weight: 200;

}


/* OTHER */

.date_selector{
    padding: 10px;
    margin: 0px;
    max-width: 200px;
    height: 40px;
    border-radius: 10px;
    background: #f8f8f8;
    color: #000000;
}

.profile_img {
    width: 140px;
    height: 140px;
    border-radius: 90px;
    margin-bottom: 20px;
    border-width: 2px;
    margin-top: 30px;
    object-fit: cover;
    background-color: #ccc;
}

.store_title{
    margin: 0px;
    text-align: center;
    text-transform: capitalize
}

.button_container {
    display: flex;
    align-content: center;
    justify-content: center; 
    flex-direction: row; 
    justify-content: space-evenly;
    height: 60px;
    width: 100%;
    margin-top: 20px;
}

.button_action {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    cursor: pointer; 
    border-radius: 10px;
}

.button_action.primary {
    width: 45%;
    background-color: #fd6270;
    color: #fff;
}

.button_action.secondary {
    width: 45%;
    border: solid 1px #fd6270;
    color: #fd6270;
}


.button_action.pro_primary {
    width: 45%;
    background-color: #26BFBF;
    color: #fff;
}

.button_action.pro_secondary {
    width: 45%;
    border: solid 1px #26BFBF;
    color: #26BFBF;
}


.button_action.disabled {
    width: 45%;
    background-color:#ccc;
    color: #fff;
    cursor: not-allowed;
}

.top_textarea {
    background: #fff;
    width: 100%;
    min-height: 200px;
    font-size: 16px;
    box-shadow: rgb(0 0 0 / 8%) 0px 1px 12px;
    padding: 20px;
    border: none;
    border-radius: 10px;
}

.top_cardsimple {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: rgb(0 0 0 / 8%) 0px 1px 12px;
    padding: 10px;
    border: none;
    border-radius: 10px;
    background-color: #fff;
    max-width: 250px;
    max-width: 200px;
    margin-right: 10px;
    height: 300px;
}

.top_cardsimple img{
    width: 130px;
    height: 130px;
    object-fit: cover;
    border-radius: 10px;
    border: solid 1px #00000019;
    margin-top: 15px;
    margin-bottom: 15px;
}

.top_cardsimple span{
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
}

@media (max-width: 820px) { 

    .top_left_container {
        margin-right: 10px;
    }

}


@media (max-width: 800px) { 

    .top_wrapper_container {
        display: flex;
        flex-wrap: wrap;
    }
    
    .top_left_container {
        margin-right: 0px;
        width: 100%;
    }
    
    .top_right_container {
        width: 100%;
    }

    .top_product_img img{
        width: calc(100vw - 82px);
    }

}


@media (max-width: 660px) {
    .top_store_card {
        min-width: 100%;
    }
}