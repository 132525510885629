@import url('./z_bootstrap.css');

/* Nearby Container */
.nearby_container{
    margin: 5px;
    cursor: pointer;
}

.nearby_card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 370px;
    background-color: #ffffff;
    width: 220px;
    border-radius: 10px;
    margin-bottom: 20px;
    padding: 15px;
    box-shadow: rgb(0 0 0 / 8%) 0px 1px 12px;
}

.nearby_card img {
    width: 100%;
    height: 170px;
    object-fit: cover;
}

.nearby_info {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.nearby_info h1 {
    font-size: 20px;
    width: 100%;
    text-transform: capitalize;
    margin-top: 10px;
}

.nearby_info p {
    font-size: 14px;
    width: 100%;
    color: gray;
    margin: 0;
}

.nearby_button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    color: #ffffff;
    background-color: #fd6270;
    border-radius: 7px;
    margin-top: 22px;
}

/* Product Container */
.product_container {
    margin: 5px;
    cursor: pointer;
}

.product_card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 370px;
    background-color: #ffffff;
    width: 220px;
    border-radius: 10px;
    margin-bottom: 20px;
    padding: 15px;
    box-shadow: rgb(0 0 0 / 8%) 0px 1px 12px;
} 

.product_photo {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
}

.product_photo img {
    object-fit: cover;
    width: 100%;
    height: 195px;
}

.product_info {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-bottom: 5px;
    margin-top: 10px;
}

.product_info h1 {
    font-weight: bold;
    font-size: 17px;
    margin-top: 10px;
}

.product_price {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 5px;
}

.product_price h1 {
    font-size: 19px;
    font-weight: bold;
}

.product_price span {
    font-size: 12px;
    font-weight: bold;
    color: #ccc;
    margin-left: 5px;
}
 
/* Info Container */

.info_container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #fff;
    border-color: #e3e3e3;
    border-width: 1px;
    width: 170px;
    overflow: hidden;
    padding: 5px;
    border-radius: 15px;
    height: 100%;
}

.info_img{
    display: flex;
    width: 80px;
    height: 80px;
    align-self: center;
    object-fit: cover;
    border-radius: 50px;
}

.info_name_box {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.info_name_box h1{
    display: block!important;
}

.info_name {
    margin: 0px;
    margin-top: 10px;
    margin-bottom: 5px;
    font-size: 14px;
    width: 100%;
    text-align: center;
}

.info_distance {
    margin: 0;
    font-size: 12px;
    width: 100%;
    color: gray;
    text-align: center;
}

.info_button {
    display: flex;
    height: 35px;
    background-color: #fd6270;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    margin-top: 22px;
    color: #ffffff;
    cursor: pointer;
}


@media (max-width: 500px) {
    .product_container {
        margin: 5px;
        cursor: pointer;
        width: calc(50% - 10px); 
    }
    .product_card{
        width: 100%;
        height: 100%;
    }

    .product_photo {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        max-height: 195px;
    }
    
    .product_photo img {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }

    .nearby_container {
        margin: 15px;
        cursor: pointer;
        width: 100%;
    }
    .nearby_card{
        width: 100%;
        height: 100%;
    }
    .nearby_card img {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
    .nearby_button {
        height: 60px;
    }
}

.category_selector {
    padding: 10px;
    margin: 0px;
    max-width: 150px;
    height: 40px;
    border-radius: 10px;
    background: #f8f8f8;
    color: #000000;
}


.category_selector.editor{
    padding-left: 0px;
    max-width: 100%;
    background: transparent;
    border: none;
    border-bottom: solid 1px #ccc;
    border-radius: 0;
}

.category_selector.checkout{
    padding-left: 0px;
    max-width: 100%;
    background: transparent;
    border: none;
    border-radius: 0;
}


@media (min-width: 992px) {
    .category_selector.checkout{
       font-size: 18px;
    }
}

@media (min-width: 320px) {
    .card-columns {
        column-count: 4;
    }
}

@media (min-width: 576px) {
    .card-columns {
        column-count: 4;
    }
}

@media (min-width: 768px) {
    .card-columns {
        column-count: 4;
    }
}

@media (min-width: 992px) {
    .card-columns {
        column-count: 2;
    }
}

@media (min-width: 1200px) {
    .card-columns {
        column-count: 2;
    }
}

@media (min-width: 1300px) {
    .card-columns {
        column-count: 2;
    }
}


@keyframes flashing { 
    0% {
      opacity: 0.5;
    }

    25% {
        opacity: 0.8;
    }
  
    50% {
      opacity: 0.5;
    }

    75% {
        opacity: 0.8;
    }

    100% {
        opacity: 0.5;
    }
}

@keyframes slide{
    0% {
      transform: translate3d(0, 0, 0);
    }
    100% {
      transform: translate3d(0, -2000px, 0);
    }
}

@keyframes animbase {
    0% {
      transform: scale(1) rotate(0deg);
    }
    40% {
      transform: scale(1.5) rotate(0deg);
    }
  
    70% {
      transform: scale(1.5) rotate(360deg);
    }
    100% {
      transform: scale(1) rotate(360deg);
    }
}
 
@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@-moz-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@-webkit-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@-o-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@-ms-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}

@keyframes zoom-out {
    0% {
      transform: scale(1, 1);
    }
    50% {
      transform: scale(1.5, 1.5);
    }
    100% {
      transform: scale(3, 3);
    }
}

.section {
	position: relative;
	/* height: 100vh; */
}

.section .section-center {
	position: absolute;
	top: 50%;
	left: 0;
	right: 0;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
}

#booking {
	font-family: 'Montserrat', sans-serif;
	background-image: url('/assets/img/babonbo_search_banner.jpg');
	background-size: contain;
	font-weight: 300;
    background-repeat: no-repeat;
    background-position: right;
    background-clip: border-box;
}

.booking-form {
	background: #fff;
	padding: 30px 15px 0px;
	border-radius: 4px;
	overflow: auto;
}

.booking-form .form-group {
	position: relative;
	margin-bottom: 30px;
}

.booking-form .form-control {
	border: none;
	height: 65px;
	-webkit-box-shadow: none;
	box-shadow: none;
	font-size: 22px;
	color: #090a0b;
	font-weight: 300;
	background: #f2f1f1;
	border-radius: 4px;
}

.booking-form .form-control::-webkit-input-placeholder {
	color: #b1b6bd;
}

.booking-form .form-control:-ms-input-placeholder {
	color: #b1b6bd;
}

.booking-form .form-control::placeholder {
	color: #b1b6bd;
}

.booking-form input[type="date"].form-control:invalid {
	color: #b1b6bd;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
}

.booking-form select.form-control {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

.booking-form select.form-control+.select-arrow {
	position: absolute;
	right: 0px;
	top: 0px;
	width: 24px;
	text-align: center;
	pointer-events: none;
	height: 65px;
	line-height: 65px;
	color: #b1b6bd;
	font-size: 14px;
}

/* .booking-form select.form-control+.select-arrow:after {
	content: '\279C';
	display: block;
	-webkit-transform: rotate(90deg);
	transform: rotate(90deg);
} */

.booking-form .form-label {
	color: #fd6270;
	display: block;
	font-weight: 600;
	height: 25px;
	line-height: 25px;
	font-size: 16px;
	position: relative;
	margin-bottom: 10px;
	text-transform: uppercase;
}

/* .booking-form .form-label:after {
	content: '';
	position: absolute;
	left: 10px;
	top: -10px;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 10px 10px 0 10px;
	border-color: #f2f1f1 transparent transparent transparent;
} */

.booking-form .form-btn {
	margin-bottom: 30px;
}

.booking-form .submit-btn {
	background: #fd6270;
	border: none;
	font-weight: 600;
	text-transform: uppercase;
	height: 90px;
	font-size: 18px;
	width: 100%;
	color: #fff;
	border-radius: 4px;
	display: block;
    border-radius: 20px;
}


.search_header{
    font-size: 45px;
}

.search_sub{
    font-size: 22px;
    margin-bottom: 50px;
    font-weight: 300;
}

.search_img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
    min-height: 500px;
}

@media (max-width: 992px) {

    
    .booking-form .submit-btn {
        font-size: 18px;
        height: 60px;
    }

    .search_header{
        font-size: 35px;
    }

    .search_sub{
        font-size: 18px;
    }

    .search_img{
        min-height: auto;
        margin-top: 40px;
    }

    .booking-form .form-control{
        font-size: 18px;
    }
}

.city-card:hover {
    box-shadow: 0px 30px 18px -8px rgba(0, 0, 0,0.1);
      transform: scale(1.10, 1.10);
}

@media (min-width: 770px) {
    .mobile-only {
        display: none;
    }
}


@media (max-width: 769px) {
    .desktop-only {
        display: none;
    }
}
