.live-chat-bubble {
	width: 60px;
	height: 60px;
	background: #333;
	position: fixed;
	bottom: 20px;
	right: 20px;
	border-radius: 50%;
	box-shadow: 0 8px 16px rgba(0,0,0,0.1);
	display: flex;
	justify-content: center;
	align-items: center;
	transition: all 0.2s;
	cursor: pointer;
	z-index: 44; 
}

.live-chat-bubble:hover {
	transform: scale(1.1);
	box-shadow:  0 8px 16px rgba(0,0,0,0.1), 0 0 0 8px rgba(0,0,0,0.1);
}

.live-chat-bubble img {
	width: 20px;
	transition: all 0.2s;
}

.live-chat-bubble .close-chat {
	position: absolute;
	left: calc(50% - 10px);
	top: calc(50% - 10px);
	opacity: 0;
	visibility: hidden;
	transition: 0.2s all;
	transform: scale(0.9);
}
.live-chat-bubble.active .close-chat {
	opacity: 1;
	visibility: visible;
	transform: none;
}

.live-chat-bubble.active .live-chat-icon {
	opacity: 0;
	transform: scale(0.8);
}

.live-chat-body {
	width: 376px; 
	height: calc(100vh - 216px)!important;
	border-radius: 10px;
	box-shadow: 0 8px 16px rgba(0,0,0,0.15);
	background: #fff;
	position: absolute;
	bottom: 100px;
	z-index: 44;
	right: 20px;
	overflow: hidden;
	opacity: 0;
	visibility: hidden;
	transform: translateY(20px);
	transition: all 0.3s;
}


.live-chat-body.active {
	opacity: 1;
	visibility: visible;
	transform: none;
}

.live-chat-body-input {
	width: 100%;
	position: absolute;
	bottom: 0;
	left: 0;
	height: 60px;
	padding:10px;
}

.live-chat-body-input input {
	width: 100%;
	height: 100%;
	background: #efefef;
	border-radius: 40px;
	border:none;
	outline: none;
	font-size: 14px;
	padding-left: 20px;
}


.live-chat-conversation {
	height: 100%;
	padding:0px 10px 60px 10px;
	width: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: flex-start;
	flex-direction: column;
	overflow: auto;
}
.live-chat-conversation .scroll-flex {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	width: 100%;
}
.live-chat-item {
	display: flex;
	justify-content: flex-start;
	width: 100%;
}
.live-chat-item div {
	background-color: #efefef;
	padding:7px 12px;
	font-size:15px;
	font-weight: 500;
	border-radius: 20px;
	margin-top: 10px
}

.live-chat-item.sender {
	justify-content: flex-end;
}

.live-chat-item.sender div {
	background: #424E70;
	color: #fff;
}

.live-chat-signup {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	background: #fff;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	padding:30px;
}


.live-chat-signup h4 {
	font-size: 30px;
	margin:0;
}

.live-chat-signup p {
	margin:0 0 20px 0;
	opacity: 0.7;
	font-size: 16px;
}
.live-chat-signup input {
	width: 100%;
	height: 50px;
	margin-bottom: 10px;
	font-size: 16px;
	border:none;
	outline: none;
	border-bottom: solid 1px #ccc;
	transition: all 0.2s;
}

.live-chat-signup button {
	border:none;
	outline: none;
	padding:10px 20px;
	font-size: 14px;
	text-transform: uppercase;
	background: #333;
	color: #fff;
	border-radius: 30px;
	margin-top: 10px;
}

.live-chat-signup input:focus {
	border-bottom-color: #333;
}


.live-chat-container {
	width: calc(100vw - 80px);
	height: calc(100vh - 85px);
	background: #fff;
	border-radius: 6px;
	box-shadow: 0 8px 16px rgba(0,0,0,0.1);
	display: flex;
	flex-wrap: wrap;
	margin-top: 0px;
	position: fixed;
	bottom: 0;
	right: 0;
	overflow: hidden;
}
.live-chat-users {
	width: 300px;
	height: 100%;
	overflow: auto;
	border-right: solid 1px #efefef;
}
.live-chat-convo {
	width: calc(100% - 300px);
	padding:10px;
	position: relative;
	height: calc(100% - 60px);
}
.convo-input-message {
	height: 80px;
	width: 100%;
	background: #efefef;
	border-top: solid 1px #efefef;
	position: absolute;
	bottom: 0;
	left: 0;
	padding:10px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}
.convo-input-message input {
	width: 100%;
	height: 100%;
	padding:0 20px;
	border-radius: 40px;
	background: #fff;
	border:solid 1px #efefef;
	font-size: 15px;
	transition: all 0.2s;
	outline: none;
}

.convo-input-message input:focus {
	border-color: transparent;
	box-shadow: 0 6px 12px rgba(0,0,0,0.2);
}

.live-chat-convo-container {
	height: calc(100% - 80px);
	position: absolute;
	top: 0;
	left: 0;
	padding:20px;
	display: flex;
	width: 100%;
	flex-direction: column;
	justify-content: flex-end;
}
.live-chat-message {
	width: 100%;
	display: flex;
	justify-content: flex-start;
	margin-top: 10px;
}

.live-chat-message div {
	border-radius: 30px;
	padding:10px 20px;
	font-weight: 400;
	font-size: 16px;
	background: #efefef;
	box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.live-chat-message.sender div {
	color: #fff;
	background: linear-gradient(-45deg, #455173 0%, #21283F 100%);
}
.live-chat-message.sender {
	justify-content: flex-end;
}

.live-chat-message.date {
	justify-content: center;
}

.live-chat-message.date div {
	padding:5px 10px;
	border-radius: 6px;
	background: #9CA9C3;
	font-size: 12px;
	text-transform: uppercase;
	color: rgba(255,255,255,0.7);
}
.convo-input-message img {
	width: 25px;
	margin-left: 10px;
	cursor: pointer;
	margin-right: 10px;
}
.live-chat-user {
	width: 100%;
	padding:25px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	white-space: nowrap;
	position: relative;
	border-bottom: solid 1px #efefef;
	cursor: pointer;
}
.live-chat-user:hover,
.live-chat-user.active {
	background: #fafafa;
}

.live-chat-user.active:before {
	content: " ";
	position: absolute;
	right: 0;
	top: 0;
	width: 5px;
	background: linear-gradient(-45deg, #455173 0%, #21283F 100%);
	height: 100%;
}

.live-chat-user img {
	width: 50px;
	height: 50px;
	border-radius: 50%;
	object-fit: cover;
	margin-right: 10px;

}

.live-chat-user div {
	width: calc(100% - 70px);
}

.live-chat-user i {
	position: absolute;
	top: 10px;
	right: 10px;
	font-size: 12px;
	opacity: 0.7;
	font-style: normal;
}

.live-chat-user div span {
	width: 100%;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	display: block;
	font-size: 14px;
	font-weight: 400;
	opacity: 0.5;
}

.live-chat-user div strong {
	font-weight: 500;
}

.live-chat-user sub {
	font-size: 12px;
    border-radius: 10px;
    padding: 1px 6px;
    color: #fff;
    background: #28C76F;
    position: absolute;
    right: 10px;
    top: 47px;
    transform: none;
    font-weight: normal;
}

.live-chat-user.unread div strong {
	font-weight: bold;
}

.live-chat-user.unread div span {
	opacity: 1;
	font-weight: 600;
}
.live-chat-user-card {
	padding:20px;
	min-width: 200px;
	border-radius: 10px;
	position: absolute;
	top: 30px;
	right: 30px;
	box-shadow: 0 8px 16px rgba(0,0,0,0.1);
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
}

.live-chat-user-card h4 {
	margin:0;
	width: 100%;
}
.live-chat-user-card button {
	border:none;
	outline: none;
	padding:8px 16px;
	border-radius: 20px;
	margin-top: 10px;
	color: #fff;
	font-size: 12px;
	text-transform: uppercase;
	background: linear-gradient(-135deg, #81FBB8 0%, #28C76F 100%);
}
.live-chat-user-card .close-card {
	filter: invert(1);
	position: absolute;
	top: 10px;
	right: 10px;
	width: 8px;
}
.live-chat-user-card span {
	font-weight: 400;
	font-size: 14px;
}
.live-chat-header {
	width: 100%;
	height: 60px;
	background: #fafafa;
	display: flex;
	padding:0 0px;
	justify-content: flex-start;
	align-items: center;
	box-shadow: inset 0 -3px 3px rgba(0,0,0,0.05);
}

.live-chat-tabs {
	display: flex;
	height: 100%;
	justify-content: flex-start;
	align-items: flex-end;
}

.live-chat-tabs div {
	padding: 15px 25px;
    border-radius: 4px 4px 0 0;
    font-size: 15px;
    font-weight: 400;
    color: rgba(0,0,0,0.4);
    position: relative;
    cursor: pointer;
}
.live-chat-tabs div span {
	font-size: 12px;
    border-radius: 10px;
    padding: 1px 6px;
    color: #fff;
    background: #28C76F;
    position: absolute;
    right: 7px;
    top: 7px;
    transform: none;
    font-weight: normal;
}
.live-chat-tabs div:hover,
.live-chat-tabs div.active {
	color: #333;
}

.live-chat-tabs div.active {
	background-color: #fff!important;
	box-shadow: 0 -3px 6px rgba(0,0,0,0.1);
}


.live-chat-website {
	height: 100%;
	padding:5px 20px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	cursor: pointer;
	border-radius: 12px;
	border:solid 5px transparent;
	width: 300px;
}

.live-chat-website:hover {
	background: rgba(0,0,0,0.05);
}

.live-chat-website img {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	object-fit: contain;
	margin-right: 10px;
}

.live-chat-website h4 {
	margin:0;
	margin-top: -6px;
}

.live-chat-website span {
	font-weight: 400;
	opacity: 0.4;
	font-size: 12px;
}

@media (max-width: 779px) { 

	.live-chat-container {
		width: 100%;
		height: calc(100% - 60px);
	}

	.live-chat-users {
		width: 60px;
		background: #fafafa;
	}
	.live-chat-website {
		width: 60px;
		border:none;
		padding:10px;
	}
	.live-chat-website img {
		width: 40px;
		height: 40px;
	}
	.live-chat-users img {
		width: 40px;
		height: 40px;
	}
	.live-chat-user sub {
		right: 7px;
    	top: 35px;
	}
	.live-chat-user {
		padding:10px;
	}
	.live-chat-user.active {
		background: #fff;
		box-shadow: 2px 0 11px rgba(0,0,0,0.1);
	}
	.live-chat-tabs div {
		padding:16px 14px;
	}
	.live-chat-user.active:before {
		display: none;
	}
	.live-chat-user div,
	.live-chat-user i {
		display: none;
	}
	.live-chat-convo {
		width: calc(100% - 60px);
	}
	.live-chat-user img {
		margin:0;
	}
	.live-chat-user-card {
		top: 10px;
		right: 10px;
	}
	.live-chat-website div {
		display: none;
	}
}


@media (max-width: 660px) {
	.live-chat-bubble{
		display: none;
	}

	.live-chat-body{
		width: 100%;
		height: 100%!important;
		padding: 0px;
		margin: 0px;
		top: 0px;
		right: 0px;
		box-shadow: none;
		z-index: 222;
		border-radius: 0px;
		position: absolute;
	}
}


.live-menu-body {
	width: 300px;
	height: 400px;
	border-radius:20px;
	box-shadow:  0 8px 16px rgba(0,0,0,0.15);
	background: #fff;
	position: fixed;
	bottom: 100px;
	z-index: 44;
	right: 20px;
	overflow: hidden;
	opacity: 0;
	visibility: hidden;
	transform: translateY(20px);
	transition: all 0.3s;
}

.live-menu-body.active {
	opacity: 1;
	visibility: visible;
	transform: none;
}
