@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Quicksand:wght@300..700&family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');

:root {
  --color-ba: #fc6170;
  --color-pr: #26bfbf;
  --color-blue: #3f51b5;
  --color-purple: #65558f;
  --color-light-purple: #ece6f0;
  --color-purple-gray: #79747e;
  --color-mid-gray: #aeaeae;
  --color-gray: #8c8c8c;
  --color-light-gray: #e8e8e8;
  --background-light-gray: #f3f3f3;
  --background-gray: #efefef;
  --white: rgb(255, 255, 255);
  --hover-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

body {
  margin: 0;
  padding: 0 !important;
  font-family: 'Quicksand', sans-serif;
}

.color-ba {
  color: var(--color-ba);
}

.color-pr {
  color: var(--color-pr) !important;
}

.color-gray {
  color: var(--color-gray) !important;
}

.color-red {
  color: #ff0000;
}

.border-ba,
.border-black,
.border-red,
.border-light-gray,
.border-purple-gray,
.border-mid-gray {
  border-width: 1px;
  border-style: solid;
}

.border-ba {
  border-color: var(--color-ba) !important;
}

.border-purple-gray {
  border-color: var(--color-purple-gray) !important;
}

.border-b-ba {
  border-bottom: 1px solid var(--color-ba) !important;
}

.border-b-pr {
  border-bottom: 1px solid var(--color-pr) !important;
}

.border-light-gray {
  border-color: var(--color-light-gray);
}

.border-mid-gray {
  border-color: var(--color-mid-gray);
}

.border-pr-dashed {
  border: 1px dashed var(--color-pr) !important;
}

.border-ba-dashed {
  border: 1px dashed var(--color-ba) !important;
}

.border-gray-dashed {
  border: 1px dashed var(--color-light-gray) !important;
}

.border-black {
  border-color: black;
}

.border-red {
  border-color: red;
}

.bg-gray {
  background: var(--color-gray) !important;
}

.bg-ba {
  background: var(--color-ba) !important;
}

.bg-light-gray {
  background: var(--background-light-gray) !important;
}

.bg-pr {
  background: var(--color-pr) !important;
}

.bg-light-pr {
  background: rgba(38, 191, 191, 0.25) !important;
}

.bg-light-purple {
  background: var(--color-light-purple) !important;
}

.bg-purple {
  background: var(--color-purple);
}

.bg-blue {
  background: var(--color-blue) !important;
}

.inline-label {
  position: absolute;
  top: -0.7rem;
  left: 1rem;
  padding: 0 0.25rem;
  font-size: 0.8rem;
  background: var(--white);
  color: var(--color-gray);
}

.header-menu-main {
  display: none;
}

.header-menu-mobile {
  display: flex;
}

.lato {
  font-family: 'Lato', sans-serif;
}

.quicksand {
  font-family: 'Quicksand', sans-serif;
}

.nunito {
  font-family: 'Nunito Sans', sans-serif;
}

/* LAYOUT */
.layout {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  min-height: 100vh;
  height: 100%;
  background: var(--white);
}

/* HEADER */
.header-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  background: var(--white);
  border-radius: 0 0 50px 50px;
  background-size: 45vw auto;
  background-repeat: no-repeat;
}

.header-logo {
  margin-right: 30px;
  width: 150px;
}

.menu-primary {
  display: flex;
  align-items: center;
  gap: 20px;
}

.menu-primary-item {
  color: var(--color-ba);
  font-family: 'Lato', sans-serif;
  font-weight: bold;
  font-size: 14px;
}

.menu-primary-item:hover {
  color: rgba(252, 97, 112, 0.6);
}

.menu-secondary-wrapper {
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  font-size: 12px;
  font-weight: 700;
  background: linear-gradient(180deg, white 0%, #f1f1f1 100%),
    linear-gradient(0deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0.5) 100%);
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.05);
  border-radius: 60px;
}

.menu-secondary {
  display: inline-flex;
  flex: 1 1 0;
  justify-content: center;
  align-items: center;
}

.menu-secondary-item {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 0 10px;
  font-size: 12px;
  font-family: 'Quicksand', sans-serif;
  font-weight: bold;
  color: var(--color-gray);
}

.menu-secondary-item:hover svg {
  color: rgba(140, 140, 140, 0.6);
  fill: rgba(140, 140, 140, 0.6);
}

.menu-secondary-divider {
  border-right: 1px var(--color-light-gray) solid;
}

.hero-img-container {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 30%;
  max-width: 800px;
  width: 100%;
  z-index: 1;
}

.hero-img-container.provider {
  margin-top: 0px;
}

.hero-content {
  z-index: 1;
}

.hero-img-container h2 {
  position: relative;
  font-size: 14px;
  text-align: center;
  color: var(--color-ba);
}

.hero-img {
  display: flex;
  width: 100%;
  max-height: 275px;
  border-radius: 36px;
  object-fit: cover;
}

/* FOOTER */
.footer {
  width: 100%;
  height: 100%;
  background: var(--background-gray);
  border-radius: 50px 50px 0 0;
}

.footer-container {
  display: flex;
  flex-wrap: wrap;
  align-items: start;
  justify-content: space-between;
  margin: auto;
  padding: 0 1.5rem;
  font-size: small;
}

.footer-container > div:first-child {
  flex-grow: 1;
  flex-basis: 0;
  min-width: 100%;
}

.footer-container > div {
  flex-basis: 15%;
  flex-grow: 0;
  min-width: 50%;
}

.modal-body {
  margin: 0 auto;
  width: 100%;
  max-width: 600px;
  max-height: 90vh;
  overflow: auto;
  padding: 2rem;
  background-color: var(--white);
  border-radius: 1rem;
  position: relative;
  z-index: 20;
}

.slide-in-bottom {
  min-height: 90%;
  height: 90%;
  max-height: 90%;
  margin-bottom: 0 !important;
  animation: slideFromBottom 0.5s ease-out forwards;
}

@media (min-width: 640px) {
  .modal-body {
    padding: 0 1.5rem;
  }
}

@media (min-width: 765px) {
  .slide-in-bottom {
    animation: none;
  }

  .header-wrapper {
    background: var(--background-gray);
  }
}

@media (min-width: 1024px) {
  .hero-img-container h2 {
    padding: 10px;
    font-size: 1rem;
  }

  .modal-body {
    padding: 0 2rem;
  }

  .header-wrapper {
    position: relative;
  }

  .header-menu-main {
    display: flex;
  }

  .header-menu-mobile {
    display: none;
  }

  .footer-container > div:first-child {
    min-width: 200px;
  }

  .footer-container > div {
    flex-basis: 15%;
    min-width: 150px;
  }
}

@media (min-width: 1536px) {
  .hero-img-container h2 {
    font-size: 1.5rem;
  }
  .container {
    max-width: 1536px;
  }
}

@keyframes slideFromBottom {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.container-rotate {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 15vw;
  left: 0;
  width: 100%;
  z-index: 0;
}

.circle {
  position: absolute;
  border-radius: 50%;
  border: 1px solid var(--color-ba);
}

#circle1 {
  width: 90vw;
  height: 90vw;
}

#circle2 {
  width: 110vw;
  height: 110vw;
}

#circle3 {
  width: 130vw;
  height: 130vw;
}

.circle img {
  position: absolute;
  width: 40px;
  height: 40px;
  padding: 6px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  z-index: 0;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
  transform-origin: -50% -50%;
}

/* Circle 1 items */
#circle1 img:nth-child(1) {
  transform: rotate(0deg) translate(45vw) rotate(0deg);
  animation: rotateCircle1_1 30s linear infinite;
}

#circle1 img:nth-child(2) {
  transform: rotate(120deg) translate(45vw) rotate(-120deg);
  animation: rotateCircle1_2 30s linear infinite;
}

#circle1 img:nth-child(3) {
  transform: rotate(240deg) translate(45vw) rotate(-240deg);
  animation: rotateCircle1_3 30s linear infinite;
}

/* Circle 2 items */
#circle2 img:nth-child(1) {
  transform: rotate(0deg) translate(55vw) rotate(0deg);
  animation: rotateCircle2_1 20s linear infinite;
}

#circle2 img:nth-child(2) {
  padding: 8px;
  transform: rotate(120deg) translate(55vw) rotate(-120deg);
  animation: rotateCircle2_2 20s linear infinite;
}

#circle2 img:nth-child(3) {
  transform: rotate(240deg) translate(55vw) rotate(-240deg);
  animation: rotateCircle2_3 20s linear infinite;
}

/* Circle 3 items */
#circle3 img:nth-child(1) {
  transform: rotate(0deg) translateY(65vw) rotate(0deg);
  animation: rotateCircle3_1 30s linear infinite;
}

#circle3 img:nth-child(2) {
  transform: rotate(72deg) translate(65vw) rotate(-72deg);
  animation: rotateCircle3_2 30s linear infinite;
}

#circle3 img:nth-child(3) {
  transform: rotate(144deg) translate(65vw) rotate(-144deg);
  animation: rotateCircle3_3 30s linear infinite;
}

#circle3 img:nth-child(4) {
  transform: rotate(216deg) translate(65vw) rotate(-216deg);
  animation: rotateCircle3_4 30s linear infinite;
}

#circle3 img:nth-child(5) {
  transform: rotate(288deg) translate(65vw) rotate(-288deg);
  animation: rotateCircle3_5 30s linear infinite;
}

/* Keyframes for Circle 2 items */
@keyframes rotateCircle1_1 {
  from {
    transform: rotate(0deg) translateY(-45vw) rotate(0deg);
  }
  to {
    transform: rotate(360deg) translateY(-45vw) rotate(-360deg);
  }
}

@keyframes rotateCircle1_2 {
  from {
    transform: rotate(120deg) translateY(-45vw) rotate(-120deg);
  }
  to {
    transform: rotate(480deg) translateY(-45vw) rotate(-480deg);
  }
}

@keyframes rotateCircle1_3 {
  from {
    transform: rotate(240deg) translateY(-45vw) rotate(-240deg);
  }
  to {
    transform: rotate(600deg) translateY(-45vw) rotate(-600deg);
  }
}

/* Keyframes for Circle 2 items */
@keyframes rotateCircle2_1 {
  from {
    transform: rotate(0deg) translateY(-55vw) rotate(0deg);
  }
  to {
    transform: rotate(360deg) translateY(-55vw) rotate(-360deg);
  }
}

@keyframes rotateCircle2_2 {
  from {
    transform: rotate(120deg) translateY(-55vw) rotate(-120deg);
  }
  to {
    transform: rotate(480deg) translateY(-55vw) rotate(-480deg);
  }
}

@keyframes rotateCircle2_3 {
  from {
    transform: rotate(240deg) translateY(-55vw) rotate(-240deg);
  }
  to {
    transform: rotate(600deg) translateY(-55vw) rotate(-600deg);
  }
}

/* Keyframes for Circle 3 items */
@keyframes rotateCircle3_1 {
  from {
    transform: rotate(0deg) translateY(-65vw) rotate(0deg);
  }
  to {
    transform: rotate(360deg) translateY(-65vw) rotate(-360deg);
  }
}

@keyframes rotateCircle3_2 {
  from {
    transform: rotate(72deg) translateY(-65vw) rotate(-72deg);
  }
  to {
    transform: rotate(432deg) translateY(-65vw) rotate(-432deg);
  }
}

@keyframes rotateCircle3_3 {
  from {
    transform: rotate(144deg) translateY(-65vw) rotate(-144deg);
  }
  to {
    transform: rotate(504deg) translateY(-65vw) rotate(-504deg);
  }
}

@keyframes rotateCircle3_4 {
  from {
    transform: rotate(216deg) translateY(-65vw) rotate(-216deg);
  }
  to {
    transform: rotate(576deg) translateY(-65vw) rotate(-576deg);
  }
}

@keyframes rotateCircle3_5 {
  from {
    transform: rotate(288deg) translateY(-65vw) rotate(-288deg);
  }
  to {
    transform: rotate(648deg) translateY(-65vw) rotate(-648deg);
  }
}

@media (min-width: 768px) {
  .hero-img-container h2 {
    position: absolute;
    top: 65%;
    padding: 0 5px;
    width: 50%;
    font-size: 0.7rem;
    background: var(--white);
    border-radius: 10px;
  }

  /* .hero-img-container {
    margin: auto;
    width: 70%;
  } */
}

.container-rotate {
  position: absolute;
  top: 10vw;
  transition: top 0.5s ease-in-out;
}

@media (min-width: 350px) {
  .container-rotate {
    top: 10vw;
  }
}

@media (min-width: 400px) {
  .container-rotate {
    top: 5vw;
  }
}

@media (min-width: 450px) {
  .container-rotate {
    top: -5vw;
  }
}

@media (min-width: 550px) {
  .container-rotate {
    top: -10vw;
  }
}

@media (min-width: 600px) {
  .container-rotate {
    top: -15vw;
  }
}

.circle-group-1 .static-circle {
  position: absolute;
  top: 0%;
  left: 5%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
}

.circle-group-1 .static-circle::before {
  content: '';
  position: absolute;
  top: -20%;
  left: -1px;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  border-radius: 50%;
  background: linear-gradient(
    to top,
    var(--color-ba) 0%,
    rgba(252, 97, 112, 0.75) 2%,
    rgba(252, 97, 112, 0.5) 5%,
    rgba(252, 97, 112, 0.25) 10%,
    transparent 50%
  );
  transform: rotate(25deg);
  mask: radial-gradient(closest-side, transparent 99%, black 100%);
  -webkit-mask: radial-gradient(closest-side, transparent 99%, black 100%);
}

.circle-group-2 .static-circle {
  position: absolute;
  top: 13vw;
  left: 25%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
}

.circle-group-2 .static-circle::before {
  content: '';
  position: absolute;
  top: auto;
  bottom: 0%;
  left: -1px;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  border-radius: 50%;
  background: linear-gradient(
    to top,
    var(--color-ba) 0%,
    rgba(252, 97, 112, 0.75) 2%,
    rgba(252, 97, 112, 0.5) 5%,
    rgba(252, 97, 112, 0.25) 10%,
    transparent 50%
  );
  mask: radial-gradient(closest-side, transparent 99%, black 100%);
  -webkit-mask: radial-gradient(closest-side, transparent 99%, black 100%);
}

.static-circle:nth-child(1) {
  width: 40vw;
  height: 40vw;
}

.static-circle:nth-child(2) {
  width: 36vw;
  height: 36vw;
}

.static-circle:nth-child(3) {
  width: 32vw;
  height: 32vw;
}

details {
  margin-bottom: 1rem;
}

summary {
  cursor: pointer;
  font-weight: bold;
  display: flex;
  align-items: center;
  list-style: none;
}

summary p {
  margin-left: 0.5rem;
  display: inline-block;
}

summary .summary-icon {
  margin-right: 0.5rem;
  transition: transform 0.3s ease;
}

details[open] summary .summary-icon {
  transform: rotate(45deg);
}

summary::-webkit-details-marker {
  display: none;
}
